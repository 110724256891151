@import "../../scss/functions.scss";

.border {
  position: relative;
  border-radius: to-rem(50);
  width: to-rem(40);
  height: to-rem(40);
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  background: var(--charcoal);

  .circle-color {
    border-radius: to-rem(50);
    width: to-rem(32);
    height: to-rem(32);
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-check:before {
      color: var(--charcoal);
    }
  }
}
