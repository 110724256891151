@import "../../scss/functions.scss";
$spacing: to-rem(96);

.media-library {
  // padding-bottom: 8px;

  &.media-library-wrapper,
  .media-library-inner {
    margin: 0;
    padding: 0;
  }

  .media-library__photos-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
    // flex-direction: row;
    // flex-wrap: wrap;
    // justify-content: flex-start;
    // align-content: flex-start;
    gap: 24px;
  }

  @media only screen and (min-width: 48rem) {
    &.media {
      height: calc(100dvh - #{$spacing});
      padding: 0;
    }

    .media-library__photos-container {
      // padding: to-rem(16);
    }
  }

  @media only screen and (min-width: 64em) {
  }
}
