@import "../../scss/functions.scss";

.password-sign-in {
  margin: 0 auto;
  max-width: to-rem(460);
  &.password-sign-in-wrapper,
  .password-sign-in-inner {
    margin: 0;
    padding: 0;
  }

  .password-sign-in__reset-password {
    background-color: transparent;
    color: var(--pink-hot);
    font-size: to-rem(12);
    cursor: pointer;
  }
}
