@import "../../scss/functions.scss";

.reset-password {
  //width: 100%;
  //height: 100dvh;
  &.reset-password-wrapper,
  .reset-password-inner {
    margin: 0;
    padding: 0;

    .reset-password-header {
      margin-bottom: 16px;
    }
  }

  .separator {
    width: 100%;
    height: to-rem(1);
    background-color: var(--charcoal);
  }

  .reset-password__separator {
    margin: to-rem(8, 0);
    height: to-rem(1);
  }

  .reset-password__app-link {
    padding: to-rem(24);
  }

  .reset-password__buttons-container {
    margin: var(--standard-spacing) 0 var(--standard-spacing) 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--inner-gap);
  }
}
