.landing-page-wrapper {
  .landing-page-inner {
    .root-landing-page {
      .landing-page {
        position: relative;
        overflow: hidden;

        .features-cards__wrapper {
          display: flex;
          flex-direction: column;
          gap: 24px;
        }

        .info-text-centered {
          position: relative;
          justify-self: center;
          margin-bottom: 40px;

          .gradient-background {
            position: absolute;
            z-index: -1;
            top: 50%;
            left: 50%;
            border-radius: 50%;
            width: 2575px;
            height: 2575px;
            transform: translate(-50%, -50%);
            opacity: 0.35;
            background: radial-gradient(circle, #e7007b7a 0%, #e7007c00 50%);
          }
        }

        .separator-lp {
          margin: 40px;
          border-top: 1px solid var(--gray-dark);
        }

        .features-girls-images {
          margin-bottom: 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 24px;

          [alt="feature girl"] {
            border-radius: var(--radius-mi);
            width: 100%;
            // max-width: 392px;
          }

          .logos-container {
            display: grid;
            justify-content: center;
            grid-column: 2/3;
            grid-template-columns: repeat(3, 1fr);
            gap: 24px;
            justify-self: center;
            justify-items: center;
            max-width: 168px;

            [alt="roses logo"] {
              width: 30px;
              height: 30px;
            }

            .icon-chevron-bottom {
              grid-column: 2/3;
            }
          }
        }

        .text-and-flags {
          margin: 24px 32px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 20px;
        }

        @media only screen and (min-width: 48rem) {
          display: grid;
          justify-items: center;

          .features-girls-images {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            padding-left: 24px;
            padding-right: 24px;
            [alt="feature girl"] {
              max-height: 392px;
              max-width: 392px;
              object-fit: cover;
            }
          }

          .info-text-centered {
            margin: 40px 24px;
          }
        }

        @media only screen and (min-width: 80rem) {
          display: grid;
          gap: 24px;

          .features-girls-images {
            margin: 0 auto;
            // max-width: 1224px;
          }

          .text-and-flags {
            margin: 0 auto;
            width: 100%;
          }

          .info-text-centered {
            margin: 40px auto;
          }
        }
      }
    }
  }
}
