.chat-suggestion,
.chat-suggestion-list {
  padding: 16px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  pointer-events: auto;
}

.chat-suggestion-list {
  padding: 0;
}

.chat-suggestion-title {
  font-size: 12px;
  margin: 0;
}

.chat-suggestion-item {
  border-radius: 8px;
  padding: 8px 16px;
  color: var(--gray);
  font-size: 12px;
  background: var(--midnight);
  transition: 0.3s background;

  &:hover {
    cursor: pointer;
    background: var(--dawn);
  }
}
