@import "../../scss/functions.scss";
$spacing: 234;

.user-settings-profile-photo {
  // position: relative;
  overflow: hidden;

  &::before {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: to-rem(100);
    content: "";
    pointer-events: none;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 76) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &.user-settings-profile-photo-wrapper {
    margin: 0;
  }

  .user-settings-profile-photo-inner {
    padding: 0;
    padding-bottom: to-rem(24);

    @media only screen and (min-width: 768px) {
      // padding-bottom: to-rem(40);
    }

    @media only screen and (min-width: 1280px) {
      padding-bottom: to-rem(24);
    }
  }
  // .user-settings-profile__actions {
  //   position: sticky;
  //   z-index: 10;
  //   top: 0;
  //   width: 100%;
  // padding: var(--standard-spacing) 0;
  // overflow-y: hidden;
  // }

  .bar-progress {
    margin-bottom: to-rem(16);
    width: 100%;
    height: to-rem(2);
    display: flex;
    flex-direction: row;
    padding-top: to-rem(8);
    gap: to-rem(8);

    .progress {
      width: 16.66%;
      height: to-rem(2);
      background-color: var(--pink-light);
    }

    .none {
      background-color: var(--gray-dark);
    }
  }

  .profile-picture-container {
    display: flex;
    align-items: center;
    padding: var(--standard-spacing);
    gap: to-rem(8);
    overflow-x: auto;

    .colorCircle {
      background-color: aqua;
    }
  }

  .user-settings-photo-container {
    display: flex;
    flex-direction: column;
    gap: to-rem(8);

    .album-photos-quantity {
      position: relative;
      display: block;
      padding: to-rem(0, 0, 0, 32);
      color: var(--gray);
      font-size: 0.75rem;
      text-align: left;
      align-self: flex-end;
    }
  }

  .text-detail {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: to-rem(14);
    gap: to-rem(16);
    text-transform: capitalize;
  }

  .container-button_radioList {
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }

  .user-settings-profile-customize__main {
    overscroll-behavior: none;
    overflow: hidden;
    // margin-bottom: to-rem(30);
  }

  .user-settings-profile-customize__footer {
    position: fixed;
    z-index: 10;
    bottom: to-rem(16);
    width: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: to-rem(0, 8);

    @media only screen and (min-width: 1280px) {
      bottom: to-rem(36);
    }
  }

  .user-settings-preferences {
    height: calc(100dvh - #{$spacing});
    overflow-y: auto;
    overscroll-behavior: none;

    .list-container {
      .list-container-settings {
        .text-detail {
          font-weight: 400;
          font-size: to-rem(14);
          text-transform: capitalize;
        }
      }
    }
  }

  // .user-settings-slid-up {
  //   bottom: to-rem(80);

  //   .list-item-slid-up {
  //     #button {
  //       margin-top: to-rem(12);
  //     }
  //   }
  // }
}

// .user-settings-slid-up {
//   bottom: to-rem(80);

//   .list-item-slid-up {
//     #button {
//       margin-top: to-rem(12);
//     }
//   }
// }

.container-radio_radioList {
  height: to-rem(160);
  overscroll-behavior: none;
  overflow-y: auto;
}

.toggle-height {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  color: var(--white);
}

@media screen and (max-width: 380px) {
  .container-radio_radioList {
    height: to-rem(160);
    overscroll-behavior: none;
    overflow-y: auto;
  }
}

@media only screen and (min-width: 30em) {
  .container-radio_radioList {
    overscroll-behavior: none;
    overflow-y: auto;
  }
}
