@import "../../scss/functions.scss";

.feed-profile-label {
  display: flex;
  align-items: center;
  gap: to-rem(8);

  &.feed-profile-label--column {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }

  .feed-profile-label__name {
    .feed-profile-label__text {
      margin: 0;
      display: flex;
      align-items: center;
      color: var(--white);
      font-family: "Montserrat Variable";
      font-weight: 600;
      font-size: to-rem(16);
      gap: to-rem(8);
      text-wrap: nowrap;

      &:last-child {
        color: var(--ash);
        font-family: "Roboto Regular";
        font-weight: 400;
        font-size: to-rem(14);
      }
    }
  }
}
