@import "../../scss/functions.scss";

.roses-credit-container-popular {
  border-radius: to-rem(16);
  background-color: var(--charcoal);
}

.roses-credit-container-popular-active {
  background-color: var(--brand-primary);

  [data-gold="true"] {
    background-color: #ffb36b1a;
  }
}

.roses-credit {
  border: 0;
  border-radius: var(--radius-mi);
  width: 100%;
  padding: to-rem(1);
  color: var(--gray);
  background: var(--border-primary);
  transition:
    color 0.2s,
    background-color 0.2s,
    border-color 0.2s;
  &:active,
  &--active {
    border-radius: var(--radius-mi);
    background-color: var(--brand-primary);
    color: var(--white);
    // border: to-rem(1) solid var(--pink-light);

    .roses-credit__icon {
      background-image: url(../../assets/icons/logos/logo-fill-pink.svg);
    }

    .roses-credit__discount {
      background-color: #00000040;
    }

    .roses-credit__content {
      background-color: #000000bf;
    }
  }

  &[data-is-gold="true"] {
    &:active,
    &.roses-credit--active-gold {
      border-color: var(--gold-dark);
      background-color: #ffb36b1a;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &--gold {
    border-color: var(--gold-dark);
    background-color: rgba(255, 179, 107, 0.1);
    color: var(--white);

    .roses-credit__icon {
      background-image: url(../../assets/icons/logos/logo-fill-gold.svg);
    }
  }

  &__iconContainer {
    width: 1.5rem;
    height: 1.5rem;
    flex-shrink: 0;
  }

  &__logo {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__icon {
    width: 100%;
    height: 100%;
    display: inline-block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url(../../assets/icons/logos/logo-fill-gray.svg);
    transition: background-image 0.2s;
  }

  &__text {
    color: var(--white);
    font-weight: 700;
    font-size: 1.25rem;
  }

  &__values {
    color: var(--white);
    font-size: 0.875rem;
    opacity: 0.75;

    &--old {
      display: inline-block;
      font-size: to-rem(11);
      opacity: 0.5;
      text-decoration: line-through;
    }
  }

  &__discount {
    border-radius: 0.25rem;
    padding: 0.25rem 0.5rem;
    color: var(--white);
    font-weight: 500;
    background: #ee4e8740;
  }

  &__content {
    border-radius: var(--radius-mi);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: to-rem(8);
    background: var(--bg-secondary);
  }

  &__popular {
    border: to-rem(1) solid var(--charcoal);
    border-radius: var(--radius-mi);
    padding: 0;
  }

  &__popular-active {
    border-color: var(--pink-light);
    background-color: var(--brand-primary);

    .roses-credit__content {
      background-color: #000000bf;
    }
  }

  &__popular-active-gold {
    border-color: var(--gold-dark);
    background-color: #ffb36b1a;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: to-rem(8);
  }

  &__token {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__title {
    margin: 0;
    border-radius: to-rem(16, 16, 0, 0);
    background-color: var(--charcoal);
    color: var(--white);
    font-size: 0.75rem;
    line-height: 200%;
    text-align: center;
  }

  &__title-active {
    background-color: var(--brand-primary);
  }

  &__disabled {
    opacity: 0.5;
  }
}
