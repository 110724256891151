@import "../../scss/functions.scss";

.tooltip {
  position: relative;
  width: to-rem(16);
  height: to-rem(16);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &__box {
    position: absolute;
    border-radius: to-rem(8);
    width: fit-content;
    padding: to-rem(4) to-rem(8);
    color: var(--white);
    font-size: to-rem(10);
    background: var(--pink);
    opacity: 1;
    visibility: visible;
    pointer-events: none;
    transition: opacity 0.25s;
    max-width: to-rem(250);
    min-width: to-rem(150);
    text-align: left;
  }

  &__top {
    top: to-rem(-5);
    left: 0;
    transform: translate(0%, -100%);
  }

  &__left {
    top: 0;
    left: to-rem(-5);
    transform: translate(-100%, 0);
  }

  &__right {
    top: 0;

    left: calc(100% + #{to-rem(5)});
  }

  &__bottom {
    bottom: -to-rem(5);
    left: 0;
    transform: translate(0%, 100%);
  }

  &__visible {
    z-index: 5;
    opacity: 1;
    visibility: visible;
  }

  &__fade-out {
    opacity: 0;
    visibility: visible;
  }

  .icon {
    font-size: to-rem(14);
  }

  .icon:before {
    display: block;
    color: var(--gray);
  }

  .icon:hover::before {
    cursor: pointer;
  }
}
