@import "../../scss/functions.scss";

.user-settings-edit-bio {
  &.user-settings-edit-bio-wrapper {
    margin: 0;
  }

  .user-settings-edit-bio-inner {
    padding: 0;
  }

  .user-settings-edit-bio__main {
    display: grid;
    gap: var(--inner-gap);
  }

  .user-settings-edit-bio__input-container {
    display: grid;
    gap: var(--inner-gap);
  }

  .user-settings-edit-bio__counter {
    display: flex;
    justify-content: flex-end;
    color: var(--white);
    font-size: to-rem(14);
  }
}
