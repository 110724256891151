@import "../../scss/functions.scss";

.chat-list {
  &.chat-list-wrapper {
    margin: 0;
  }

  .chat-list-inner {
    padding: 0;
  }

  .chat-list__top {
    position: sticky;
    z-index: 10;
    top: 0;
    margin-bottom: to-rem(24);
  }

  .chat-list__search-container {
    position: relative;
    display: flex;
    flex-direction: column;
  }

  .chat-list__list-container {
    display: flex;
    flex-direction: column;
    gap: to-rem(8);
  }

  .list-item-secondary-container {
    cursor: pointer;

    .notification-count {
      border-radius: 100%;
      width: to-rem(20);
      height: to-rem(20);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--brand-primary);

      p {
        color: var(--black);
        font-weight: 600;
        font-size: to-rem(11);
      }
    }

    .icon-left-border {
      img {
        border-radius: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .notification-count {
    border-radius: 50%;
    height: to-rem(20);
    justify-content: center;
    align-items: center;
    padding: to-rem(5);
    background: var(--pink-light);
    aspect-ratio: 1 / 1;

    &__value {
      margin: 0;
      color: var(--black);
      font-weight: 600;
      font-size: to-rem(10);
    }
  }

  .chat-list__list {
  }

  .chat-list__subtitle {
    border-radius: to-rem(8);
    padding: to-rem(8);
    font-family: "Roboto Regular";
    background: var(--bg-tertiary);
  }
  .chat-list__subtitle,
  .chat-list__no-chats {
    margin: 0;
    font-size: to-rem(12);
  }

  .list-item-link .image-circle {
    border-width: to-rem(1);
  }

  .list-item-link .item__left-icon.icon-fill {
    padding: 0;
  }

  #input-text-default .input-icon-right {
    top: to-rem(12);
    width: to-rem(24);
    height: to-rem(24);
  }

  .list-item-link.show {
    display: flex;
  }

  .list-item-link.hidden {
    display: none;
  }

  .list-item-link-action {
    border-radius: to-rem(8);
    background: var(--charcoal);
  }
}
