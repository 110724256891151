@import "../../../scss/functions.scss";

#header-default {
  margin-top: to-rem(40);
  display: flex;
  flex-direction: column;
  max-width: to-rem(390);

  #title-text {
    margin-bottom: 0;
  }

  .header-default__icon-title {
    display: flex;
    flex-direction: column;

    &.header-default__icon-gap {
      gap: to-rem(24);
    }

    &.inline-elements {
      flex-direction: row;
      gap: to-rem(16);

      .icon-container {
        margin-top: to-rem(20);
      }

      .header__no-margin-top {
        margin: 0;
        align-items: center;
      }
    }
  }

  &.gap-between {
    gap: to-rem(16);
  }

  .icon-container {
    [alt="Roses Logo"] {
      width: to-rem(32);
      height: to-rem(32);
      // padding: 3px;
    }

    [alt="left arrow icon"] {
      width: 100%;
      height: 100%;
      padding: to-rem(4, 0);
      cursor: pointer;
      max-width: to-rem(24);
      max-height: to-rem(24);
      cursor: pointer;

      &:hover {
        transition: all 0.3s ease;
      }
    }
  }

  .description-header {
    color: var(--gray);
    font-size: to-rem(14);
  }

  .header__no-margin-top {
    margin: 0;
    align-items: center;
  }
}
