#radio-list {
  .list-label-input-container {
    height: 82px;
    display: grid;
    align-items: flex-start;
    grid-template-columns: 60px 1fr 24px;
    border-bottom: 1px solid var(--charcoal);
    align-content: center;

    .image-list {
      position: relative;
      margin: 0 auto;
      border: 1px solid var(--charcoal);
      border-radius: 20px;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;

      img {
        width: 1.125rem;
        height: 1.125rem;
        align-self: center;
      }

      .sphere-icon {
        position: absolute;
        right: 2px;
        bottom: 0;
        border-radius: 4px;
        width: 8px;
        height: 8px;

        &.pink {
          background-color: var(--pink);
        }

        &.blue {
          background-color: var(--blue);
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }

    label {
      margin-top: 2px;
      margin-left: 12px;
      color: var(--white);
      font-size: 0.875rem;
      .title-radio,
      .sub-radio {
        font-weight: 400;
      }

      .title-radio {
        color: var(--white);
        font-size: 14px;
      }

      .sub-radio {
        font-size: 12px;
      }
    }

    .input-radio {
      margin-top: 20px;
      accent-color: var(--pink);
    }

    .input-radio:checked + label {
      color: var(--white);
    }

    .input-radio:after {
      position: relative;
      top: -4px;
      left: -1px;
      border: 4px solid var(--charcoal);
      border-radius: 15px;
      width: 16px;
      height: 16px;
      display: inline-block;
      background-color: var(--charcoal);
      content: "";
      visibility: visible;
    }

    .input-radio:checked:after {
      position: relative;
      top: -4px;
      left: -1px;
      border: 4px solid var(--charcoal);
      border-radius: 15px;
      width: 16px;
      height: 16px;
      display: inline-block;
      background-color: var(--pink);
      content: "";
      visibility: visible;
    }
  }
}
