@import "../../../../scss/functions.scss";
$rem-334: to-rem(334);

.send-roses-modal {
  margin: 0 auto;
  border-radius: var(--radius-mi);
  width: 100%;
  background-color: var(--dawn);
  padding: to-rem(24);
  //TO DO confirm the line below
  // background-color: var(--bg-primary);
  max-width: to-rem(342);
  // &__wrapper {
  // height: calc(100dvh - #{
  //     $rem-334}
  // )}
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  align-content: center;
  gap: to-rem(24);

  &[data-type="send"] {
    height: auto;
  }

  [alt="roses logo icon"] {
    width: to-rem(24);
    height: to-rem(24);
  }

  .send-roses-modal__top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: to-rem(12);
  }

  .send-roses-modal__title {
    margin: 0;
    font-weight: 500;
    font-size: to-rem(16);
  }
  .send-roses-modal__description,
  .range-roses-to-send {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: to-rem(14);
    text-align: center;
  }

  .range-roses-to-send {
    display: grid;
    // margin-bottom: var(--standard-spacing);
    grid-template-columns: to-rem(48) 1fr to-rem(48);
    justify-items: center;
    row-gap: var(--inner-gap);
    .range-roses-to-send__less,
    .range-roses-to-send__more {
      border-radius: 100%;
      width: to-rem(48);
      height: to-rem(48);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--charcoal);
      cursor: pointer;

      img {
        width: to-rem(14);
        height: to-rem(14);
      }
    }

    .range-roses-to-send__less {
    }

    .range-roses-to-send__value {
      width: to-rem(198);

      .value-to-send {
        color: var(--white);
        font-weight: 700;
        font-size: to-rem(32);
      }

      .available-value {
        font-weight: 400;
        font-size: to-rem(14);
      }
    }

    .range-roses-to-send__more {
    }

    .range-roses-to-send__range {
      width: 100%;
      grid-column: 1/4;
    }
  }

  .send-roses__buttons-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--inner-gap);
  }

  .range-rose__value--quotation {
    color: var(--white);
  }

  &__top {
    text-align: center;
  }

  &__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--inner-gap);
    margin: 16px 0;
  }

  &__toggle-ipt {
    display: flex;
    align-items: center;
    gap: to-rem(8);
  }

  &__grid {
    width: 100%;
    display: grid;
    align-items: flex-end;
    padding-bottom: var(--standard-spacing);
    grid-template-columns: repeat(2, 1fr);
    gap: var(--inner-gap);
  }

  &__table {
    width: 100%;
    color: var(--white);
    font-size: to-rem(14);
    th,
    td {
      padding: to-rem(8) 0;
    }
    tr:first-of-type th,
    td {
      padding: 0;
    }
    tr:last-of-type th,
    td {
      padding-bottom: 0;
    }

    tr:last-of-type {
      border-top: to-rem(1) solid var(--white);
    }

    th {
      text-align: left;
    }

    td {
      text-align: right;
    }

    &--gold {
      color: var(--gold-light);
    }

    &--data {
      color: var(--gray-light);
    }
  }

  &__dropDown {
    width: 100%;

    &#drop-down-component {
      margin: 0;

      #button.tertiary {
        padding: 0;
      }
    }
  }

  &__info {
    flex-direction: column;
    align-items: center;
    border-top: to-rem(1) solid var(--gray-dark);
    border-bottom: to-rem(1) solid var(--gray-dark);
    overflow: hidden;

    p {
      margin: 0;
      text-align: center;
    }

    &.icon-disclaimer img {
      margin-right: 0;
      margin-bottom: to-rem(8);
      width: to-rem(24);
      height: to-rem(24);
    }
  }

  &__info-head {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: to-rem(4);
  }

  &__info-title {
    margin: 0;
    color: var(--gold-light);
    text-align: center;
  }

  &__gold-value {
    color: var(--white);
  }

  &__info-icon {
    &.icon:before {
      color: var(--gray-light);
    }
  }

  &__info-text {
    color: var(--gray-light);
  }
}
