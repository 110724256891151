@import "../../scss/functions.scss";

.user-settings-language {
  &.user-settings-language {
    margin: 0;
  }

  .user-settings-language-inner {
    padding: 0;
  }

  .user-settings-language__icon {
    width: to-rem(24);
    height: to-rem(24);
    padding: to-rem(2.4);
  }
}
