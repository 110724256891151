@import "../../scss/functions.scss";
$header-height: to-rem(72);

.record-self {
  height: calc(100dvh - #{$header-height});

  &.record-self-wrapper {
    margin: 0;
  }

  .record-self-inner {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .record-self__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    text-align: center;
  }

  .record-self__bottom {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }

  .record-self__controllers {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .record-self__prefix {
    margin-bottom: to-rem(24);
    color: var(--gray-light);
    font-size: to-rem(14);
  }

  .record-self__guid-line {
    margin-bottom: to-rem(40);
    border-radius: 100%;
    width: to-rem(284);
    height: to-rem(344);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .record-self__svg {
    width: to-rem(284);
    height: to-rem(344);
  }

  .record-self__progress-border {
    position: absolute;
    top: 0;
    left: 0;
    border: 5px solid transparent;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    transition: border-image 0.1s linear;
  }

  .record-self__icon {
    position: absolute;
    font-size: to-rem(60);
  }

  .record-self__title {
    margin-top: 0;
    font-size: to-rem(24);
  }

  .record-self__video {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100dvh;
    object-fit: cover;
    max-width: 100%;
    inset: 0;
  }

  .record-self__preview {
    z-index: 3;
    @extend .record-self__video;
  }

  .record-self__preview-buttons {
    position: absolute;
    z-index: 3;
    bottom: to-rem(16);
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 to-rem(16) 0 to-rem(16);
    gap: to-rem(16);
  }

  .record-self__hidden {
    visibility: hidden;
  }

  .record-self__play-button {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    font-size: to-rem(40);
    transform: translate(-50%, -50%);
  }
}
