#media-storage-gallery {
  background-color: var(--bg-primary);
  position: relative;

  .media-storage-gallery__inner {
    .media-storage-gallery__buttons-wrapper {
      position: absolute;
      width: calc(100% - 32px);
      display: grid;
      gap: 16px;
    }
  }

  .media-storage-gallery__buttons-wrapper {
    width: 100%;
    overflow: hidden;
    .action-button-group {
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      white-space: nowrap;
      // padding: 8px 0 0 0;
      z-index: 11;

      .action-button {
        min-width: 90px;
      }
    }

    .media-storage-gallery__search-input {
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100px;
        top: 8px;
        left: 0;
        z-index: 1;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 1) 99.9%
        );
        pointer-events: none;
      }
    }
  }

  .media-storage-gallery__page-title {
  }

  .media-storage-gallery__cards-wrapper {
    display: grid;
    gap: 8px;
    grid-auto-rows: min-content;
    grid-template-columns: repeat(auto-fill, minmax(114px, 1fr));
    width: 100%;
    overflow-y: auto;
    height: calc(100dvh - 142px);
    padding: 126px 0 110px 0;

    .cards-wrapper__file-card {
      position: relative;
      border-radius: 8px;
      transition: all 0.3s ease-in-out;
      position: relative;
      cursor: pointer;
      aspect-ratio: 8/10;
      height: 100%;

      .card__tag-file-type {
        font-size: 10px;
        font-weight: 700;
        color: var(--white);
        position: absolute;
        bottom: 16px;
        right: 16px;
        background-color: #33333380;
        padding: 4px 8px;
        border-radius: 4px;
      }

      .file-card__card {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        object-fit: cover;
      }
      &.file-card__selected {
        border: 2px solid var(--pink-light);
        transition: all 0.2s ease-in-out;
        .file-card__card {
          border-radius: 6px;
        }
      }
    }
  }

  @media only screen and (min-width: 48rem) {
    background-color: var(--bg-tertiary);

    border-radius: 0 0 0 16px;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100px;
      top: 0;
      left: 0;
      z-index: 1;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 99.9%
      );
      pointer-events: none;
    }

    .media-storage-gallery__inner {
      padding: 24px;
    }
    .action-button-group {
      padding-top: 0;
    }
    .media-storage-gallery__page-title {
      padding-left: 8px;
    }

    .media-storage-gallery__page-title {
      display: none;
    }
    .media-storage-gallery__cards-wrapper {
      padding: 64px 0 110px 0;
      // grid-auto-rows: 143px;
      height: calc(100dvh - 152px);

      .cards-wrapper__file-card {
      }
    }
  }
}
