#input-list-select {
  position: relative;
  .input-list-select__ul {
    .input-list-select__li {
      position: relative;
      display: grid;
      align-items: center;
      justify-content: space-between;
      grid-template-columns: auto 1fr auto;
      gap: 8px;

      cursor: pointer;
      &:first-child {
        padding-top: 16px;
      }

      &:last-child {
        padding-bottom: 16px;
      }
      .li__icon-left {
      }
      .li__text {
        color: var(--white);
        font-size: 14px;
        font-weight: 400;
      }

      .li__sub-text {
        font-size: 12px;
        font-weight: 400;
        color: var(--gray-light);
      }
      .li__node {
      }

      .li__chevron-icon {
      }

      .separator {
        position: absolute;
        bottom: 0;
        left: 16px;
        width: calc(100% - 32px);
        height: 1px;
        background-color: var(--charcoal);
      }
    }
  }
}
