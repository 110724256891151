@import "../../scss/functions.scss";
$titleSpacing: 80;
$spacing: 48;

.user-settings-subscription {
  height: calc(100dvh - #{$titleSpacing});
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  &.user-settings-subscription-wrapper,
  .user-settings-subscription-inner {
    margin: 0;
    padding: 0;
  }

  .container-buttons {
    position: absolute;
    bottom: to-rem(40);

    width: calc(100% - #{$spacing});
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }

  .user-settings-subscription__roses-container {
    position: relative;
    margin: to-rem(16 24);
    border-radius: to-rem(8);
    display: flex;
    flex-direction: column;
    color: #7b7b7b;
    gap: to-rem(16);
  }
}
