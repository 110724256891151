@import "../../scss/functions.scss";

.gender-identification {
  &.gender-identification-wrapper,
  .gender-identification-inner {
    margin: 0;
    padding: 0;
  }

  #icon-and-subtitle {
    height: to-rem(10);
  }
}
