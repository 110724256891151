.photo-required {
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;

  .large-image {
    grid-column: span 2;
    grid-row: span 2;
  }

  .banner {
    grid-column: span 3;
    grid-row: span 1;
    min-height: 160px;
    max-height: 160px;

    &::before {
      position: absolute;
      z-index: 8;
      top: 0;
      right: 0;
      bottom: -1px;
      left: 0;
      pointer-events: none;
      content: "";
      background: linear-gradient(
        to top,
        var(--banner-color) 0%,
        rgba(238, 78, 135, 0) 90%
      );
    }
  }

  .banner:not(:has(img))::before {
    display: none;
  }

  [alt="remove icon"] {
    cursor: pointer;
  }

  .photo-preview {
    position: relative;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dawn);
    overflow: hidden;
    //background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23555555FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
    cursor: pointer;
    flex-basis: 31.7%;
    aspect-ratio: 3 / 4;
    span,
    img {
      background-color: var(--dawn);
    }
  }

  .photo-preview input[type="file"] {
    position: absolute;
    z-index: 8;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  .border-empty {
    border: 3px dashed var(--charcoal);
  }

  .border-none {
    border: none;
  }

  .preview-images {
    position: relative;
    border-radius: var(--radius-mi);
    width: 100%;
    height: 100%;
    display: grid;
    //z-index: 2;
    cursor: pointer;

    &.grid-image {
      border-radius: 24px;
    }

    .cover-text {
      position: absolute;
      z-index: 9;
      bottom: 16px;
      left: 16px;
      color: white;
      font-family: "Roboto Bold";
      font-size: 10px;
      /* top: 12px;
            */
      background: transparent;
    }

    video {
      z-index: 2;
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }

    img {
      z-index: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 2;
    }

    button {
      position: absolute;
      z-index: 9;
      top: 5px;
      right: 5px;
      border: none;
      background-color: transparent;

      img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 10px;
      }
    }

    .empty-photo {
      position: absolute;
      z-index: 0;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
    }
  }

  .photo-preview {
    position: relative;
    transition: transform 0.3s ease-in-out;
  }

  .photo-preview:active:not(:empty):not(:has(video)) {
    animation: shake 0.5s ease-in-out;
    transform: scale(1.1);
  }

  .preview-images img {
    transition: transform 0.3s ease;
  }

  .preview-images img:active {
    transform: scale(1.2);
  }
}
