.toggle-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggle-switch {
  position: relative;
  border: 1px solid var(--gray-dark);
  border-radius: 15px;
  width: 50px;
  background-color: var(--charcoal);
  padding: 12px 0;
  transition: background-color 0.3s ease;
}

.toggle-switch.on {
  background-color: #3f0628;

  &[data-gold="true"] {
    background-color: var(--gold-dark);
  }
}

.toggle-switch.off {
  background-color: var(--dawn);
}

.toggle-handle {
  position: absolute;
  top: 50%;
  max-height: 20px;
  min-height: 20px;
  left: 4px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  min-width: 20px;
  max-width: 20px;
  background-color: var(--gray-dark);
  transition:
    left 0.3s ease,
    background-color 0.3s ease;
  transform: translateY(-50%);
  box-shadow: 0 2px 4px 0 #00000059;
}

.toggle-switch.on .toggle-handle {
  left: 24px;
}

.handle-on {
  background-color: var(--brand-primary);

  &[data-gold="true"] {
    background-color: #ffb36b;
  }
}

.handle-off {
  background-color: var(--gray-dark);
}
