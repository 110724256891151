@import "../../../scss/functions.scss";

.icon-disclaimer {
  display: flex;
  background-color: transparent;
  padding: to-rem(16) 0;
  align-content: center;
  max-width: to-rem(460);
  gap: to-rem(8);

  p {
    color: var(--gray);
    align-self: center;

    &.isWarning {
      color: var(--peach);
    }
  }

  .container-checkbox {
    position: relative;
    margin-right: to-rem(28);
    width: auto;
    font-family: "Roboto Regular";
    font-weight: 400;
    font-size: to-rem(14);
    cursor: pointer;

    .container-checkbox__checkmark {
      position: absolute;
      right: 0;
      left: 0;
      border: to-rem(1) solid var(--gray-dark);
      border-radius: to-rem(4);
      width: to-rem(24);
      height: to-rem(24);
      background-color: var(--bg-secondary);

      &::after {
        top: to-rem(2);
        margin: to-rem(3);
        border-radius: to-rem(2);
        width: to-rem(16);
        height: to-rem(16);
        display: none;
        background-color: var(--brand-primary);
        content: "";
        background-image: url("../../../assets/icons/check-simple-gray.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: to-rem(10);
      }

      &.isWarning {
        border: to-rem(1) solid var(--peach);
      }
    }

    input[type="checkbox"] {
      height: to-rem(48);
      display: none;
      cursor: pointer;
    }

    input:checked ~ .container-checkbox__checkmark:after {
      display: block;
    }
  }

  img {
    width: to-rem(14);
    height: to-rem(14);
    background-color: transparent;
    // margin-right: to-rem(8);
  }
}
