.button-border {
  border-radius: 50%;
  width: 280px;
  height: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-secondary);

  .button-inside-border {
    border-radius: 50%;
    width: 240px;
    height: 240px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, var(--peach), var(--pink-hot));

    .button-inner {
      border-radius: 50%;
      width: 190px;
      height: 190px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(0deg, var(--peach), var(--pink-hot));

      .button-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: var(--white);
        font-family: "Roboto Bold";
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
      }
    }
  }
}
