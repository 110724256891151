@import "../../scss/functions.scss";
$rem-16: to-rem(16);
$rem-112: to-rem(112);
$rem-110: to-rem(110);

.chat-container-page {
  position: relative;
  height: calc(100dvh);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: var(--navbar-height);
  padding-bottom: var(--standard-spacing);
  overflow: hidden;

  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: to-rem(100);
    content: "";
    background: linear-gradient(to bottom, var(--black) 30%, transparent 100%);
    pointer-events: none;
  }

  &::after {
    position: absolute;
    z-index: 0;
    bottom: to-rem(0);
    left: 0;
    width: 100%;
    height: to-rem(124);
    content: "";
    background: linear-gradient(to top, var(--black) 30%, transparent 100%);
    pointer-events: none;
  }

  .chat-title {
    gap: 0;

    .back-button {
      margin-right: to-rem(10);
    }
  }

  .chat-page__message textarea {
    border: 1px solid var(--gray-dark);
    padding: to-rem(16) to-rem(150) to-rem(16) to-rem(16);
  }

  .messages-container {
    position: absolute;
    width: -webkit-fill-available;
    height: calc(100dvh - 80px);
    padding: to-rem(80, 0, 100, 0);
    // padding: 6.25rem 0 8rem 0;
    // height: calc(100dvh - 150px);
    // height: calc(100dvh - 120px);
    // padding-top: to-rem(56);
    overflow-y: scroll;
    overscroll-behavior: none;
  }

  .actions-input-chat {
    position: relative;
    z-index: 1;
    margin-bottom: to-rem(30);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    // padding-bottom: 8px;
    align-self: flex-end;
    // gap: var(--inner-gap);
    overflow: visible;
    background: linear-gradient(180deg, #0000, #000);
    pointer-events: none;

    &::after {
      position: absolute;
      z-index: 1;
      bottom: to-rem(80);
      left: 0;
      width: 100%;
      height: to-rem(40);
      content: "";
      pointer-events: none;
    }

    @media only screen and (min-width: 48rem) {
      margin-bottom: to-rem(8);
      padding: 6.25rem 0 0 0;
    }
  }

  @media only screen and (min-width: 48rem) {
    height: calc(100dvh - 80px);
    padding-top: 0;

    .messages-container {
      height: calc(100dvh - 80px);
      padding: to-rem(80, 0, 100, 0);
      // height: calc(100dvh - 45px);
      // height: calc(100dvh - 64px);
    }
  }
}
