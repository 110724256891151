.moments-track-wrapper {
  position: relative;
  display: flex;

  .moments-creator-details {
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    .profile-title__moments {
      width: 100%;

      .user-info {
        padding: 16px 0;
      }
    }

    .profile-banner-buttons {
      display: flex;
      width: 100%;
      gap: 8px;
    }
  }

  #moments-track {
    position: relative;
    height: 100%;

    .moments-track__content-wrapper {
      position: relative;
      height: 100%;

      [alt="close icons"] {
        position: absolute;
        z-index: 2;
        top: 24px;
        right: 24px;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .content-wrapper__progressbar-wrapper {
        position: absolute;
        z-index: 8;
        top: 16px;
        right: 24px;
        left: 24px;
        width: calc(100% - 48px);
        display: flex;
        gap: 8px;
      }

      .content-wrapper__moments-cards {
        width: 100dvw;
        height: 100dvh;
        grid-template-rows: 1fr auto;

        &.total-size {
          grid-template-rows: 1fr;
        }
      }
    }
  }

  @media only screen and (min-width: 48rem) {
    background-color: var(--dawn);
    border-radius: 24px;

    .moments-creator-details {
      overflow-y: auto;
      overflow-x: hidden;
      width: 390px;
    }

    #moments-track {
      display: flex;
      align-items: center;

      .moments-track__content-wrapper {
        .content-wrapper__moments-cards {
          height: calc(100dvh - 48px);
        }

        [alt="close icons"] {
          top: 60px;
        }

        .content-wrapper__progressbar-wrapper {
          top: 24px;
        }
      }
    }
  }
}
