@import "../../scss/functions.scss";
$header-height: to-rem(80);

.user-settings-privacy {
  height: calc(100dvh - #{$header-height});
  display: flex;
  flex-direction: column;
  gap: to-rem(16);

  &.user-settings-privacy-wrapper {
    margin: 0;
    padding-right: 0;
    // padding-bottom: 0;
    padding-left: 0;
  }

  .user-settings-privacy-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .user-settings-privacy__main {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
    flex: 1;
  }

  .user-settings-privacy__options {
    padding: to-rem(0, 16);
    flex: 1;
  }

  .list-container-settings__bts-container {
    position: absolute;
    right: to-rem(8);
    bottom: to-rem(8);
    left: to-rem(8);
  }

  .is-profile-hidden {
    position: relative;
    margin-left: to-rem(56);
    display: flex;
    flex-direction: column;
  }

  .location-list-item__location {
    padding: to-rem(21, 0);
  }

  @media only screen and (min-width: 48rem) {
    .list-container-settings {
      margin: to-rem(0 16);
    }
  }
}
