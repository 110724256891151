.lazy-image-container {
  .loading-block {
    position: absolute;
    left: 0;
    right: 0;
    background-color: var(--bg-secondary);
    width: 100%;
    height: 100%;
    z-index: 8;
    filter: blur(0);
  }

  .profile-card__card {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 6;

    &.blurred-picture {
      width: 100%;
      filter: blur(5px);
      width: 100%;
    }
  }
}
