@import "../../scss/functions.scss";
$header-height: to-rem(72);

.send-photo-album {
  height: calc(100dvh - #{$header-height});
  display: flex;
  flex-direction: column;
  width: 100%;

  &.send-photo-album-wrapper {
    margin: 0;

    @media screen and (min-width: 768px) {
      border-radius: var(--radius-mi);
      background-color: var(--bg-secondary);
      max-width: to-rem(500);
    }
  }

  .send-photo-album-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;

    @media screen and (min-width: 768px) {
      padding: var(--standard-spacing);
    }
  }

  .send-photo-album__main {
    margin-bottom: auto;
    overflow-y: auto;
  }

  .send-photo-album__title {
    margin: 0;
    margin-bottom: to-rem(16);
    font-size: to-rem(24);
  }

  .send-photo-album__header {
    margin-bottom: to-rem(24);
  }

  .send-photo-album__bottom {
    display: flex;
    flex-direction: column;
    gap: to-rem(24);
  }

  .send-photo-album__sub-text {
    margin-bottom: to-rem(16);
    align-items: start;
    padding: 0;
  }

  .send-photo-album__sub-text-alt {
    margin-bottom: 0;
    @extend .send-photo-album__sub-text;
  }

  .send-photo-album__sub-text-icon {
    margin-top: 0;
    width: to-rem(24);
    height: to-rem(24);
  }
}
