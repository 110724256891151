@import "../../scss/functions.scss";

.user-settings-change-password {
  display: flex;
  flex-direction: column;

  &.user-settings-change-password-wrapper {
    margin: 0;
  }

  .user-settings-change-password-inner {
    display: flex;
    flex-direction: column;
    padding: 0;
    flex: 1;
  }

  .change-password-container {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .change-password__icon {
    width: to-rem(24);
    height: to-rem(24);
    padding: to-rem(4);
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }

  .password-container {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }

  .separator {
    position: relative;
    margin: to-rem(24) 0;
    width: 100%;
    height: to-rem(1);
    background-color: var(--charcoal);
  }

  @media only screen and (min-width: 48rem) {
  }
}
