@import "../../scss/functions.scss";

.user-settings-goals {
  &.user-settings-goals-wrapper {
    margin: 0;
  }

  .user-settings-goals-inner {
    padding: 0;
  }
  .goals {
    padding-top: var(--standard-spacing);
    display: flex;
    flex-direction: column;
    gap: var(--inner-gap);
  }

  .goals__history {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: to-rem(1) solid var(--charcoal);

    &:hover {
      cursor: pointer;
    }

    &:last-of-type {
      border: 0;
    }
  }

  .goals__top {
    display: flex;
    justify-content: space-between;
  }

  .goals__history-title {
    font-size: to-rem(14);

    &--active {
      color: var(--green);
    }

    &--inactive {
      color: var(--gray);
    }
  }

  .goals__bottom {
    display: flex;
    justify-content: space-between;
  }

  .goals__objective {
    font-size: to-rem(14);
  }

  .goals__amount {
    font-size: to-rem(14);
  }

  .goals-fallback {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .goals-logo {
    width: to-rem(24);
  }

  .goals-no-post {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: to-rem(14);
    gap: to-rem(8);
  }

  .goals-no-post-support-text {
    color: var(--white);
    font-size: to-rem(18);
  }
}

.goals__modal {
  display: flex;
  flex-direction: column;
  gap: to-rem(10);
}
