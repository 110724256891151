.toastContainer {
  position: absolute;
  z-index: 10;
  left: 50%;
  border: 1px solid var(--charcoal);
  border-radius: var(--radius-mi);
  width: 342px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--bg-secondary);
  padding: 16px;
  transform: translateX(-50%);
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  opacity: 0;
  transition:
    opacity 0.5s ease-in-out,
    top 0.5s ease-in-out;
}

.iconContainer {
  position: absolute;
  top: 24px;
  left: 24px;
  border: 1px solid var(--gray-dark);
  border-radius: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textContainer {
  margin-left: 16px;
  // background-color: var(--bg-secondary);
}

.textPrimary {
  margin-bottom: 0;
  color: var(--white);
  font-size: 14px;
  // background-color: var(--bg-secondary);
}

.textSecondary {
  margin-top: 0;
  color: var(--gray-light);
  font-weight: 400;
  font-size: 14px;
  // background-color: var(--bg-secondary);
}

.toastContainer.show {
  top: 24px;
  opacity: 1;
  z-index: 11;
}

.toastContainer.hide {
  z-index: 3;
  top: -100px;
  opacity: 0;
}
