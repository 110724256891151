@import "../../../scss/functions.scss";

.card-fallback {
  position: relative;
  z-index: 2;
  margin: 0 auto;
  margin-top: to-rem(56);
  border-radius: var(--radius-mi);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: to-rem(40);
  background: var(--bg-tertiary);
  max-width: to-rem(360);

  .card-fallback-title,
  .card-fallback-desc {
    margin-top: 0;
    text-align: center;
  }

  .card-fallback-title {
    margin-bottom: to-rem(8);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white);
    font-size: to-rem(16);
    gap: to-rem(8);
    font-weight: 400;
    font-size: to-rem(16);
  }

  .card-fallback-desc {
    margin-bottom: 0;
    color: var(--gray);
    font-size: to-rem(14);
    font-weight: 400;
    text-align: center;
  }

  .card-fallback-btn {
    margin-top: to-rem(24);
  }
}
