.collaborator-list {
  width: 100%;
  height: 100%;
  background-color: var(--bg-primary);
  // display: grid;
  // padding: 24px;
  // grid-template-rows: 80px repeat(auto-fill, minmax(auto, 1fr));
  grid-template-rows: 80px auto 1fr auto;

  .icon-left-border {
    img {
      border-radius: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .collaborator-list__list {
    margin-bottom: 16px;

    .list__users-items {
      height: calc(100vh - 318px);
      overflow-y: scroll;
    }
  }

  .collaborator-list__button-send-container {
    .button-send {
      border: none;
      border-radius: 24px;
      background-color: var(--charcoal);
      padding: 8px 24px;
      color: var(--white);
      font-family: "Montserrat Variable";
      font-weight: 600;
      font-size: 10px;
      outline: none;
      cursor: pointer;
      letter-spacing: 0.05em;
      text-transform: uppercase;

      &.sended-button {
        background-color: transparent;
        color: var(--gray-light);
      }
    }
  }

  .add-media-page__buttons-container {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    gap: 16px;
  }

  @media only screen and (min-width: 48rem) {
    padding: 24px;

    .collaborator-list__list {
      margin: 0;
      .list__users-items {
        height: calc(100vh - 376px);
      }
    }

    .page-title-container {
      background: transparent;

      .back-button {
        background-color: var(--charcoal);
      }
    }

    .add-media-page__buttons-container {
      margin-bottom: 0;
    }
  }
}
