.chat-bubble {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: var(--gray-dark);
  padding: 5px 15px;
  align-items: center;
  max-width: 50px;
  max-height: 30px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-top-left-radius: 2px;
  position: relative;
  z-index: 1;
  margin-bottom: 8px;
}

.typing {
  height: 17px;
  display: flex;
  align-items: center;
}

.typing .dot {
  margin-right: 4px;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  display: inline-block;
  background-color: var(--gray-dark);
  aspect-ratio: 1 / 1;
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  vertical-align: middle;
}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {
  0% {
    background-color: var(--gray);
    transform: translateY(0px);
  }

  28% {
    background-color: var(--gray-light);
    transform: translateY(-7px);
    //rgba(20,105,69,.4);
  }

  44% {
    background-color: var(--faded);
    transform: translateY(0px);
  }
}
