.user-settings-account {
  &.user-settings-account-wrapper {
    margin: 0;
  }
  .user-settings-account-inner {
    padding: 0;
  }
  .button-bottom {
    position: absolute;
    bottom: 0;
    margin-bottom: 24px;
    width: calc(100% - 50px);
  }

  .list-container-user-settings {
    gap: 0;

    .list-container-user-settings-list-item {
      cursor: pointer;
    }
  }
  .text-detail {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
