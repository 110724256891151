.user-settings-my-offers {
  &.user-settings-my-offers-wrapper {
    margin: 0;
  }

  .user-settings-my-offers-inner {
    padding: 0;
  }
  .list-container {
    gap: 0;
  }

  .container-buttons {
    position: absolute;
    bottom: 40px;
    width: calc(100% - 48px);
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
