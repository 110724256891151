@import "../../scss/functions.scss";
$header-height: to-rem(72);

.send-me-link {
  height: calc(100dvh - #{$header-height});

  &.send-me-link-wrapper {
    margin: 0;
  }

  .send-me-link-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: to-rem(24);
  }

  .send-me-link__title {
    margin: 0;
    margin-bottom: to-rem(16);
    font-size: to-rem(24);
  }

  .send-me-link__information {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }

  .send-me-link__row {
    display: grid;
    grid-template-columns: to-rem(125) auto;
    row-gap: to-rem(16);
    column-gap: to-rem(8);
  }

  .send-me-link__bottom {
    align-content: end;
    flex-grow: 1;
  }

  .send-me-link__icon-disclaimer {
    align-items: center;
    padding: 0;
    gap: to-rem(8);
  }

  .send-me-link__icon {
    margin: 0;
    width: to-rem(24);
    height: to-rem(24);
    padding: to-rem(2) to-rem(3);
  }
}
