@import "../../../scss/functions.scss";

#modal-default {
  position: fixed;
  top: 50%;
  left: 50%;
  border: none;
  width: 100%;
  transform: translate(-50%, -50%);
  background: none;
  user-select: none;

  .modal-content {
    position: relative;
    border-radius: var(--radius-mi);
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: #111111;
    animation: fadeIn 0.3s ease-out;
    gap: to-rem(24);

    &.modal-content--feed {
      padding: 0;

      @media screen and (min-width: 768px) {
        border-radius: 0;
      }
    }

    .header {
      .header__title {
        margin: 0;
        font-weight: 500;
        font-size: to-rem(16);
        text-align: center;
      }

      .header__text {
        font-size: to-rem(14);
        text-align: center;
      }
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.8);
}
