@import "../../scss/functions.scss";

.revenue__wrapper {
  display: grid;
  padding-bottom: to-rem(80);
  grid-template-columns: 1fr 1fr;
  gap: to-rem(8);

  .revenue {
    border-radius: var(--radius-mi);
    display: flex;
    flex-direction: column;
    font-size: to-rem(12);
    gap: to-rem(8);
    background: var(--bg-tertiary);

    &.revenue-inner {
      padding: to-rem(24);
    }

    .revenue__title {
      margin: 0;
      color: var(--gray-light);
      font-family: "Roboto Regular";
      font-size: to-rem(14);
    }

    .revenue__value {
      margin: 0;
      color: var(--white);
      font-family: "Roboto Bold";
      font-size: to-rem(24);
    }

    .revenue__txt {
      margin: 0;
      color: var(--gray);
      font-family: "Roboto Regular";
      font-size: to-rem(12);
    }

    .revenue__link {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--pink-light);
      font-size: to-rem(12);
      cursor: pointer;
    }

    .revenue__link-icon {
      font-size: to-rem(24);
    }

    .revenue__percent {
      display: flex;
      align-items: center;
      color: var(--green);
      font-size: to-rem(12);
      gap: to-rem(4);

      &--down {
        color: rgb(246, 45, 45);
      }
    }

    .revenue__percent-icon {
      border-radius: 50%;
      font-size: to-rem(16);
      // background: var(--dawn);

      &::before {
        color: var(--green);
        pointer-events: none;
      }

      &--down {
        &::before {
          color: rgb(246, 45, 45);
          pointer-events: none;
        }
      }
    }

    .revenue__progress-bar {
    }
  }
}
