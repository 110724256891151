.subscription__container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 400px;
  margin: 0 auto;

  .subscription-modal-buttons-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      color: var(--white);
    }
  }

  .loading {
    margin: 0 auto;
  }

  .subscription-modal__grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    align-items: flex-end;
  }
}
