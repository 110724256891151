@import "../../scss/functions.scss";

.user-settings-my-subscriptions-details {
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  &.user-settings-my-subscriptions-details-wrapper {
    margin: 0;
  }

  .user-settings-my-subscriptions-details-inner {
    padding: 0;
  }

  .user-settings-my-subscriptions-details_content {
    display: flex;
    flex-direction: column;
    // gap: to-rem(24);
    // margin: to-rem(24);

    .user-settings-my-subscriptions-details_content-table {
      width: 100%;
      color: var(--white);
      font-size: to-rem(14);

      tr {
        border-bottom: to-rem(1) solid var(--charcoal);
      }

      tr:last-of-type {
        border: none;
      }

      th {
        text-align: left;
      }

      td {
        color: var(--gray-light);
        text-align: end;
      }
      th,
      td {
        padding: to-rem(14, 0);
      }
    }

    .subscription-plan-modal {
      max-width: to-rem(400);
    }

    .subscription-auto-renewal {
      display: flex;
      justify-content: space-between;

      .subscription-auto-renewal__txt {
        color: var(--white);
        font-size: to-rem(14);
      }
    }
  }
}
