#preview-media-cards-list {
  display: flex;
  gap: 16px;
  align-items: flex-end;
  height: 110px;
  position: absolute;
  width: 100dvw;
  overflow-y: hidden;
  overflow-x: scroll;
  padding-bottom: 24px;

  &.gradient-background {
    &::before {
      position: absolute;
      z-index: 1;
      top: 24px;
      left: 0;
      width: 100%;
      height: 100px;
      content: "";
      background: linear-gradient(to top, var(--black) 30%, transparent 100%);
      pointer-events: none;
    }
  }

  &.justify-center {
    justify-content: center;
  }

  &.horizontal-margin-slider {
    margin-right: 100px;
  }

  .preview-media__image-icons-container {
    position: relative;
    z-index: 10;

    &:first-child {
      margin-left: 24px;
    }

    .preview-media__sup-icon {
      position: absolute;
      cursor: pointer;
      top: -36px;
      left: calc(50% - 12px);
      transition: transform 0.3s ease;

      &.locked-media-icon {
        transform: translateY(48px);
        z-index: 10;
      }
    }

    .preview-media-container {
      height: 48px;
      width: 48px;
      border-radius: var(--radius-mi);
      cursor: pointer;
      &.locked-media {
        position: relative;
        &::after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 8px;
          backdrop-filter: blur(4px);
          pointer-events: none;
        }
      }
      &.pink-coating {
        position: relative;
        &::after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          background-color: var(--brand-primary);
          pointer-events: none;
          opacity: 0.3;
          height: 100%;
          width: 100%;
          border-radius: 8px;
        }
      }
      .preview-media__media {
        height: 48px;
        width: 48px;
        object-fit: cover;
        border-radius: 8px;
      }
    }
  }

  .preview-media__plus-button {
    border: none;
    height: 48px;
    width: 48px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    // margin-right: 24px;
    z-index: 1;
  }

  @media only screen and (min-width: 48rem) {
    width: 100%;
    padding: 24px;

    &.gradient-background {
      border-radius: 0 0 0 16px;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 165px;
        bottom: 0;
        left: 0;
        z-index: 1;
        background: linear-gradient(
          to top,
          var(--bg-tertiary) 8%,
          transparent 64%
        );
        pointer-events: none;
      }
    }
  }
}
