@import "../../scss/functions.scss";
$header-height: to-rem(72);

.personal-information {
  height: calc(100dvh - #{$header-height});
  width: 100%;
  background-color: var(--bg-primary);

  &.personal-information-wrapper {
    margin: 0;

    @media screen and (min-width: 768px) {
      border-radius: var(--radius-mi);
      background-color: var(--bg-secondary);
      max-width: to-rem(500);
    }
  }

  .personal-information-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: to-rem(24);

    @media screen and (min-width: 768px) {
      padding: var(--standard-spacing);
    }
  }

  .personal-information__title {
    margin: 0;
    margin-bottom: to-rem(16);
    font-size: to-rem(24);
  }

  .personal-information__information {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }

  .personal-information__bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: to-rem(16);
    flex-grow: 1;
  }

  .personal-information__icon-disclaimer {
    align-items: center;
    padding: 0;
    gap: to-rem(8);
  }

  .personal-information__icon {
    margin: 0;
    width: to-rem(24);
    height: to-rem(24);
    padding: to-rem(2, 3);
  }

  .personal-information__go-to {
    text-decoration: underline;
    cursor: pointer;
  }
}

.personal-information__modal {
  height: calc(100dvh - #{$header-height});
  overflow: scroll;
  position: relative;

  @media screen and (min-width: 768px) {
    border-radius: var(--radius-mi);
    background-color: var(--bg-secondary);
    max-width: to-rem(500);
  }
}
