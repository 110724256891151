@import "../../../../scss/functions.scss";
$spacing: to-rem(48);

#terms-and-policies-modal {
  position: relative;
  border-radius: var(--radius-mi);
  background-color: var(--dawn);
  overflow-y: scroll;

  .close-modal-icon {
    position: absolute;
    top: to-rem(8);
    right: to-rem(8);
    cursor: pointer;
    padding: to-rem(16);
    transition: all 0.3s ease-in-out;
    border-radius: 100%;
    &:hover {
      backdrop-filter: blur(5px);
    }
  }

  .terms-and-policies-modal__content {
    display: grid;

    .content__carousel-wrapper {
      display: none;
      max-width: to-rem(390);
    }

    .content__form-wrapper {
      display: flex;
      flex-direction: column;
      padding: to-rem(40);
      gap: to-rem(16);
      justify-self: center;
      max-height: to-rem(650);
      .terms-wrapper,
      .privacy-wrapper {
        margin-top: 0;
        .terms-wrapper-inner,
        .privacy-wrapper-inner {
          &.inner {
            padding: 0;
          }
        }
      }

      .form-wrapper__inputs {
        display: grid;
        gap: to-rem(16);

        .form-wrapper__link-privacy-policy {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .content__buttons-container {
      display: flex;
      flex-direction: column;
      gap: to-rem(16);
    }
  }

  @media only screen and (min-width: 48rem) {
    .terms-and-policies-modal__content {
      grid-template-columns:
        minmax(to-rem(200), to-rem(390))
        minmax(to-rem(300), to-rem(422));

      .content__form-wrapper {
        height: calc(100dvh - #{$spacing});
        overflow-y: scroll;
      }

      .content__carousel-wrapper {
        display: block;
      }
    }
  }
}
