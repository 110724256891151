#range-default {
  //margin-top: 50px;

  input[type="range"] {
    border: 1px solid var(--pink-hot);
    border-radius: 10px;
    width: 100%;
    height: 12px;
    background-color: #ff007a35;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }

  input::-webkit-slider-thumb {
    border-radius: 15px;
    width: 12px;
    height: 12px;
    -webkit-appearance: none;
    appearance: none;
    background: var(--pink-light);
    cursor: pointer;
  }

  input::-moz-range-thumb {
    border-radius: 15px;
    width: 25px;
    height: 25px;
    background: var(--pink-light);
    cursor: pointer;
  }
}
