@import "../../scss/functions.scss";
$header-height: to-rem(72);

.add-referrals {
  height: calc(100dvh - #{$header-height});
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary);
  width: 100%;
  // display: grid;
  // grid-template-rows: auto 1fr 1fr;

  &.add-referrals-wrapper {
    margin: 0;

    @media screen and (min-width: 768px) {
      border-radius: var(--radius-mi);
      background-color: var(--bg-secondary);
      max-width: to-rem(500);
    }
  }

  .add-referrals-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: to-rem(24);

    @media screen and (min-width: 768px) {
      padding: var(--standard-spacing);
    }
  }

  .add-referrals__code-text {
    color: var(--white);
    text-transform: uppercase;
  }

  .add-referrals__title {
    margin: 0;
    margin-bottom: to-rem(16);
    font-size: to-rem(24);
  }

  .add-referrals__main {
    flex-grow: 1;
  }

  .add-referrals__buttons-container {
    width: 100%;
    display: grid;
    align-self: flex-end;
    gap: 1rem;
  }
}
