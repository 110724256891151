@import "../../../../scss/functions.scss";
$spacing: to-rem(48);

#reset-password-modal {
  position: relative;
  border-radius: var(--radius-mi);
  background-color: var(--dawn);
  overflow-y: scroll;

  .close-modal-icon {
    position: absolute;
    top: to-rem(8);
    right: to-rem(8);
    cursor: pointer;
    padding: to-rem(16);
    transition: all 0.3s ease-in-out;
    border-radius: 100%;
    &:hover {
      backdrop-filter: blur(5px);
    }
  }

  .reset-password-modal__content {
    display: grid;

    .content__carousel-wrapper {
      display: none;
      max-width: to-rem(390);
    }

    .content__form-wrapper {
      height: calc(100dvh - #{$spacing});
      display: flex;
      flex-direction: column;
      padding: to-rem(40);
      gap: to-rem(16);
      overflow-y: scroll;
      max-height: to-rem(650);
      scroll-behavior: smooth;

      .reset-password__subtext {
        padding: 0;
      }

      .form-wrapper__inputs {
        display: grid;
        gap: to-rem(16);

        .inputs__inputs-container {
          margin-bottom: to-rem(16);

          .inputs-container__passwords-container {
            display: flex;
            flex-direction: column;
            gap: to-rem(16);
          }
        }
      }

      .reset-password__icon {
        margin: 0;
        width: to-rem(24);
        height: to-rem(24);
        padding: to-rem(6, 3);
      }
    }
  }

  .reset-password-modal-wrapper__buttons-container {
    display: flex;
    flex-direction: column;
    gap: to-rem(8);
  }

  @media only screen and (min-width: 48rem) {
    .reset-password-modal__content {
      grid-template-columns:
        minmax(to-rem(200), to-rem(390))
        minmax(to-rem(300), to-rem(422));

      .content__form-wrapper {
        height: calc(100dvh - #{$spacing});
        overflow-y: scroll;
      }

      .content__carousel-wrapper {
        display: block;
      }
    }
  }
}
