@import "../../../scss/functions.scss";

.button-close-modal {
  position: absolute;
  z-index: 12;
  top: to-rem(8);
  right: to-rem(8);
  border-radius: 100%;
  padding: to-rem(16);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    backdrop-filter: blur(to-rem(5));
  }
}
