@import "../../scss/functions.scss";

#image-circle-border {
  position: relative;
  z-index: 9;
  margin: to-rem(1);
  border: to-rem(1) solid var(--border-primary);
  border-radius: 100%;
  background-color: transparent;
  // background-color: var(--border-primary);

  &.cursor-pointer {
    cursor: pointer;
  }

  &.pink-border {
    background-color: var(--brand-primary);
  }

  &.charcoal-border {
    background-color: var(--charcoal);
  }

  &.no-border {
    background-color: transparent;
  }

  &.no-avatar {
    margin: 0;
    background-color: var(--bg-primary);
    padding: to-rem(8);
  }

  .image-circle-border__center-image {
    border-radius: 100%;
    width: 100%;
    height: 100%;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    box-sizing: content-box;
    object-fit: cover;
    object-position: center;
  }

  &.image-circle__no-img {
    border: to-rem(1) solid var(--charcoal);
    width: 36px;
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
    cursor: pointer;
  }
}
