.add-media-page__page-title {
  top: 16px;

  @media only screen and (min-width: 48rem) {
  }
}

.add-media-page__inner {
  padding: 16px;
  height: calc(100dvh - 64px);

  @media only screen and (min-width: 48rem) {
    padding: 0;
    height: initial;
  }
}
