.loading-page {
  width: 100%;
  height: 100%;

  .loading-page-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .loading-page-wrapper__text {
      margin-top: 0;
      color: var(--pink-hot);
      font-size: 12px;
    }
  }
}
