#map-route-component {
  position: sticky;
  overflow-y: hidden;

  #map {
    height: 100%;
    max-height: 460px;
    //width: 100%;

    height: 20rem;

    background: var(--charcoal);
  }
}

.leaflet-tooltip {
  top: -6px;
  left: 1px;
  border: none;
  border-radius: 4px;
  background-color: var(--orange);
  padding: 8px;

  .tooltip-map-route {
    width: 54px;
    padding: 0;
    // height: 31px;

    p {
      margin: 0;
      color: var(--white);
      font-weight: 700;
      font-size: 12px;
      // padding: 8px 0;
      text-align: center;
    }
  }

  &::before {
    border-top-color: var(--orange);
    pointer-events: none;
  }
}

.leaflet-popup {
  left: -100px !important;
  width: 100%;
  min-width: 200px;

  .leaflet-popup-content-wrapper {
    border: none;
    border-radius: 4px;
    background-color: var(--orange);

    .leaflet-popup-content {
      width: auto !important;

      p {
        margin: 0;
        color: var(--white);
        font-weight: 700;
        font-size: 12px;
        text-align: left;
      }
    }
  }

  .leaflet-popup-tip {
    background-color: var(--orange);
  }
}

.leaflet-popup-close-button {
  display: none;
}

.pop-up-map-route {
  width: 100%;
  display: flex;
  gap: 16px;

  .pop-up-map-route__column {
    .column__spheres-container {
      height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      margin: 0;
      color: var(--white);
      font-weight: 700;
      font-size: 12px;
      // padding: 8px 0;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 780px) {
  #map-route-component {
    position: sticky;
    overflow-y: hidden;
  }
}
