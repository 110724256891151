#nav-bar-landing-page {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 40px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  transition: background-color 0.3s ease;

  &.scrolled {
    border-radius: 0 0 16px 16px;
    background: var(--dawn);
  }

  .trigger {
    display: none;
  }

  .logo-container {
    display: flex;
    align-items: center;
    gap: 8px;

    &:hover {
      cursor: pointer;
    }

    img {
      width: 32px;
      height: 32px;
    }

    h1 {
      font-family: "Roboto Bold";
      font-size: 24px;
      margin: 0;

      sup {
        font-size: 12px;
      }
    }
  }

  .button-container-nav-bar {
    display: flex;
    align-items: center;
    gap: 16px;

    #language-code {
      margin: 0;
    }

    ul.nav {
      display: flex;
      list-style: none;
      gap: 0px;
      transition: transform 0.3s ease-in-out;
      padding: 0;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      a {
        width: 100%;
        display: block;
        padding: 12px 24px;
        color: var(--white);
        font-family: "Montserrat Variable";
        font-weight: 600;
        font-size: 10px;
        letter-spacing: 1px;
        text-decoration: none;
        text-transform: uppercase;
        text-align: center;
        white-space: nowrap;

        &:hover {
          color: var(--pink-hot);
        }
      }
    }

    &.open {
      ul.nav {
        transform: translateX(0);
      }
    }
  }

  @media only screen and (max-width: 50em) {
    // padding: 0 40px;
    padding: 16px 40px;

    &.open {
      .trigger {
        &:before {
          top: 50%;
          left: 25%;
          transform: rotate(45deg);
        }

        &:after {
          top: 50%;
          left: 25%;
          transform: rotate(-45deg);
        }
      }

      .button-container-nav-bar {
        position: absolute;
        top: 0;
        right: 0;
        width: 320px;
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 80px;
        gap: 16px;
        background: var(--dawn);
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;

        #button {
          margin: 0 auto;
          max-width: calc(100% - 48px);
        }
      }

      .button-container-nav-bar ul.nav {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 16px;
        transition: transform 0.3s ease-in-out;
        transform: translateX(0);
      }
    }

    .trigger {
      position: relative;
      z-index: 10;
      width: 40px;
      height: 40px;
      display: inline-block;

      &:hover {
        cursor: pointer;
      }
      &:before,
      &:after {
        position: absolute;
        width: 16px;
        height: 2px;
        content: "";
        transform: translate(-50%, -50%);
        background: var(--white);
        transition: ease 0.3s;
      }

      &:before {
        top: 16px;
        left: 50%;
      }

      &:after {
        top: 24px;
        left: 50%;
      }
    }

    .button-container-nav-bar {
      display: none;
    }
  }

  @media only screen and (min-width: 80em) {
    padding: 16px 80px;
  }
}
