#log-in-sign-up-desk {
  height: 100dvh;
  overflow: hidden;
  overscroll-behavior: none;

  .log-in-desk-type {
    // height: 100%;

    .image-login-wrapper {
      display: none;
    }

    .account-login-sign-up-page-wrapper {
      width: 100%;
      height: 100dvh;
      display: grid;
      align-items: center;
      overflow-y: auto;
      justify-items: center;
      overflow-x: hidden;
      overscroll-behavior: none;
    }
  }

  @media only screen and (min-width: 48rem) {
    .log-in-desk-type {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .image-login-wrapper {
        height: 100%;
        display: block;

        #fade-carousel-images {
          width: 100%;
          height: 100vh;
          // object-fit: cover;
        }
      }

      .account-login-sign-up-page-wrapper {
        .account-login-page {
        }
      }
    }
  }

  @media only screen and (min-width: 64em) {
  }

  @media only screen and (min-width: 80em) {
  }
}
