.creator-dashboard {
  grid-row: 1;
  &.create-dashboard-wrapper,
  .create-dashboard-inner {
    margin: 0;
    padding: 0;
  }

  .creator-dashboard-moments {
  }
}
