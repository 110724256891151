// .header-home.show-header {
//   z-index: 13;
// }

.add-media-page {
  width: 100%;
  background-color: var(--bg-secondary);
  max-width: 970px;
  height: 100%;

  .add-media-page__navbar {
    display: none;
  }

  .media-storage-wrapper {
    position: relative;
    height: 100%;

    .continue-button {
      backdrop-filter: blur(5px);
      cursor: pointer;
      height: 48px;
      width: 48px;
      border-radius: 100%;
      background-color: var(--brand-primary);
      position: absolute;
      bottom: 24px;
      right: 24px;
      z-index: 3;
      border: none;
    }

    .image-container__pixel-shield-button {
      position: absolute;
      z-index: 1;
      top: 32px;
      left: 24px;
      border: none;
      border-radius: 100%;
      width: 48px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--bg-primary-25);
      cursor: pointer;
    }

    .shield-pills {
      position: absolute;
      z-index: 1;
      top: 96px;
      left: 24px;
    }
  }

  @media only screen and (min-width: 48rem) {
    margin: 24px;
    border-radius: var(--radius-mi);
    display: grid;

    grid-template-columns: 1fr 310px;
    grid-template-rows: 56px auto;
    height: calc(100dvh - 48px);

    .add-media-page__navbar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      grid-column: 1/3;
      border-bottom: 1px solid var(--gray-dark);

      p {
        color: var(--white);
        font-weight: 400;
        font-size: 14px;
      }

      .image-container__close-icon {
        cursor: pointer;
      }
    }
  }
}
