.opened-camera-component {
  width: 100%;
  height: 100%;

  .opened-camera-component__video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .opened-camera-component__button-container {
    position: absolute;
    bottom: 24px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .button-container__timer {
      position: absolute;
      letter-spacing: var(--letter-spacing);
      bottom: 64px;
      margin: 0;
    }

    .button-container__button-wrapper {
      border: 2px solid var(--white);
      border-radius: 100%;
      width: 56px;
      height: 56px;
      padding: 2px;
      transition: all 0.2s ease-in-out;

      &.video-recording {
        width: 56px;
        height: 56px;
      }
    }

    .button-container__recording-button {
      margin: 0 auto;
      border: 1px solid transparent;
      border-radius: 100%;
      width: 100%;
      height: 100%;
      background-color: var(--white);
      outline: none;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &.video-recording {
        width: 100%;
        height: 100%;
        background-color: red;
      }
    }
  }

  .button-container__recording-button {
    margin: 0 auto;
    border: 1px solid transparent;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    outline: none;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &.video-recording {
      width: 100%;
      height: 100%;
      background-color: red;
    }
  }

  .opened-camera-component__multi-images {
    position: absolute;
    bottom: 24px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #33333333;
    cursor: pointer;
  }

  .opened-camera-component__gallery-images {
    left: 24px;
  }

  .opened-camera-component__multi-images {
    right: 24px;
  }

  @media only screen and (min-width: 48rem) {
    .opened-camera-component__video {
      border-radius: 0 0 0 24px;
    }
  }
}
