#preview-captured-media {
  position: relative;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  object-fit: cover;
  height: 100%;
  background-color: var(--bg-primary);

  &::before {
    pointer-events: none;
    content: "";
    width: 100dvw;
    height: 100px;
    position: absolute;
    bottom: 100px;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.6) 0%,
      transparent 100%
    );
  }

  .media-container__video-captured,
  [alt="media captured"] {
    border-radius: 0px 0px 0px 24px;
  }

  [alt="media captured"] {
    height: calc(100dvh - 100px);
    border-radius: 0 0 24px 24px;
    width: 100%;
    // height: 100%;
    object-fit: cover;
    object-position: top;
  }

  .media-container__video-captured {
    border-radius: 24px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    .video-captured__video {
      position: relative;
      border-radius: 0px 0px 0px 24px;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .play_icon {
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 50%);
      cursor: pointer;
      opacity: 0.4;
    }
  }

  .media-container__footer {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 16px 40px 16px;
    gap: 8px;

    .footer__arrow-button {
      background-color: var(--brand-primary);
      border-radius: 100%;
      width: 48px;
      min-width: 48px;
      height: 48px;
      cursor: pointer;
    }
  }
  @media only screen and (min-width: 48rem) {
    width: 100%;
    height: 100%;
    object-fit: cover;
    grid-template-rows: none;

    &::before {
      width: 100%;
      bottom: 0px;
    }

    [alt="media captured"] {
      border-radius: 0 0 0px 24px;
      width: 100%;
      height: calc(100dvh - 106px);

      object-fit: cover;
    }
  }
}
