@import "../../scss/functions.scss";
$header-height: to-rem(72);

.legal-residence {
  height: calc(100dvh - #{$header-height});
  background-color: var(--bg-primary);

  &.legal-residence-wrapper {
    margin: 0;

    @media screen and (min-width: 768px) {
      border-radius: var(--radius-mi);
      background-color: var(--bg-secondary);
      max-width: to-rem(500);
    }
  }

  .legal-residence-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: to-rem(24);

    @media screen and (min-width: 768px) {
      padding: var(--standard-spacing);
    }
  }

  .legal-residence__title {
    margin: 0;
    margin-bottom: to-rem(16);
    font-size: to-rem(24);
  }

  .legal-residence__information {
    display: flex;
    flex-wrap: wrap;
    gap: to-rem(16);
  }

  .legal-residence__country-container {
    margin-bottom: to-rem(8);
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: to-rem(8);
  }

  .legal-residence__row {
    display: flex;
    flex: 1;
    gap: to-rem(16);
  }

  .legal-residence__bottom {
    align-content: end;
    flex-grow: 1;
  }

  .legal-residence__icon-disclaimer {
    align-items: center;
    padding: 0;
    gap: to-rem(8);
  }

  .legal-residence__icon-disclaimer--alt {
    align-items: start;
  }

  .legal-residence__icon {
    margin: 0;
    width: to-rem(24);
    height: to-rem(24);
    padding: to-rem(2, 3);
  }

  .legal-residence__highlight {
    color: var(--pink-light);
    cursor: pointer;
  }

  .legal-residence__dropdown {
    flex: 1;
  }
}
