#slide-carousel {
  width: 100%;
  height: 100%;
  // position: relative;
  overflow: hidden;
  transition: all 0.4s ease-in-out;

  .carousel-button {
    position: absolute;
    z-index: 2;
    top: 50%;
    border: none;
    color: white;
    font-size: 24px;
    transform: translateY(-50%);
    background: transparent;
    cursor: pointer;

    &.prev {
      left: 24px;

      [alt="arrow left"] {
        width: 18px;
      }
    }

    &.next {
      right: 24px;

      [alt="arrow right"] {
        width: 18px;
      }
    }
  }

  #slide-carousel-images {
    width: 100%;
    height: 100%;
    // position: relative;
    overflow: hidden;

    .main-slide-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;

      .slide-carousel-slide {
        width: 100%;
        height: 100%;
        // position: absolute;
        object-fit: cover;
        opacity: 0;
        transition: opacity 1s ease-in-out;

        &.active {
          z-index: 1;
          opacity: 1;
        }
      }

      .text-container {
        position: absolute;
        z-index: 10;
        bottom: 150px;
        left: 24px;
        width: calc(100% - 48px);
        // height: 100%;
        opacity: 0;
        transition: opacity 2s ease-in-out;

        &.margin-bottom-for-bottoms {
          bottom: 180px;
        }

        p {
          color: var(--gray-light);
          font-weight: 400;
          font-size: 14px;
          text-align: left;
        }

        &.active {
          z-index: 1;
          opacity: 1;
          z-index: 10;
        }

        [alt="Icon"] {
          width: 32px;
          height: 32px;
        }
      }

      &::before {
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;
        content: "";
        background: linear-gradient(
          to top,
          rgb(0, 0, 0) 0%,
          rgb(0, 0, 0) 0%,
          rgba(0, 0, 0, 0.05) 30%
        );
        pointer-events: none;
      }
    }

    .footer-dots-button {
      position: absolute;
      z-index: 10;
      bottom: 24px;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      justify-items: center;
      gap: 16px;

      .pagination-dots-wrapper {
        display: flex;
        justify-content: center;

        .pagination-dot {
          margin: 24px 4px;
          border: 6px solid;
          border-radius: 8px;
          width: 8px;
          height: 8px;
          transition: all 0.4s ease-in-out;

          &.current-dot {
            margin: 24px 4px;
            width: 32px;
          }
        }
      }

      .container-button-carousel {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }
  }
}
