@import "../../../scss/functions.scss";

.list-item-extract {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 0;
  color: var(--gray-dark);
  border-bottom: to-rem(1) solid var(--gray-dark);
  gap: to-rem(16);

  &:hover {
    cursor: pointer;
  }

  &:last-of-type {
    border-bottom: none;
  }

  &__avatar {
    margin-right: to-rem(24);
  }

  &__top {
  }

  &__title {
    margin: 0;
    color: var(--white);
    font-size: 0.875rem;
  }

  &__date {
    color: var(--gray-light);
    font-size: 0.75rem;
  }

  &__infos {
    display: flex;
    justify-content: end;
    align-items: center;
    flex: 1;
  }

  &__info {
    margin-left: 1rem;
    color: var(--gray-light);
    font-size: 0.875rem;

    &--amount {
      color: var(--gray-light);
    }

    &--positive {
      color: var(--green);
    }

    &--extract {
      min-width: 5rem;
      text-align: end;
    }
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
  }
}
