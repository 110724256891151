.user-settings-my-subscriptions {
  &::before {
    content: "";
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100px;
    top: 0;
    left: 0;
    z-index: 9;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 76) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &.user-settings-my-subscriptions-wrapper {
    margin: 0;
  }
  .user-settings-my-subscriptions-inner {
    padding: 0;
  }

  .user-settings-my-subscriptions_list {
    display: flex;
    flex-direction: column;
    gap: var(--inner-gap);
    // padding: 16px 8px 8px 8px;

    .user-settings-my-subscriptions_list-active {
    }

    .user-settings-my-subscriptions_list-header {
      background-color: var(--bg-secondary);
      border-radius: 8px;
      padding: 8px;

      p {
        color: var(--white);
        font-size: 0.75rem;
        margin: 0;
      }
    }
  }

  // @media only screen and (min-width: 48rem) {
  //   .user-settings-my-subscriptions_list {
  //     padding: 16px;
  //   }
  // }
}
