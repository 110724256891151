@import "../../../scss/functions.scss";

.header-home {
  position: absolute;
  z-index: 11;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: var(--bg-primary);
  padding: to-rem(16);
  gap: to-rem(8);

  .header-home__logo {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    gap: 4px;
  }

  .header-home__icon {
    width: to-rem(28);
    padding: to-rem(4);
  }

  .header-home__version {
    border-radius: to-rem(4);
    padding: to-rem(4, 6);
    color: var(--white);
    font-weight: bold;
    font-size: to-rem(8);
    text-transform: uppercase;
    letter-spacing: to-rem(0.5);
    background: var(--pink-light);
  }

  .header-home__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--inner-gap);
    flex: 1 1 100%;
    order: 1;
  }
  .header-home__menu-start,
  .header-home__menu-end {
    display: flex;
    align-items: center;
    gap: var(--inner-gap);
  }

  .header-home__btn {
    width: to-rem(24);
    height: to-rem(24);
    background-color: transparent;
  }

  .header-home__btn--active {
    &::before {
      color: var(--pink-light);
    }
  }

  .header-home__search-icon {
    &::before {
      color: var(--text-secondary);
    }
  }

  .header-home__login {
    padding: to-rem(4, 4, 4, 16);
    gap: 1rem;
  }

  .header-home__plan {
    color: var(--text-secondary);
    font-size: to-rem(14);
    cursor: pointer;
  }

  .header-home__plan-icon {
    font-size: to-rem(24);
    vertical-align: middle;

    &::before {
      color: var(--text-secondary);
      pointer-events: none;
    }
  }

  .header-home__separator {
    color: var(--border-primary);
  }

  .header-home__actions {
    padding: to-rem(4, 4, 4, 16);
    gap: to-rem(16);
  }
  .header-home__login,
  .header-home__actions {
    border-radius: to-rem(24);
    display: flex;
    align-items: center;
    background-color: var(--bg-tertiary);
  }

  .header-home__coins {
    display: flex;
    align-items: center;
    gap: to-rem(8);
  }

  .header-home__coin {
    margin: 0;
    display: flex;
    align-items: center;
    color: var(--white);
    font-family: "Roboto Regular";
    font-weight: 400;
    font-size: to-rem(14);
    gap: to-rem(4);
  }

  .header-home__coin--premium {
    color: var(--gold-light);
  }

  .header-home__notify {
    position: relative;
  }

  .header-home__notify__sphere-icon {
    position: absolute;
    top: to-rem(-2);
    right: to-rem(-2);
    border: to-rem(2) solid var(--black);
    border-radius: 50%;
    width: to-rem(12);
    height: to-rem(12);
    background-color: var(--brand-primary);
  }

  .header-home__my-plan {
    max-width: to-rem(800);
  }

  @media only screen and (min-width: 48rem) {
    position: relative;
    padding: to-rem(16, 0);
    gap: to-rem(24);

    .header-home__menu {
      flex: 1;
      order: unset;
      max-width: to-rem(522);
    }

    .header-home__menu-start {
      display: flex;
      align-items: center;
      gap: var(--inner-gap);
    }

    .header-home__search {
      max-width: to-rem(600);
    }
  }
}
