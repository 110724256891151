@import "../../scss/functions.scss";
$header-height: to-rem(72);

.user-information {
  height: calc(100dvh - #{$header-height});
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary);
  width: 100%;
  // display: grid;
  // grid-template-rows: auto auto 1fr;

  &.user-information-wrapper {
    margin: 0;

    @media screen and (min-width: 768px) {
      border-radius: var(--radius-mi);
      background-color: var(--bg-secondary);
      max-width: to-rem(500);
    }
  }

  .user-information-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: to-rem(24);

    @media screen and (min-width: 768px) {
      padding: var(--standard-spacing);
    }
  }

  .user-information__title {
    margin: 0;
    margin-bottom: to-rem(16);
    font-size: to-rem(24);
  }

  .user-information__main {
    flex-grow: 1;

    .node-item-right-text {
      color: var(--gray-light);
      font-size: to-rem(14);
    }
  }

  .user-information__buttons-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
    align-self: flex-end;
  }

  .user-information__item {
    &.list-item-link.column-for-node {
      grid-template-columns: auto auto;
    }
    padding-right: 0;
    padding-left: 0;
  }
}
