#desk-wrapper {
  overflow: hidden;
  margin: 0 var(--standard-spacing);

  // height: calc(100dvh - 8px);
  .hidden-component {
    display: none;
  }

  .hide-catalog-for-gallery {
    display: none;
  }

  #producer-interaction {
    .page-title-container {
      @media only screen and (min-width: 48rem) {
        position: sticky;
        top: 0;
      }
    }
  }

  .main-page-desk {
    width: 100%;
    display: grid;
    overflow: hidden;
    // grid-template-columns: 1fr 1fr;

    .catalog-main {
      position: relative;

      &.hide-catalog-for-feed {
        display: none;
      }

      .discover-nav-bar {
        position: sticky;
        z-index: 4;
        top: 0px;
        right: 0;
        left: 0;
        display: grid;
        background-color: var(--bg-primary);
      }
    }

    .hide-catalog {
      display: none;
    }

    .only-secondary-main-is-show {
      display: none;
    }

    .secondary-hide-catalog {
      display: none;
    }

    .show-catalog {
      display: block;
      overflow-x: hidden;
      overflow-y: hidden;

      // @media only screen and (min-width: 48rem) {
      //     padding-top: 140px;
      // }
    }

    .feed-main {
      display: none;

      &.feed-main-show {
        display: grid;
        grid-column: 1/5;
      }

      &.feed-principal-main-show {
        display: grid;
      }

      &.feed-secondary-main-show {
        display: none;
      }

      &.feed-principal-secondary-main-show {
        display: none;
      }
    }
    .feed-main-principal-main-show .principal-main,
    .secondary-main {
      width: 100%;
      display: none;
      opacity: 0;
      overflow: hidden;
      animation: all 0.3s ease;
    }

    .principal-main.show-principal-main {
      width: 100%;
      display: grid;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;

      &.hide-principal-main-mobile {
        display: none;
      }

      &.show-principal-and-secondary-main {
        display: none;
      }
    }

    .principal-feed-show {
      display: none;
    }

    .principal-feed-secondary-show {
      display: none;
    }

    .principal-main.hide-principal-main {
      // animation-name: slideOut;
      animation-duration: 0.3s;
      opacity: 1;
      transform: translateX(0);
      visibility: visible;
    }

    .secondary-main.show-secondary-main {
      display: grid;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      overflow: auto;
      grid-row: 1;

      &.show-secondary-and-tertiary-main {
        display: none;
      }
    }
    //xxx
    // .principal-feed-show.principal-main-feed {
    //   display: grid;
    //   grid-column: 4/5;
    // }

    .secondary-main {
      &.secondary-feed-principal-show {
        display: grid;
        opacity: 1;
      }

      &.secondary-feed-main-show {
        display: grid;
        opacity: 1;
        grid-row: 1;
      }
    }

    .secondary-main.hide-secondary-main {
      display: none;
      // animation-name: slideOut;
      opacity: 0;
      transform: translateX(100%);
      transition: opacity 0.3s ease-in-out;
      visibility: hidden;
      pointer-events: none;
    }

    .tertiary-main.show-tertiary-main {
      display: grid;
      // animation-name: slideIn;
      // animation-duration: 0.3s;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      overflow: auto;
    }

    .hide-tertiary-main-for-feed {
      display: none;
    }

    .tertiary-main.hide-tertiary-main {
      display: none;
      // animation-name: slideOut;
      opacity: 0;
      // animation-duration: 0.3s;
      transform: translateX(100%);
      transition: opacity 0.3s ease-in-out;
      visibility: hidden;
      pointer-events: none;
    }

    .hide-principal-main-mobile {
      display: none;
      // animation-name: slideOut;
    }

    // @keyframes slideIn {
    //   from {
    //     transform: translateX(100%);
    //     opacity: 0;
    //   }

    //   to {
    //     transform: translateX(0%);
    //     opacity: 1;
    //   }
    // }

    // @keyframes slideOut {
    //   from {
    //     transform: translateX(0);
    //     opacity: 1;
    //   }

    //   to {
    //     transform: translateX(100%);
    //     opacity: 0;
    //   }
    // }
  }

  @media only screen and (min-width: 48rem) {
    height: 100dvh;
    .main-page-desk {
      grid-template-columns: 1fr 1fr;
      column-gap: var(--inner-gap);
    }
    .principal-main.show-principal-main,
    .secondary-main.show-secondary-main,
    .tertiary-main.show-tertiary-main {
      // animation-name: slideIn;
      animation-duration: 0.3s;
      transform: translateX(0);
    }

    .principal-feed-show.principal-main-feed {
      display: grid;
      grid-column: 4/5;
    }

    .hidden-component {
      display: grid;
    }

    .feed-main {
      &.feed-main-show {
        grid-column: 1/3;
      }

      &.feed-principal-main-show {
        display: grid;
        grid-column: 2/5;
      }

      &.feed-principal-secondary-main-show {
        display: grid;
        visibility: visible;
        grid-column: 2/3;
      }
    }

    .main-page-desk .principal-main.show-principal-main {
      // height: calc(100vh - 80px);
    }

    .principal-main.show-principal-main {
      width: 390px;
    }

    #producer-interaction {
      .page-title-container {
        position: fixed;
        top: 68px;

        @media only screen and (min-width: 48rem) {
          position: sticky;
          top: 0;
        }
      }
    }

    .main-page-desk {
      // padding: 0;
      // grid-template-columns: auto 390px;
      // row-gap: var(--inner-gap);
      max-width: inherit;

      .catalog-main {
        // min-width: 390px;

        .discover-nav-bar {
          .container_search {
            display: none;
          }
        }
      }

      .secondary-main.show-secondary-main {
        grid-column: 2/3;

        &.show-secondary-and-tertiary-main {
          display: grid;
          grid-column: 1/2;
        }
      }

      .principal-main.show-principal-main {
        &.show-principal-and-secondary-main {
          display: grid;
          grid-column: 1/2;
        }
      }

      .hide-catalog-for-gallery {
        display: none;
      }

      .hide-catalog {
        display: grid;
        grid-column: 1/2;
        // grid-template-rows: 140px auto;
        overflow-y: auto;
      }

      .show-catalog {
        display: block;
        grid-column: 1/3;
      }

      .principal-main {
        grid-column: 2 / 3;

        &.hide-principal-main-mobile {
          display: none;
        }

        &.principal-feed-secondary-show {
          display: none;
          // display: grid;
          // grid-column: 1/2;
          // visibility: visible;
        }
      }

      .principal-main-feed {
        border-left: 0px;
      }

      .principal-feed-show {
        display: grid;
        grid-row: 1;
        grid-column: 1/2;
      }

      .secondary-main {
        &.show-secondary-and-tertiary-main {
          grid-column: 1/2;
        }

        &.secondary-feed-principal-show {
          grid-column: 1/2;
          grid-row: 1;
          min-width: 390px;
        }

        &.secondary-feed-main-show {
          display: grid;
          grid-column: 1/2;
          opacity: 1;
          grid-row: 1;
        }
      }

      .feed-main.feed-secondary-main-show {
        display: grid;
        grid-column: 2/3;
        visibility: visible;
      }

      .tertiary-main {
        grid-column: 2/3;
      }

      .feed-main {
        &.feed-principal-secondary-main-show {
          display: grid;
          visibility: visible;
          grid-column: 2/5;
        }
      }
    }
  }

  @media only screen and (min-width: 64em) {
    .main-page-desk {
      grid-template-columns: 390px auto auto 390px;

      .hide-catalog {
        display: grid;
        align-items: flex-start;
        grid-column: 1/4;
        overflow-y: auto;
        // grid-template-rows: 140px auto;
      }
      .secondary-main.show-secondary-main {
        grid-column: 3/5;
      }

      .only-secondary-main-is-show {
        grid-column: 1/3;
      }

      .show-tertiary-main {
        grid-column: 2/5;
      }

      .show-catalog {
        display: block;
        grid-column: 1 / 5;
      }

      .principal-main {
        grid-column: 4/5;

        &.principal-feed-show {
          grid-column: 1/2;
        }
      }

      .principal-main.show-principal-main {
        &.show-principal-and-secondary-main {
          display: grid;
          grid-column: 1/3;
        }
      }

      .secondary-main {
        grid-column: 2/3;

        &.secondary-feed-main-show {
          display: grid;
          opacity: 1;
          grid-row: 1;
          grid-column: 4/5;
        }
      }

      .feed-main.feed-secondary-main-show {
        display: grid;
        grid-column: 1/4;
        visibility: visible;
      }
    }
  }

  @media only screen and (min-width: 80em) {
    .main-page-desk {
      grid-template-columns: auto 390px 390px 390px;
      // height: calc(100dvh - 64px);
      height: calc(100dvh - 80px);

      .secondary-main {
        grid-column: 4 / 5;
      }

      &.grid-template-changed {
        grid-template-columns:
          390px 390px 390px
          auto;
      }

      &.grid-template-feed {
        grid-template-columns:
          390px auto auto
          390px;
      }

      .hide-catalog {
        display: grid;
        grid-column: 1/4;
        // grid-template-rows: 140px auto;
      }

      .principal-main.principal-feed-secondary-show {
        display: grid;
        grid-column: 1/2;
        grid-row: 1;
      }

      .feed-main {
        grid-column: 1/5;

        &.feed-principal-main-show {
          grid-column: 2/5;
        }

        &.feed-principal-secondary-main-show {
          grid-column: 2/4;
        }

        &.feed-principal-secondary-main-show {
          grid-column: 2/4;
        }
      }

      .secondary-hide-catalog {
        display: grid;
        grid-column: 1/3;
        overflow-y: auto;
      }

      .secondary-main.show-secondary-main {
        grid-column: 4/5;

        &.show-secondary-and-tertiary-main {
          display: grid;
          grid-column: 2/3;
        }
      }

      .show-tertiary-main {
        grid-column: 3/5;

        &.show-all-main {
          grid-column: 2/5;
        }
      }

      .only-secondary-main-is-show {
        grid-column: 1/4;
      }

      .show-catalog {
        display: block;
        grid-column: 1 / 5;
      }

      .principal-main.show-principal-main.hide-principal-main-mobile {
        display: grid;
        grid-column: 4/5;

        &.hide-principal-main-mobile {
          display: grid;
          grid-column: 1/2;
        }
      }

      .principal-main.show-principal-main {
        grid-column: 4/5;

        &.show-principal-and-secondary-main {
          display: grid;
          grid-column: 3/4;
        }
      }

      .secondary-main.secondary-feed-principal-show {
        grid-column: 4/5;
      }
    }
  }
}
