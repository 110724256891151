.all-button-styles {
  margin-bottom: 24px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;

  #button {
    max-width: 200px;
  }
}

.show-all-button-icons {
  height: calc(100dvh - 150px);
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(200px, 205px));
  -webkit-user-select: text;
  user-select: text;
  overflow-y: scroll;
  gap: 8px;

  .show-all-button-icons__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
  }

  .show-all-button-icons__texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    text-align: center;

    button {
      border: none;
      border-radius: var(--radius-mi);
      background-color: var(--charcoal);
      padding: 8px 12px;
      color: var(--white);
      font-size: 14px;
    }
  }
}
