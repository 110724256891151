@import "../../scss/functions.scss";
$header-height: to-rem(72);

.recovery-email {
  height: calc(100dvh - #{$header-height});
  display: flex;
  flex-direction: column;
  .recovery-email-wrapper,
  .recovery-email-inner {
    margin: 0;
    padding: 0;
  }

  .recovery-email__continue-button {
    margin-top: auto;
  }
}
