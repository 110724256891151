@import "../../scss/functions.scss";

.daily-login {
  border-radius: var(--radius-mi);
  display: flex;
  flex-direction: column;
  padding: to-rem(24);
  gap: to-rem(16);
  background: var(--bg-tertiary);

  .daily-login__main {
    padding-bottom: to-rem(16);
    border-bottom: to-rem(1) solid var(--border-primary);
  }

  .daily-login__top {
    margin-bottom: to-rem(16);
    display: flex;
    justify-content: space-between;
  }

  .daily-login__title {
    margin: 0;
    color: var(--text-secondary);
    font-family: "Roboto Regular";
    font-size: to-rem(14);
  }

  .daily-login__text {
    font-size: to-rem(12);
    text-align: center;
    font-style: italic;
  }

  .daily-login__highlight {
    color: var(--text-primary-75);
    font-weight: 600;
    font-size: to-rem(20);
    vertical-align: middle;
  }

  .daily-login__highlight-gold {
    color: var(--color-gold-light);
  }
}
