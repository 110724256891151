#landing-page-carousel {
  position: relative;
  width: 100%;
  height: 100vh;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    touch-action: pan-y;
  }

  .slide-background {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:before {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: radial-gradient(
        circle,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      pointer-events: none;
    }

    &::after {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: radial-gradient(
        circle at -25% -65%,
        rgba(231, 0, 124, 0.5) 0%,
        rgba(231, 0, 124, 0) 50%
      );
      pointer-events: none;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }

    .banner-wrapper-inner {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 1224px;
      text-align: left;

      .banner-text-container {
        position: relative;
        z-index: 3;
        height: auto;
        max-width: 460px;

        .banner-button-wrapper {
          padding-top: 24px 0 0 0;

          #button {
            margin: 0;
            padding: 0;
            font-size: 12px;
          }
        }

        img {
          width: 40px;
          height: 40px;
        }

        // h2 {
        //     color: var(--white);
        //     font-weight: 400;
        //     font-size: 24px;
        // }

        // p {
        //     height: 63px;
        //     color: var(--ash);
        //     font-weight: 400;
        //     font-size: 14px;
        // }
      }
    }
  }

  @media (min-width: 30em) {
    .slide-background {
      .banner-wrapper-inner {
        justify-content: flex-start;

        .text-container {
          .button-wrapper {
            padding: 24px 80px 0 0;
          }
        }
      }
    }

    @media (min-width: 48rem) {
      .slide-background {
        .banner-wrapper-inner {
          .banner-text-container {
            .banner-button-wrapper {
              padding: 24px 80px 0 0;
            }
          }
        }
      }

      @media (min-width: 80em) {
        .slide-background {
          .banner-wrapper-inner {
          }
        }
      }
    }
  }
}
