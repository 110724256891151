@import "../../scss/functions.scss";
$header-height: to-rem(72);

.identity-verification {
  height: calc(100dvh - #{$header-height});

  &.identity-verification-wrapper {
    margin: 0;
  }

  .identity-verification-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .identity-verification__title {
    margin: 0;
    margin-bottom: to-rem(16);
    font-size: to-rem(24);
  }

  .identity-verification__top {
    margin-bottom: to-rem(24);
  }

  .identity-verification__body {
    padding: to-rem(40);
    justify-items: center;
  }

  .identity-verification__body-gap {
    gap: to-rem(16);
  }

  .identity-verification__body-content {
    margin-bottom: to-rem(24);
    text-align: center;
  }

  .identity-verification__body-title {
    margin: 0;
    font-size: to-rem(14);
    text-transform: uppercase;
  }

  .identity-verification__bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: to-rem(8);
    flex-grow: 1;
  }
}
