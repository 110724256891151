@import "../../scss/functions.scss";
$spacing: to-rem(48);

.user-settings-notifications {
  &.user-settings-notifications-wrapper {
    margin: 0;
  }

  .user-settings-notifications-inner {
    padding: 0;
  }

  .list-container {
    gap: 0;
  }

  .container-buttons {
    position: absolute;
    bottom: to-rem(40);

    width: calc(100% - #{$spacing});
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }

  .user-settings-notifications__notifications-container {
    display: flex;
    flex-direction: column;
    // margin: to-rem(24);
    gap: to-rem(16);
  }

  .user-settings-notifications__list-header {
    border-radius: to-rem(8);
    background-color: var(--bg-secondary);
    padding: to-rem(8);
  }

  .user-settings-notifications__list-text {
    margin: 0;
    color: var(--white);
    font-size: 0.75rem;
  }

  .user-settings-notifications__list--highlight {
    color: var(--green);
    font-size: to-rem(14);
  }
}
