@import "../../scss/functions.scss";
$rem-nav-height: to-rem(72);
$rem-nav-height-desktop: to-rem(96);
$gap-to-modal: to-rem(32);

.feed-global {
  margin: 0 auto 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: to-rem(52);
  &.feed-global-wrapper,
  .feed-global-inner {
    margin: 0;
    padding: 0;
  }

  .feed-global-inner {
    padding-top: to-rem(50);
  }

  .feed-global-cards {
    height: calc(100dvh - #{$rem-nav-height});
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
  }

  .feed-global-loading {
    position: relative;
    z-index: 2;
  }

  .feed-global-fallback {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .feed-global-logo {
    width: to-rem(24);
  }

  .feed-global-no-post {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: to-rem(14);
    gap: to-rem(8);
  }

  .feed-global-no-post-support-text {
    color: var(--white);
    font-size: to-rem(18);
  }

  @media screen and (min-width: to-rem(768)) {
    height: calc(100dvh - #{$rem-nav-height-desktop});
    padding-top: 0;

    .feed-global-inner {
      padding-top: 0;
    }

    .feed-global-cards {
      height: calc(100dvh - #{$rem-nav-height-desktop});
      max-width: to-rem(600);
    }
  }
}

.feed-posts-expand-modal {
  max-width: none;
  max-height: none;

  .feed-posts-expand-modal-list {
    width: 100%;
    height: 100vh;
    display: grid;
    scroll-snap-type: y mandatory;
    overflow: scroll;
    place-items: center;

    .feed-posts-expand-modal-list-item {
      width: 100%;
      display: grid;
      scroll-snap-align: center;
      max-width: to-rem(1200);
      place-items: center;
    }
  }

  @media screen and (min-width: to-rem(768)) {
    .feed-posts-expand-modal-list {
      padding-top: 16px;
      padding-bottom: 16px;
    }
  }
}
