@import "../../../scss/functions.scss";

.input-text-default {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: to-rem(4);

  .input-text-default__label {
    display: flex;
    align-items: center;
    font-size: to-rem(12);
    gap: to-rem(4);
  }

  [alt="closed eye"] {
    width: to-rem(18);
    height: to-rem(15);
    cursor: pointer;
  }

  [alt="right icon"] {
    width: to-rem(15);
    height: to-rem(15);
    cursor: pointer;
  }

  [alt="opened eye"] {
    width: to-rem(15);
    height: to-rem(15);
    cursor: pointer;
  }

  .input-container {
    position: relative;
    z-index: 6;

    .input-text {
      border: to-rem(1) solid var(--gray-dark);
      border-radius: to-rem(24);
      width: 100%;
      height: to-rem(48);
      display: grid;
      justify-content: center;
      align-items: center;
      background-color: var(--dawn);
      color: var(--white);
      font-size: to-rem(14);

      &.isWarning {
        outline: to-rem(1) solid var(--peach);
      }

      &::placeholder {
        color: var(--gray);
      }

      &:disabled {
        background-color: var(--bg-secondary);
      }
    }

    .input-text-icon-left {
      position: absolute;
      top: to-rem(14);
      left: to-rem(18);
      width: to-rem(18);
      height: to-rem(18);
      background-color: transparent;

      &.disabled-icon {
        opacity: 0.5;
      }
    }

    .input-text-icon-right {
      position: absolute;
      top: to-rem(16);
      right: to-rem(18.5);
      background-color: transparent;
    }

    .input-text-icon-right-24 {
      position: absolute;
      top: to-rem(14);
      right: to-rem(18.5);
      width: to-rem(20);
      height: to-rem(20);
      background-color: transparent;
    }

    .element-node-wrapper {
      position: absolute;
      top: 50%;
      right: to-rem(50);
      transform: translateY(-50%);
    }
  }

  .input-text__icon-right {
    position: absolute;
    top: 50%;
    right: to-rem(16);
    bottom: 0;
    background-color: transparent;
    font-size: to-rem(24);
    transform: translateY(-50%);
  }

  .input-text__explanation {
    color: var(--gray);
    font-size: to-rem(12);
  }

  .input-text__btn {
    position: absolute;
    z-index: 6;
    top: to-rem(8);
    right: to-rem(8);
    bottom: to-rem(8);
    width: auto;

    height: calc(100% - #{to-rem(16)});
    padding: to-rem(8, 16);
    font-family: "Montserrat Variable";
    font-size: to-rem(8);
  }
}
