@import "../../scss/functions.scss";

.account-login-page {
  max-width: to-rem(390);
  &.account-login-wrapper,
  .account-login-inner {
    margin: 0;
    padding: 0;
  }
  #terms,
  #privacy {
    margin-top: 0;
  }

  .account-login__login-container {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }

  .account-login__oauth-buttons {
    display: flex;
    flex-direction: row;
    gap: to-rem(8);
    cursor: pointer;
  }

  .account-login__buttons-container {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }

  .account-login__link {
    color: var(--pink);
    cursor: pointer;
  }
}
