.action-button-group {
  position: relative;
  width: 100%;
  display: flex;
  overscroll-behavior: none;
  justify-items: stretch;
  overflow-x: auto;
  z-index: 11;
  overflow-y: hidden;

  .action-button-group-container {
    position: relative;
    display: flex;
    width: 100%;
    background-color: var(--bg-inverse-10);
    backdrop-filter: blur(24px);
    border-radius: 24px;
    overflow-x: auto;
    overscroll-behavior: none;
  }

  .action-button {
    border: none;
    width: 100%;
    min-width: 122px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;
    cursor: pointer;
    background-color: transparent;
    transition: background-color 0.3s ease;
    gap: 4px;

    // &.background-midnight {
    //   backdrop-filter: blur(24px);
    //   &.active {
    //     border: none;
    //   }
    // }

    &.first-child {
      border-top-left-radius: 24px;
      border-bottom-left-radius: 24px;
    }

    &.last-child {
      border-top-right-radius: 24px;
      border-bottom-right-radius: 24px;
    }

    &.no-hor-margin {
      &.first-child {
        margin-left: 0;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
      }

      &.last-child {
        margin-right: 0;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
      }
    }

    p {
      color: var(--text-primary-50);
      font-family: "Montserrat Variable";
      font-weight: 600;
      font-size: 10px;
      letter-spacing: var(--letter-spacing);
      text-transform: uppercase;
      margin: 0;

      text-wrap: nowrap;

      &.active {
        color: var(--text-primary-75);
      }

      &.right-text {
        opacity: 0.65;
      }
    }
  }

  .action-button__button-right {
    margin-left: var(--standard-spacing);
    border: none;
    border-radius: 100%;
    width: 100%;
    height: 48px;
    background-color: var(--bg-secondary);
    max-width: 48px;
    min-width: 48px;
    cursor: pointer;

    // &.background-midnight {
    //   background-color: var(--bg-secondary);
    // }

    // &.background-dawn {
    //   background-color: var(--dawn);
    // }

    .button-right__icon {
    }
  }

  .action-button-glider {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    border-radius: 24px;
    display: flex;
    background-color: #ffffff1a;
  }

  @media only screen and (min-width: 48rem) {
    .action-button {
    }
  }
}
