@import "../../scss/functions.scss";

.user-settings-my-subscribers {
  &::before {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: to-rem(100);
    content: "";
    pointer-events: none;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 76) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &.user-settings-my-subscribers-wrapper {
    margin: 0;
  }

  .user-settings-my-subscribers-inner {
    padding: 0;
  }

  .user-settings-my-subscribers_list {
    display: flex;
    flex-direction: column;
    padding-top: var(--standard-spacing);
    gap: to-rem(16);

    .user-settings-my-subscribers_list-active {
    }

    .user-settings-my-subscribers__list-header {
      border-radius: to-rem(8);
      background-color: var(--bg-secondary);
      padding: to-rem(8);

      .user-settings-my-subscribers__list-text {
        margin: 0;
        color: var(--white);
        font-size: 0.75rem;
      }
    }
  }
}
