#input-checkbox-default {
  height: 160px;
  overflow-y: auto;

  width: 100%;
  //   height: 100%;
  display: flex;
  flex-direction: column;
  color: var(--gray);
  text-transform: capitalize;

  .label-input-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;

    .container-checkbox {
      position: relative;
      width: 100%;
      font-family: "Roboto Regular";
      font-weight: 400;
      font-size: 0.875rem;
      cursor: pointer;

      .container-checkbox__checkmark {
        position: absolute;
        right: 0;
        border: 1px solid var(--gray-dark);
        border-radius: 4px;
        width: 24px;
        height: 24px;
        background-color: var(--dawn);

        &::after {
          top: 2px;
          margin: 3px;
          border-radius: 2px;
          width: 16px;
          height: 16px;
          display: none;
          background-color: var(--brand-primary);
          content: "";
          background-image: url("../../../assets/icons/check-simple-gray.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 10px;
        }
      }

      input[type="checkbox"] {
        height: 48px;
        display: none;
        cursor: pointer;
      }

      input:checked ~ .container-checkbox__checkmark:after {
        display: block;
      }
    }

    .separator {
      position: absolute;
      bottom: 0;
      left: 16px;
      width: calc(100% - 32px);
      height: 1px;
      background-color: var(--charcoal);
    }
  }
}
