@import "../../scss/functions.scss";

.progressbar-without {
  width: 100%;
  height: 100%;
  display: flex;

  .progressbar-without__bar {
    border-radius: to-rem(2);
    width: 100%;
    height: to-rem(2);
    background-color: var(--ash);
  }

  .progressbar-without__bar--pink {
    background-color: var(--pink-light);
  }

  .progressbar-without__bar-negative {
    border-radius: to-rem(2);
    width: 100%;
    height: to-rem(2);
    background-color: #dddddd2d;
  }
}
