@import "../../../scss/functions.scss";

.list-item-link {
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  padding: to-rem(16);
  grid-template-columns: 1fr to-rem(24);
  gap: to-rem(16);

  &--big {
    grid-column: 1 / span 2;
  }

  &--compact {
    padding: to-rem(8);
    gap: to-rem(8);
    min-height: to-rem(56);
  }
  &.column-for-image,
  &.column-for-image-and-node-without-arrow {
    grid-template-columns: to-rem(40) 1fr auto;
  }

  &.column-for-image-and-node {
    grid-template-columns: to-rem(40) 1fr auto auto;
  }

  &.column-for-node-and-show-left {
    grid-template-columns: to-rem(40) auto auto 2.5rem;

    &:has(:nth-child(3):last-child) {
      grid-template-columns: to-rem(40) auto 2.5rem;
    }
  }

  &.column-for-node {
    grid-template-columns: auto auto to-rem(40);
    gap: to-rem(8);
  }

  &.cursor-pointer {
    cursor: pointer;
  }

  .item__image-left-container {
    border-radius: 100%;
    width: to-rem(40);
    height: to-rem(40);
    display: flex;
    justify-content: center;
    align-items: center;

    &.image-circle {
      border: to-rem(1) solid var(--charcoal);
      background-color: var(--dawn);

      &.icon-transparent {
        background: transparent;
      }
    }

    .item__left-icon {
      width: to-rem(18);
      height: to-rem(18);

      &.icon-fill {
        border-radius: 100%;
        width: 100%;
        height: 100%;
        padding: to-rem(2);
        object-fit: cover;
        object-position: top;
      }

      &.icon-big {
        width: to-rem(24);
        height: to-rem(24);
      }
    }
  }

  .item__main-container {
  }

  .main-container__highlight-text {
    color: var(--white);
    font-weight: 400;
    font-size: to-rem(14);
  }

  .main-container__simple-text {
    margin: 0;
    font-weight: 400;
    font-size: to-rem(12);
  }

  .list-item-link__left-node {
    justify-self: flex-start;
  }

  .list-item-link__right-node {
    justify-self: flex-end;
  }

  .danger-text {
    color: var(--peach);
  }
  .arrow-right,
  .close-right {
    justify-self: center;
    cursor: pointer;
  }
}
