@import "../../scss/functions.scss";

.user-settings-personal-touch {
  &.user-settings-personal-touch-wrapper {
    margin: 0;
  }

  .user-settings-personal-touch-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .user-settings-personal-touch__main {
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: to-rem(16);
  }

  .user-settings-personal-touch__bars {
    display: flex;
    padding-top: to-rem(8);
    gap: to-rem(8);
  }

  .user-settings-personal-touch__list {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .user-settings-personal-touch__detail {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: to-rem(14);
    gap: to-rem(8);
    text-transform: capitalize;
  }
}
