@import "../../../scss/functions.scss";

.list-item-toggle {
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  padding: to-rem(16) 0;
  grid-template-columns: 1fr auto;
  cursor: pointer;

  &.column-for-image {
    grid-template-columns: to-rem(56) 1fr auto;
  }

  .item__image-left-container {
    border-radius: 100%;
    width: to-rem(40);
    height: to-rem(40);
    display: flex;
    justify-content: center;
    align-items: center;

    &.image-circle {
      border: to-rem(1) solid var(--charcoal);
    }

    .item__left-icon {
      width: to-rem(18);
      height: to-rem(18);

      &.icon-fill {
        border-radius: 100%;
        width: 100%;
        padding: to-rem(2);
        object-fit: cover;
        object-position: top;
      }
    }
  }

  .item__main-container {
    .main-container__highlight-text {
      color: var(--white);
      font-weight: 400;
      font-size: to-rem(14);
      margin: 0;
    }

    .main-container__simple-text {
      font-weight: 400;
      font-size: to-rem(12);
      margin: 0;
    }
  }

  [alt="arrow-right"] {
    width: to-rem(7);
    height: to-rem(10);
  }
}
