@import "../../../scss/functions.scss";

.cards-arrows {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  .cards-arrow-left,
  .cards-arrow-right {
    position: relative;
    z-index: 1;
    height: 100%;
    display: flex;
    align-items: center;
    flex: 1;
    cursor: pointer;

    &.cards-arrow--hidden {
      opacity: 0;
    }

    .cards-arrow-button-icon {
      width: to-rem(32);
      height: to-rem(104);
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--bg-inverse-10);
      backdrop-filter: blur(24px);
    }
  }

  .cards-arrow-left {
    justify-content: flex-start;
    background-color: rgba(255, 255, 255, 0);

    .cards-arrow-button-icon {
      border-radius: 0 to-rem(8) to-rem(8) 0;
    }
  }

  .cards-arrow-right {
    justify-content: flex-end;
    background-color: rgba(255, 255, 255, 0);

    .cards-arrow-button-icon {
      border-radius: to-rem(8) 0 0 to-rem(8);
    }
  }

  .cards-arrow-icon {
    font-size: to-rem(24);

    &::before {
      color: var(--gray-light);
      pointer-events: none;
    }
  }
}
