.plan-card-color-default {
  border-color: var(--charcoal);
  background: var(--charcoal);
  color: var(--gray);
}

.plan-card-color-consumer {
  background-color: var(--brand-primary);
  border-color: var(--pink-light);
}

.plan-card-color-creator {
  background-color: var(--gold);
  border-color: var(--gold);
  color: var(--white);
}

.plan-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-content: center;
  border-radius: var(--radius-mi);
  border-width: 1px;
  border-style: solid;
  color: var(--white);
  align-items: center;

  transition:
    color 0.2s,
    background-color 0.2s,
    border-color 0.2s;

  @media (min-width: 64rem) {
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .plan-card-header {
    border-radius: 0.5rem 0.5rem 0 0;
    font-size: 0.75rem;
    text-align: center;
    padding: 0.25rem 0;
    .plan-card-header-text {
      color: var(--white);
      font-size: 0.75rem;
      margin: 0;
    }
    .plan-card-header-text-consumer {
      color: var(--white);
    }
    .plan-card-header-text-creator {
      color: var(--charcoal);
    }
  }
  .plan-card-header-color-default {
    color: var(--white);
    background-color: var(--charcoal);
  }
  .plan-card-content {
    width: 100%;
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(26, 26, 26, 0.75);
    border-radius: var(--radius-mi);
    gap: 0.5rem;

    .plan-card-content-text {
      font-weight: 700;
      font-size: 1.5rem;
    }
    .plan-card-content-text-consumer {
      font-weight: 700;
      font-size: 1.5rem;
    }
    .plan-card-content-info {
      opacity: 0.75;
      font-size: 0.875rem;
    }
    .plan-card-content-original-price {
      font-size: 0.75rem;
      opacity: 0.5;
      text-decoration: line-through;
    }
    .plan-card-content-discount {
      border-radius: 0.25rem;
      padding: 0.25rem 0.5rem;
      color: var(--white);
      font-weight: 500;
    }

    .plan-card-content-discount-color-consumer {
      background: rgba(238, 78, 135, 0.25);
    }
    .plan-card-content-discount-color-creator {
      background: rgba(255, 179, 107, 0.25);
    }
    .plan-card-content-discount-active {
      background: rgba(0, 0, 0, 0.25);
    }
  }
}
