#horizontal-scroll-component {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  user-select: none;
  display: flex;
  align-items: center;
  scroll-behavior: smooth;

  .horizontal-scroll-component__content {
    display: flex;
  }
}
