@import "../../scss/functions.scss";

.infosPost {
  position: absolute;
  z-index: 6;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
  pointer-events: none;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    border-radius: 0 0 to-rem(16) to-rem(16);
    width: 100%;
    height: 100%;
    pointer-events: none;
    content: "";
    opacity: 1;
    transition: all 0.5s;
    mask-image: linear-gradient(to top, black 0%, #00000000 100%);
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 0.76) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  @media screen and (min-height: 48rem) {
    &.inner {
      padding: to-rem(24);
    }
  }

  .infosPost-blur {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mask-image: linear-gradient(to top, black 60%, #00000000 100%);
    opacity: 0;
    backdrop-filter: blur(0);
    pointer-events: none;
    transition: all 0.5s;
  }

  .infosPost-blur--show {
    opacity: 1;
    backdrop-filter: blur(110px);
    transition: all 0.5s;
    border-radius: var(--radius-mi);
  }

  .infosPost-content {
    position: relative;
    z-index: 5;
    width: 100%;
    font-size: to-rem(14);
    opacity: 0;
    transform: translateY(to-rem(50));
    transition: all 0.5s;
  }

  .infosPost-content--show {
    opacity: 1;
    transform: translateY(to-rem(0));
    transition: all 0.5s;
  }

  .infosPost-content-title {
    font-family: "Roboto Bold";
    margin: 0 0 to-rem(8) 0;
    color: var(--white);
    font-weight: 700;
    font-size: to-rem(14);
  }

  .infosPost-content-texts {
    margin-bottom: to-rem(8);
  }

  .infosPost-content-text {
    margin: 0;
    color: var(--white);
    font-size: to-rem(14);
  }

  .infosPost-content-tag-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    font-size: to-rem(14);
    gap: to-rem(7);
  }

  .infosPost-content-tag {
    color: var(--pink-light);
    cursor: pointer;
  }

  .infosPost-cards-locked {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);

    .infosPost-cards-locked-icon {
      width: to-rem(24);
    }

    .infosPost-cards-locked-price {
      color: var(--white);
      font-weight: 500;
      font-size: to-rem(24);
    }

    .infosPost-cards-locked-text {
      color: var(--white);
      font-size: to-rem(14);
      opacity: 0.75;
    }
  }

  .infosPost-card-options {
    position: relative;

    .infosPost-card-top-btn {
      position: relative;
      z-index: 2;
      border: 0;
      aspect-ratio: 1/1;
      background: transparent;

      &:hover {
        cursor: pointer;
      }
    }

    .infosPost-card-options-icon {
      font-size: to-rem(24);

      &::before {
        color: var(--white);
        pointer-events: none;
      }
    }
  }

  .infosPost-card-btns {
    z-index: 6;
    width: 100%;
    display: flex;
    justify-content: space-between;
    pointer-events: auto;
  }

  .infosPost-card-btns--side {
    display: flex;
    gap: to-rem(8);
  }

  .infosPost-card-btn {
    border-radius: to-rem(24);
    padding: 0 to-rem(16) 0 to-rem(12);
    color: var(--white);
    font-family: "Montserrat Variable";
    font-weight: 600;
    gap: to-rem(8);

    [alt="verify icon"] {
      width: to-rem(12);
      background-color: transparent;
    }
  }

  .infosPost-card-btn--chat {
    padding: 0 to-rem(1);
  }
}
