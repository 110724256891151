@import "../../../scss/functions.scss";

.chat-textarea {
  width: 100%;
  display: flex;
  align-items: inherit;
  gap: to-rem(8);
  pointer-events: auto;

  .chat-textarea__field {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
  }

  .chat-textarea__textarea {
    border: none;
    border-radius: to-rem(24);
    width: 100%;
    background-color: var(--dawn);
    padding: to-rem(16, 50, 16, 16);
    color: var(--white);
    font-size: to-rem(14);
    max-height: to-rem(100);
    box-sizing: border-box;
    resize: none;
    overflow-y: auto;
    word-break: break-word;
    &.chat-textarea__textarea_-active,
    &:focus {
      padding: to-rem(16, 56, 16, 16);
    }

    &::placeholder {
      font-size: to-rem(14);
    }
  }

  .chat-textarea__btn {
    width: to-rem(48);
    height: to-rem(48);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    aspect-ratio: 1 / 1;
  }

  .chat-textarea__icon {
    &::before {
      color: var(--white);
    }
  }
}
