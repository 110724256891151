@import "../../scss/functions.scss";

/* .terms-and-conditions {
    height: calc(100vh - 112px);
    overflow: auto;
}
*/

.terms-and-conditions {
  background-color: var(--bg-primary);

  &.terms-and-conditions-wrapper {
    margin: 0;

    @media screen and (min-width: 768px) {
      border-radius: var(--radius-mi);
      background-color: var(--bg-secondary);
      max-width: to-rem(500);
    }
  }

  .terms-and-conditions-inner {
    padding: 0;

    @media screen and (min-width: 768px) {
      padding: var(--standard-spacing);
    }
  }

  .term-of-service__title {
    margin: 0;
    margin-bottom: to-rem(16);
    font-size: to-rem(24);
  }

  .term-of-service__texts {
    display: flex;
    flex-direction: column;
    gap: to-rem(24);
  }

  .term-of-service__category {
    margin: 0;
    color: var(--white);
    font-size: to-rem(16);
  }

  #paragraph-text {
    margin-bottom: to-rem(24);
    font-size: to-rem(14);
  }
}
