@import "../../scss/functions.scss";
$header-height: to-rem(72);

.become-creator {
  // height: calc(100dvh - #{
  //     $header-height}
  // );
  position: absolute;
  inset: 0;
  width: 100%;
  background-color: var(--bg-primary);

  &::after {
    position: absolute;
    z-index: 10;
    content: "";
    background: url("../../assets/images/landingPageImages/banner/roses_banner_04.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    inset: 0;
  }

  &::before {
    position: absolute;
    z-index: 11;
    width: 100%;
    height: 100%;
    content: "";
    background-image: linear-gradient(180deg, #0000 0%, #000 85%);
  }

  &.become-creator-wrapper {
    margin: 0;

    @media screen and (min-width: 768px) {
      border-radius: var(--radius-mi);
      background-color: var(--bg-secondary);
      max-width: to-rem(500);
    }
  }

  .become-creator-inner {
    position: relative;
    z-index: 11;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding-right: to-rem(24);
    padding-left: to-rem(24);
    gap: to-rem(40);
  }

  .become-creator__title {
    margin: 0;
    margin-bottom: to-rem(16);
    font-size: to-rem(24);
  }

  .become-creator__header {
    justify-content: flex-end;
    flex-grow: 1;
  }
}
