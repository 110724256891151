.chat-send-roses {
  margin-bottom: 24px;
  border: 1px solid var(--gray-dark);
  border-radius: var(--radius-mi);
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--bg-secondary);
  max-width: none;
  gap: 16px;

  .chat-send-roses__btn {
    &:hover {
      cursor: auto;
    }
  }

  .chat-send-roses__logo {
    border: 1px solid var(--gray-dark);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    padding: 8px;
    background: var(--black);
  }

  .chat-send-roses__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .chat-send-roses__title {
    margin: 0;
    color: var(--white);
    font-family: "Roboto Regular";
    font-size: 14px;
  }

  .chat-send-roses__text {
    color: var(--gray-light);
    font-size: 12px;
  }

  .chat-send-roses__text--special {
    color: var(--pink-light);
    cursor: pointer;
    @extend .chat-send-roses__text;
  }

  .chat-send-roses__icon {
    font-size: 24px;
    cursor: pointer;
  }
}
