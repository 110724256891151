@import "../../scss/functions.scss";
$rem-90: to-rem(90);
$rem-78: to-rem(78);

.user-settings {
  &::before {
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;
    width: 100%;
    height: to-rem(64);
    pointer-events: none;
    content: "";
    background: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &.user-settings--creator {
  }

  // &::after {
  //   position: fixed;
  //   bottom: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 20%;
  //   content: "";
  //   background: linear-gradient(
  //     to top,
  //     rgba(0, 0, 0, 0.76) 0%,
  //     rgba(0, 0, 0, 0) 100%
  //   );
  //   pointer-events: none;
  // }

  &.user-settings-wrapper {
    margin: 0;
  }

  .user-settings-inner {
    padding: 0;
  }

  .user-settings__main {
    display: flex;
    flex-direction: column;
    padding-bottom: var(--standard-spacing);
    gap: to-rem(16);

    .user-settings__customize-button {
      width: 100%;
      max-width: to-rem(150);
      align-self: center;
    }

    .user-settings__profile-container {
      display: flex;
      align-items: center;
      padding-top: to-rem(8);
      padding-bottom: to-rem(8);
      gap: 1rem;

      .user-settings__profile-content {
        display: flex;
        align-items: center;
        flex: 1;
        gap: 1rem;

        .user-settings__profile-info {
          .user-settings__profile-name {
            margin: 0;
            color: var(--white);
            font-family: "Montserrat Variable";
            font-weight: 600;
            font-size: 1rem;
          }

          .user-settings__profile-subtitle {
            margin: 0;
            color: var(--gray-light);
            font-size: 0.875rem;
          }
        }
      }

      .user-settings__profile-avatar {
        border-radius: 100%;
        width: 3rem;
        height: 3rem;

        .user-settings__profile-avatar-image {
          border-radius: 100%;
          width: 3rem;
          height: 3rem;
          object-fit: cover;
        }
      }
    }
  }

  .user-settings__dash-preview {
    display: flex;
    flex-direction: column;
    gap: to-rem(8);
  }

  .user-settings__become {
    border-radius: var(--radius-mi);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: to-rem(24);
    background: var(--bg-tertiary);
  }

  .user-settings__become-main {
    margin-bottom: to-rem(16);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: to-rem(8);
  }

  .user-settings__become-title {
    margin: 0;
    font-size: to-rem(16);
  }

  .user-settings-list {
    display: grid;
    padding-bottom: var(--standard-spacing);
    grid-template-columns: repeat(2, 1fr);
    overflow-y: auto;
    overflow-x: hidden;
    gap: var(--inner-gap);
  }

  .user-settings-list-item {
    border-radius: var(--radius-mi);
    background-color: var(--bg-secondary);
    gap: to-rem(8);

    .user-settings-list-item-highlight {
      margin: 0;
      margin-bottom: to-rem(4);
      font-size: to-rem(10);
      text-transform: uppercase;
    }

    .user-settings-list-item-image-circle {
      background-color: var(--bg-primary);
    }
  }

  .user-settings__revenue {
    margin: 0 8px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: to-rem(8);
  }

  .buy-roses-modal {
    max-width: to-rem(400);
    overflow: visible;
  }

  .split-up-container {
    .slid-up-content {
      height: 100%;
      max-height: 70%;
    }

    .split-up__slide-children {
      height: calc(100% - #{$rem-90});
      overflow: visible auto;
    }
  }

  .user-settings__btn-create {
    position: fixed;
    z-index: 2;
    bottom: to-rem(40);
    left: 50%;
    padding: to-rem(12) to-rem(24);
    color: var(--white);
    font-family: "Montserrat Variable";
    font-weight: 600;
    font-size: to-rem(10);
    transform: translateX(-44%);
    gap: to-rem(8);
    text-transform: uppercase;
    box-shadow: 0 to-rem(4) to-rem(40) 0 #ee4e87cc;
  }

  .user-settings__btn-create-icon {
    &.icon::before {
      color: var(--white);
      pointer-events: none;
    }
  }

  @media only screen and (min-width: 48rem) {
    // padding: to-rem(12, 0, 114, 0);

    .user-settings__main {
      .user-settings__profile-container {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    .user-settings__revenue {
      margin: to-rem(0, 16);
    }
  }
}
