@import "../../../scss/functions.scss";

#input-message-default {
  width: 100%;
  display: grid;
  gap: var(--inner-gap);

  .label-input-text {
    color: var(--white);
    font-weight: 400;
    font-size: to-rem(12);
    text-align: left;
  }

  textarea {
    border: 1px solid var(--charcoal);
    border-radius: 24px;
    width: 100%;
    height: to-rem(100);
    background-color: var(--dawn);
    padding: to-rem(14) to-rem(16);
    color: var(--gray);
    font-weight: 400;
    font-size: to-rem(14);
    resize: none;
    color: var(--white);

    &::placeholder {
      color: var(--gray);
    }

    &.isWarning {
      color: var(--peach);
      outline: 1px solid var(--peach);
    }
  }

  @media only screen and (min-width: 30em) {
  }

  @media only screen and (width >= 80em) {
  }
}
