.cards-media {
  position: relative;

  .cards-loading-box {
    position: absolute;
    z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cards-media-library {
    border-radius: 16px;
  }
}

.cards-media,
.cards-media-library,
.cards-loading-box {
  width: 100%;
  height: 100%;
  aspect-ratio: 4/5;
}

.cards-media-library {
  position: relative;
  border-radius: 16px;

  &.blurred::before {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: -1px;
    top: 0;
    border-radius: 16px;
    display: block;
    content: "";
    opacity: 1;
    backdrop-filter: blur(10px);
    pointer-events: none;
  }

  .locked-section {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    margin: 0px auto;
    width: 100%;
    display: grid;
    align-items: center;
    transform: translate(-50%, -50%);
    grid-template-rows: 1fr 1fr 1fr;
    justify-items: center;

    [alt="lock icon"] {
      align-self: flex-end;
    }

    .roses-price {
      color: var(--white);
      font-weight: 500;
      font-size: 16px;
      align-self: self-start;
      text-align: center;
    }

    #button {
      position: initial;
      margin-bottom: 0;
      width: 127px;
      padding: 0 8px;
      grid-row: 3/4;
      align-self: flex-end;
    }
  }

  .principal-image {
    position: relative;
    z-index: 1;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .infinity-symbol {
    position: absolute;
    z-index: 10;
    bottom: 24px;
    left: 24px;
    width: 16px;
    height: auto;
    color: var(--white);
    font-weight: 400;
    font-size: 16px;
    text-align: left;
  }

  .locked-section {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    margin: 0px auto;
    width: 100%;
    display: grid;
    align-items: center;
    transform: translate(-50%, -50%);
    grid-template-rows: 1fr 1fr 1fr;
    justify-items: center;

    [alt="lock icon"] {
      align-self: flex-end;
    }

    .roses-price {
      color: var(--white);
      font-weight: 500;
      font-size: 16px;
      align-self: self-start;
      text-align: center;
    }

    #button {
      position: initial;
      margin-bottom: 0;
      width: 127px;
      padding: 0 8px;
      grid-row: 3/4;
      align-self: flex-end;
    }
  }

  .principal-src {
    position: relative;
    z-index: 1;
    border-radius: 16px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: cover;

    &:hover {
      cursor: pointer;
    }

    .media-audio {
      flex-direction: column;
    }

    .media-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .media-play-icon {
      position: absolute;
    }
  }

  .infinity-symbol {
    position: absolute;
    z-index: 10;
    bottom: 24px;
    left: 24px;
    width: 16px;
    height: auto;
    color: var(--white);
    font-weight: 400;
    font-size: 16px;
    text-align: left;
  }
}
