@import "../../scss/functions.scss";

.buy-roses__container {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: to-rem(24);
  max-width: to-rem(400);

  .buy-roses__buttons-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: to-rem(8);

    span {
      color: var(--white);
    }
  }

  .info-head {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: to-rem(4);

    .tooltip-container {
      max-width: to-rem(100);
    }
  }

  .info-title {
    margin: 0;
    color: var(--gold-light);
    font-weight: 400;
    font-size: to-rem(14);
    text-align: center;
  }

  .info-title__icon {
    &::before {
      color: var(--text-secondary);
    }
  }

  .buy-roses__grid {
    width: 100%;
    display: grid;
    align-items: flex-end;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
  }

  .buy-roses__select-more-container {
    display: flex;
    flex-direction: column;
    gap: to-rem(24);

    .buy-roses__select-more-container-head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .middle-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          color: var(--white);
          font-weight: 700;
          font-size: 2rem;
        }
      }
    }

    .buy-roses__select-more-inputs {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: to-rem(8);

      .subtext {
        justify-content: center;
      }
    }
  }
}
