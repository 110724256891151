@import "../../../scss/functions.scss";
$rem-64: to-rem(64);
// $rem-nav-height: to-rem(80);
$gap-to-modal: to-rem(32);

.feed-cards {
  position: relative;
  border-radius: var(--radius-mi);
  width: 100%;
  // height: calc(100dvh - #{
  //     $rem-nav-height}
  // );
  overflow: hidden;
  height: calc(100dvh - 72px);
  max-width: to-rem(840);
  scroll-snap-align: start;
  scroll-snap-type: y mandatory;
  // margin-bottom: to-rem(16);

  .feed-cards-container-locked {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0.5;
    background: #000000;
  }

  &.feed-cards--private {
    // margin-top: to-rem(48);
    height: calc(100dvh - 144px);
    scroll-snap-align: end;
  }

  &.feed-cards--expanded {
    margin-top: 0;
    height: 100dvh;
  }

  @media screen and (min-width: to-rem(768)) {
    height: calc(100dvh - 96px);

    &.feed-cards--private {
      // margin-top: to-rem(16);
      // height: calc(100dvh - 94px);
      // scroll-snap-align: start;
      // padding-top: 64px;
    }

    &.feed-cards--expanded {
      margin-top: 0;
      // height: calc(100dvh - #{
      // $gap-to-modal}
      // );
      height: calc(100dvh - 32px);
      scroll-snap-align: center;
    }
  }

  .feed-creator-image-post {
    position: relative;
    height: 100%;
    padding-bottom: to-rem(16);

    .photo-video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
    }

    [alt="creator feed post"] {
      border-radius: var(--radius-mi);
      width: 100%;
      height: 100%;
      // max-width: to-rem(840);
      // max-height: to-rem(547);
      object-fit: cover;
    }

    &::before {
      position: absolute;
      z-index: 1;
      bottom: 0;
      left: 0;
      border-radius: var(--radius-mi);
      width: 100%;
      height: 480px;
      max-height: 530px;
      content: "";
      background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.55) 0%,
        rgba(0, 0, 0, 0) 100%
      );
      pointer-events: none;
    }
  }

  .feed-cards-locked {
    position: absolute;
    z-index: 7;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate(-50%, -50%);

    .feed-cards-locked-icon {
      width: to-rem(24);
    }

    .feed-cards-locked-price {
      color: var(--white);
      font-weight: 500;
      font-size: to-rem(24);
    }

    .feed-cards-locked-text {
      color: var(--white);
      font-size: to-rem(14);
      opacity: 0.75;
    }
  }

  .feed-cards__top {
    position: absolute;
    z-index: 11;
    top: to-rem(24);
    right: to-rem(24);
    left: to-rem(24);
    display: flex;
    justify-content: space-between;
    // align-items: center;
  }

  .feed-cards__top--private {
    justify-content: flex-end;
  }

  .feed-cards-arrows {
    opacity: 0;

    &.feed-cards-arrows--show {
      opacity: 1;
    }
  }

  .feed-cards__progressbar-wrapper {
    position: absolute;
    z-index: 8;
    top: to-rem(24);
    right: to-rem(32);
    left: to-rem(32);

    width: calc(100% - #{$rem-64});
    display: flex;
    gap: to-rem(8);
  }

  .feed-card-options {
    position: relative;

    .feed-card-top-btn {
      position: relative;
      z-index: 2;
      border: 0;
      aspect-ratio: 1/1;
      background: transparent;

      &:hover {
        cursor: pointer;
      }
    }

    .feed-card-options-icon {
      font-size: to-rem(24);

      &::before {
        color: var(--white);
        pointer-events: none;
      }
    }

    .feed-card-box {
      position: absolute;
      right: 0;
      margin-top: to-rem(16);
      display: flex;
      flex-direction: column;
      display: flex;
      opacity: 0;
      transform: translateY(-30%);
      transition: all 0.5s;
    }

    .feed-card-box--active {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .creator-pack-media-info {
    display: flex;
    gap: to-rem(26);

    .creator-pack {
      height: to-rem(24);
      display: flex;
      gap: to-rem(4);

      .qnt-media-text {
        color: var(--white);
        font-weight: 400;
        font-size: to-rem(16);
        text-align: left;
        opacity: 0.7;
      }

      &.video {
      }

      &.photo {
      }

      &.price {
      }

      &.views {
      }
    }
  }
}
