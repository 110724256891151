@import "../../scss/functions.scss";

.my-request {
  display: flex;
  flex-direction: column;

  &.my-request-wrapper {
    margin: 0;
  }

  .my-request-inner {
    display: flex;
    flex-direction: column;
    padding: 0;
    flex: 1;
  }

  .my-request__main {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
    flex: 1;

    @media screen and (min-width: 768px) {
      height: initial;
      flex: 1;
    }
  }

  .my-request__bars {
    display: flex;
    padding-top: to-rem(8);
    gap: to-rem(8);
  }

  .my-request__list {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }

  .my-request__value {
    color: var(--pink-light);
    font-size: to-rem(14);
  }

  .my-request__value--gold {
    color: var(--gold-light);
  }

  .my-request__btns {
    flex: 1;
    align-content: flex-end;
  }

  .my-request__list-item-link {
    grid-template-columns: to-rem(24) 1fr auto auto;
    gap: to-rem(8);
  }

  .my-request__list-icon {
    width: to-rem(24);
    height: to-rem(24);
  }
}
