.lost-connection {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    font-family: "Montserrat Variable";
    font-weight: 700;
  }

  span {
    color: var(--white);
    font-family: "Montserrat Variable";
    font-weight: 400;
    font-size: 1.5rem;
  }

  .lost-connection-inner {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-inline: 16px;
  }
}
