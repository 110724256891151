#card-inviting-messages {
  .card-inviting-messages__time {
    color: var(--ash);
    font-weight: 400;
    font-size: 11px;
    text-align: left;
  }
  .card-inviting-messages__balloon_wrapper {
    border-radius: 0 16px 16px 16px;
    display: grid;
    background-color: var(--gray-dark);
    padding: 12px 16px;
    grid-template-columns: auto 1fr;
    gap: 16px;
    max-width: 240px;
    width: 100%;

    .card-inviting-messages__text-wrapper {
      .text-wrapper__text-message {
        color: var(--white);
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}
