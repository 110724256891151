@import url(../../scss/functions.scss);

.create-account {
  // width: 100%;
  // max-width: to-rem(390);

  display: flex;
  flex-direction: column;
  align-items: center;
  &.create-account-wrapper,
  .create-account-inner {
  }

  .create-account__inputs-container {
    display: flex;
    flex-direction: column;
    gap: to-rem(8);
    width: 100%;

    .inputs-container__password-input {
      margin-bottom: 16px;
    }

    .create-account__password {
      margin: 16px;

      hr {
        margin: 0 0 24px 0;
      }
    }

    .create-account__score {
      margin-top: to-rem(8);
      color: var(--gray);
      font-size: to-rem(12) !important;
    }
  }

  .create-account__buttons-container {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }
}
