#option {
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--dawn);
  padding: 16px;
  gap: 4px;

  .option-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .title {
      margin: 0;
      font-size: 14px;
    }

    img {
      margin: 0 4px 0 4px;
      width: 12px;
      height: 12px;
    }

    .input-select {
      margin-left: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      .popular {
        margin-left: auto;
        border-radius: 6px;
        width: 51px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-weight: bold;
        font-size: 10px;
        background-color: var(--brand-primary);
      }

      .input-radio {
        position: relative;
        border: 1px solid var(--gray-mid);
        border-radius: 50%;
        width: 22px;
        height: 22px;
        background-color: var(--dawn);
        appearance: none;
        cursor: pointer;
      }

      .input-radio:checked + label {
        color: var(--white);
      }

      .input-radio::before {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        pointer-events: none;
        background-color: var(--dawn);
        content: "";
        transform: translate(-50%, -50%);
      }

      .input-radio:after {
        border-radius: 100%;
        display: inline-block;
        align-self: center;
        justify-self: center;
        content: "";
        visibility: visible;
      }

      .input-radio:checked:after {
        position: absolute;
        z-index: 2;
        border-radius: 100%;
        width: 14px;
        height: 14px;
        background-color: var(--pink);
        content: "";
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }

  .option-price {
    color: #757575;
    font-size: 12px;

    .price {
      color: var(--pink);
      font-size: 14px;
    }
  }

  .option-text {
    width: 80%;
    color: #757575;
    font-size: 12px;
  }
}
