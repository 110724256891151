#roses-features {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;

  .pill-engagement {
    position: absolute;
    top: 24px;
    right: 24px;
  }

  @media only screen and (min-width: 48rem) {
    margin: 0 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    #button {
      margin-top: 16px;
      grid-column: 2/3;
      align-self: normal;
    }
  }

  @media only screen and (min-width: 80rem) {
    margin: 0 auto;
    max-width: 1224px;
  }
}
