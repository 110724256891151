@import "../../scss/functions.scss";

.user-settings-referrals {
  &.user-settings-referrals-wrapper {
    margin: 0;
  }

  .user-settings-referrals-inner {
    padding: 0;
  }

  .user-settings-referrals__main {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }

  .user-settings-referrals__section {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }

  .user-settings-referrals__revenues {
    display: flex;
    gap: to-rem(8);
  }

  .box-section {
    margin-bottom: to-rem(8);
    border-radius: var(--radius-mx);
    display: flex;
    flex-direction: column;
    background-color: var(--bg-tertiary);
    padding: to-rem(24);
    gap: to-rem(24);

    .box-section__top {
      display: flex;
      flex-direction: column;
      gap: to-rem(8);
      text-align: center;
    }

    .box-section__title {
      margin: 0;
      font-family: "Roboto Bold";
      font-weight: 700;
      font-size: to-rem(32);
    }

    .box-section__text {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: to-rem(14);
      gap: to-rem(4);
    }
  }

  .list-section {
    border-radius: var(--radius-mx);
    display: flex;
    flex-direction: column;
    background-color: var(--bg-tertiary);
    padding: to-rem(24);
    gap: to-rem(16);

    .list-section__top {
      display: flex;
      flex-direction: column;
      gap: to-rem(8);
    }

    .list-section__title {
      margin: 0;
      font-weight: 500;
      font-size: to-rem(14);
    }

    .list-section__text {
      font-size: to-rem(14);
    }

    .list-section__text--highlight {
      color: var(--green);
    }

    .list-section__item {
      padding: to-rem(16, 0);
      grid-template-columns: auto 1fr;
      gap: to-rem(8);
    }

    .list-section__icon {
      width: to-rem(24);
    }
  }
}
