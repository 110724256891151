.profile-card {
  background-color: var(--bg-secondary);
  position: relative;
  border-radius: var(--radius-mi);
  align-items: center;
  transition: transform 0.3s;
  display: flex;
  overflow: hidden;
  // min-height: 310px;
  aspect-ratio: 4/5;
  width: 100%;
  cursor: pointer;
  // height: 100%;

  .profile-card__media-container {
  }

  [alt="catalog gallery"],
  .catalog-media {
    width: 100%;
    height: 100%;
    border-radius: var(--radius-mi);
    object-fit: cover;
    object-position: top;
    transition: all 0.3s ease-in-out;
  }

  .catalog-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 6;
  }

  &:hover::after {
    cursor: pointer;
    opacity: 1;
  }

  // &:hover::before {
  //   background: transparent;
  // }

  .profile-title {
    position: absolute;
    z-index: 9;
    top: 16px;
    bottom: 16px;
    right: 16px;
    left: 16px;

    .user-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 4px;
      align-items: flex-start;
      justify-content: space-between;
      height: 100%;

      .profile_dots {
        position: relative;
        top: 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .right_dots {
          display: inline-flex;
          align-items: center;

          .ia-icon {
            .profile-ia-box {
              border-radius: 4px;
              padding: 4px 8px;
              color: var(--white);
              font-size: 12px;
              background: var(--bg-quaternary);
              font-weight: 500;
            }
          }
        }

        .left_dots {
          display: inline-flex;
          align-items: center;
          gap: 8px;

          .counter {
            color: var(--text-primary-75);
          }
        }
      }

      .profile-names {
        width: 100%;

        .user-title {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          gap: 4px;

          img {
            width: 16px;
            height: 16px;
          }
          .profile-info {
            margin: 0;
          }
        }

        .user-stats {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .counter {
            font-family: "Roboto regular";
            font-weight: 400;
            font-size: 14px;
            color: var(--ash);
          }
        }
      }
    }
  }

  @media only screen and (min-width: 48em) {
    .profile-title {
      top: 24px;
      bottom: 24px;
      right: 24px;
      left: 24px;
    }
  }
}
