@import "../../../scss/functions.scss";
$spacing: to-rem(48);

#explicit-content_modal {
  border-radius: var(--radius-mi);
  width: 100%;
  background-color: var(--bg-tertiary);
  overflow-y: scroll;
  max-width: to-rem(480);
  // height: 100%;
  // width: 100dvw;

  .explicit-content_modal__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: to-rem(16);
    justify-self: center;
    align-self: center;
    overflow-y: auto;
    text-wrap: wrap;

    &.explicit-content_modal-inner {
      padding: to-rem(24);
    }

    .content__texts {
      display: flex;
      flex-direction: column;
      gap: to-rem(16);

      .texts__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: to-rem(24);

        .header__icon {
          width: to-rem(24);
          height: to-rem(24);
        }

        .explicit-content__title {
          margin: 0;
          font-size: to-rem(24);
          text-align: center;
        }
      }

      .texts__descriptions-wrapper {
        border: to-rem(1) solid var(--gray-dark);
        border-radius: var(--radius-mi);
        display: flex;
        flex-direction: column;
        padding: to-rem(24);
        gap: to-rem(16);

        .descriptions-wrapper__description {
          color: var(--gray);
          font-size: to-rem(14);
        }
      }
    }

    .content__buttons-container {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: to-rem(8);

      #button.primary {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media only screen and (min-width: 48rem) {
    height: calc(100dvh - #{$spacing});
    max-height: fit-content;
    // max-width: to-rem(390);

    .explicit-content_modal__content {
      height: fit-content;
    }
  }
}
