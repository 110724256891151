.user-settings-language {
  &.user-settings-language-wrapper {
    margin: 0;
  }

  .user-settings-language-inner {
    padding: 0;
  }
  .div__content {
    overflow-y: auto;
  }

  .list-container {
    gap: 0;
  }
}
