@import "../../../../scss/functions.scss";
$spacing: to-rem(48);

#create-account-modal {
  position: relative;
  border-radius: var(--radius-mi);
  background-color: var(--dawn);
  overflow-y: scroll;

  .close-modal-icon {
    position: absolute;
    top: to-rem(8);
    right: to-rem(8);
    cursor: pointer;
    padding: to-rem(16);

    transition: all 0.3s ease-in-out;
    border-radius: 100%;
    &:hover {
      backdrop-filter: blur(5px);
    }
  }

  .create-account-modal__content {
    display: grid;

    .content__carousel-wrapper {
      display: none;
      max-width: to-rem(390);
    }

    .content__form-wrapper {
      height: calc(100dvh - #{$spacing});
      display: flex;
      flex-direction: column;
      padding: to-rem(40);
      gap: to-rem(16);
      overflow-y: scroll;
      max-height: to-rem(650);
      scroll-behavior: smooth;

      .create-account__subtext {
        align-items: center;
        padding: 0;
      }

      .create-account__subtext--checkbox {
        align-items: stretch;
        min-height: 1.5rem;
      }

      .form-wrapper__inputs {
        display: grid;
        gap: to-rem(24);

        .form__top {
          display: flex;
          flex-direction: column;
          gap: to-rem(8);
        }

        .form__main {
          margin-bottom: to-rem(8);
          display: flex;
          flex-direction: column;
          gap: to-rem(16);

          .inputs-container__passwords-container {
            display: flex;
            flex-direction: column;
            gap: to-rem(16);
          }
        }
      }

      .create-account__icon {
        margin: 0;
        width: to-rem(24);
        height: to-rem(24);
        padding: to-rem(6, 3);
      }

      .form-wrapper__buttons-container {
        display: flex;
        flex-direction: column;
        gap: to-rem(8);
        .buttons-container__login-link,
        .login-link__link {
          padding: to-rem(16) 0;
          color: var(--gray-light);
          font-family: "Montserrat Variable";
          font-weight: 600;
          font-size: to-rem(10);
          text-align: center;
          vertical-align: middle;
          text-transform: uppercase;
        }

        .login-link__link {
          color: var(--pink-hot);
          cursor: pointer;
        }
      }
    }
  }

  @media only screen and (min-width: 48rem) {
    .create-account-modal__content {
      grid-template-columns: minmax(to-rem(200), to-rem(390)) minmax(
          to-rem(300),
          to-rem(422)
        );

      .content__carousel-wrapper {
        display: block;
      }
    }
  }
}
