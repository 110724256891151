#verify_email_modal {
  position: relative;
  border-radius: var(--radius-mi);
  background-color: var(--dawn);
  overflow-y: scroll;

  .verify_email_modal__content {
    display: grid;

    .content__carousel-wrapper {
      display: none;
      max-width: 390px;
    }

    .content__form-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-height: 650px;
      padding: 40px;

      .form-wrapper__header {
        display: grid;
        gap: 16px;
      }
    }

    .content__buttons-container {
      display: grid;
      gap: 16px;
      padding: var(--standard-spacing) 0;

      #button.primary {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  @media only screen and (min-width: 48rem) {
    .verify_email_modal__content {
      grid-template-columns: minmax(200px, 390px) minmax(300px, 422px);
      .content__carousel-wrapper {
        display: block;
      }

      .content__form-wrapper {
        height: calc(100dvh - 48px);
        overflow-y: scroll;
      }
    }
  }
}
