@import "../../../scss/functions.scss";

.roses-dashboard-container {
  position: relative;
  border-radius: 1rem;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  background-color: var(--dawn);

  &.inner {
    padding: 1.5rem;
  }

  .text-container {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: transparent;
    text-align: center;
  }

  .title-text {
    background-color: transparent;
    color: var(--white);
    font-family: "Roboto Bold";
    font-size: 2rem;
    text-transform: uppercase;
    margin-bottom: to-rem(8);
  }

  .subtitle-text {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: var(--gray);
    font-size: 0.875rem;
    font-family: "Roboto Regular";
    margin-bottom: to-rem(8);

    .icon {
      margin-left: 0.25rem;
      width: 1rem;
      height: 1rem;
      padding: 0.1rem;
    }
  }

  .gold {
    color: var(--gold-light);
    font-family: "Roboto Regular";
    margin-bottom: 0;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
}
