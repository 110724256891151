.user-settings-change-profile {
  padding-top: 72px;
  overflow-x: hidden;
  overflow-y: hidden;

  &.user-settings-change-profile-wrapper {
    margin: 0;
  }

  .user-settings-change-profile-inner {
    padding: 0;
  }

  .user-settings-change-profile__page-title {
    padding-bottom: var(--standard-spacing);
  }

  .user-settings-change-profile__main {
    .main__creators-list-wrapper {
      height: calc(100dvh - 182px);
      overflow-y: scroll;
    }
    &::before {
      position: absolute;
      z-index: 1;
      top: 24px;
      left: 0;
      width: 100%;
      height: 100px;
      content: "";
      background: linear-gradient(
        to bottom,
        var(--black) 30%,
        transparent 100%
      );
      pointer-events: none;
    }
  }

  @media only screen and (min-width: 48rem) {
    .user-settings-change-profile__main {
      .main__creators-list-wrapper {
        height: calc(100dvh - 192px);
      }
    }
  }

  @media only screen and (min-width: 64rem) {
    padding: 0;
  }
}
