@import "../../scss/functions.scss";
$titleSpacing: to-rem(140);
$botSpacing: to-rem(16);
$sliderTopSpacing: to-rem(90);

.feed-page__loading {
  height: 100%;
  display: flex;
  justify-content: center;
}

.feed-page {
  position: relative;
  width: 100%;
  // height: calc(100dvh - #{
  // $botSpacing}
  // );
  display: flex;
  flex-direction: column;
  justify-items: center;
  overflow-y: scroll;
  &.feed-page-wrapper,
  .feed-page-inner {
    margin: 0;
    padding: 0;
  }

  .feed-page-inner {
    height: calc(100dvh - 66px);
    display: flex;
    flex-direction: column;
    align-items: center;
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
  }

  &.vertical-separator-left {
    border-collapse: collapse;
  }

  .feed-page__page-title {
    top: 78px;
    // position: sticky;

    &::before {
      right: 0;
      width: inherit;
      height: 50px;
    }
    // width: 100%;
    // padding-top: to-rem(72);
    // &::before {
    //   position: absolute;
    //   z-index: 1;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 6.25rem;
    //   content: "";
    //   background: linear-gradient(to bottom, var(--black) 30%, transparent 100%);
    //   pointer-events: none;
    // }
  }

  .feed__label {
    padding-top: to-rem(78);
    scroll-snap-align: start;
  }

  .feed__posts {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    // min-height: 100dvh;
    transition: padding-top 0.1s ease-in-out;
    gap: 16px;
  }
  // .padding-for-navigation {
  //   padding-top: 64px;
  //   transition: padding-top 0.1s ease-in-out;
  //   &:first-child {
  //     .feed-creator-image-post {
  //       [alt="creator feed post"] {
  //         object-fit: none;
  //         object-position: top;
  //       }
  //     }
  //   }
  // }

  .feed-page-main {
    z-index: 2;
    width: 100%;
    max-width: to-rem(600);

    .feed-page__interaction-nav {
      position: relative;
      margin-bottom: to-rem(16);
      border-radius: var(--radius-mi);
      height: 340px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 24px;
      //gap: to-rem(92);
      background-size: cover;
      background-position: center;
      margin-bottom: 16px;
      // &::after {
      //   position: absolute;
      //   bottom: 0;
      //   left: 0;
      //   border-radius: 0 0 to-rem(24) to-rem(24);
      //   width: 100%;
      //   height: 63%;
      //   content: "";
      //   background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%),
      //     linear-gradient(180deg, rgba(91, 207, 255, 0) 0%, rgba(91, 207, 255, 0.75) 100%);
      // }

      &.mobile-size {
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        &.feed-page__interaction-nav {
          gap: to-rem(0);
        }

        .feed__label-bottom {
          flex-direction: column;
          justify-content: flex-end;
        }

        .interaction-nav__profile-info {
          &.info-column {
            padding-bottom: to-rem(16);
          }
        }
      }

      .feed__label-top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-self: flex-start;
      }
      .feed__label-left,
      .feed__label-statistics {
        display: flex;
        align-items: center;
        gap: to-rem(4);

        .counter {
          font-size: 12px;
        }
      }

      .feed__label-bottom {
        position: relative;
        z-index: 2;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
      }

      .interaction-nav__profile-info {
        display: flex;
        align-items: center;
        // margin-bottom: var(--standard-spacing);
        gap: to-rem(8);

        &.info-column {
          width: 100%;
          flex-direction: column;
          justify-content: center;
          text-align: center;
        }

        .profile-info__name {
          .profile-info__text {
            color: var(--white);
            font-family: "Montserrat Variable";
            font-weight: 600;
            font-size: to-rem(16);
            // margin: to-rem(4) 0;
            text-wrap: nowrap;
            // gap: to-rem(8);

            &:last-child {
              color: var(--ash);
              font-family: "Roboto Regular";
              font-weight: 400;
              font-size: to-rem(14);
            }
          }
        }
      }

      .interaction-nav__interaction {
        display: flex;
        gap: to-rem(8);
      }
    }

    .buttons-navigation-feed-container {
      margin-bottom: to-rem(16);
      width: 100%;
    }
  }

  .feed-global-loading {
    position: relative;
    z-index: 2;
    text-align: center;
  }

  .ia-icon {
    border-radius: to-rem(4);
    background-color: var(--charcoal);
    padding: to-rem(4) to-rem(8);
    color: var(--white);
    font-size: to-rem(12);
  }

  .feed-page__modal {
    max-width: to-rem(400);
  }

  @media only screen and (min-width: 48rem) {
    .feed-page-inner {
      height: calc(100dvh - 80px);
    }

    .feed-page__page-title {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      width: 100%;
    }

    &.standard-page-layout {
      height: initial;
    }

    .feed__label {
      padding-top: to-rem(64);
    }

    .feed-page__page-title {
      padding-top: 0;
    }

    /*.feed__label {
            padding-top: to-rem(64);
        }
        */

    .feed-page-main {
      .feed-page__interaction-nav {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
      }
    }
  }
  // @media screen and (min-width: 48rem) {
  //   //     .feed-page-main {
  //   //       height: calc(100dvh - #{
  //$titleSpacing}
  //);
  //   //     }
  //   //   }
  //   //   .subscription-plan-modal {
  //   //     max-width: to-rem(400);
  //   //   }
  //   //   .split-up-container {
  //   //     .slid-up-content {
  //   //       height: 100%;
  //   //       max-height: 70%;
  //   //     }
  //   //     .split-up__slide-children {
  //   //       height: calc(100% - #{
  //$sliderTopSpacing}
  //);
  //   //       overflow: visible auto;
  //   //     }
  //   //   }
  //   // }
  //   // .feed-posts-expand-modal {
  //   //   max-width: none;
  //   //   max-height: none;
  //   //   .feed-posts-expand-modal-list {
  //   //     width: 100%;
  //   //     height: 100vh;
  //   //     display: grid;
  //   //     scroll-snap-type: y mandatory;
  //   //     overflow: scroll;
  //   //     place-items: center;
  //   //     .feed-posts-expand-modal-list-item {
  //   //       width: 100%;
  //   //       display: grid;
  //   //       scroll-snap-align: center;
  //   //       max-width: to-rem(1200);
  //   //       place-items: center;
  //   //     }
  // }
}
