@import "../../scss/functions.scss";
$spacing: 234;

.user-settings-profile {
  overflow: hidden;

  &::before {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100%;
    height: to-rem(100);
    content: "";
    pointer-events: none;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 76) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &.user-settings-profile-wrapper {
    margin: 0;
  }

  .user-settings-profile-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0;
  }

  .user-settings-profile__actions {
    position: sticky;
    z-index: 10;
    top: 0;
    width: 100%;
    overflow-y: hidden;
  }

  .user-settings-profile__main {
    overscroll-behavior: none;
    overflow: hidden;
  }

  .user-settings-profile__bars {
    margin-bottom: to-rem(16);
    display: flex;
    gap: to-rem(8);
  }

  .user-settings-profile__detail {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: to-rem(14);
    gap: to-rem(8);
    text-transform: capitalize;
  }

  .user-settings-profile__radio-list {
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    overscroll-behavior: none;
    overflow-y: auto;
  }

  .user-settings-profile__slid-up {
    bottom: to-rem(80);
  }

  .user-settings-profile__slid-up-list {
    #button {
      margin-top: to-rem(12);
    }
  }

  .user-settings-profile__toggle {
    width: 100%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    color: var(--white);
    font-size: to-rem(14);
    gap: to-rem(8);
  }
}
