@import "../../scss/functions.scss";

.account-type {
  margin: 0 auto;
  max-width: to-rem(460);
  &.account-type-wrapper,
  .account-type-inner {
    margin: 0;
    padding: 0;
  }
}
