@import "../../scss/functions.scss";

.user-settings-edit-profile {
  &.user-settings-edit-profile-wrapper {
    margin: 0;
  }

  .user-settings-edit-profile-inner {
    padding: 0;
  }

  .user-settings-edit-profile__main {
    display: flex;
    flex-direction: column;
    gap: to-rem(24);
  }

  .user-settings-edit-profile__card-action {
    border-radius: var(--radius-mi);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: to-rem(24);
    background: var(--bg-tertiary);
    gap: to-rem(16);
  }

  .user-settings-edit-profile__card-action-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: to-rem(8);
  }

  .user-settings-edit-profile__card-action-icon {
    border: to-rem(1) solid var(--border-primary);
    border-radius: 50%;
    padding: to-rem(8);
    font-size: to-rem(24);
    background: var(--bg-primary);

    &::before {
      color: var(--blue);
    }
  }

  .user-settings-edit-profile__card-action-title {
    margin: 0;
    font-size: to-rem(16);
  }

  .user-settings-edit-profile__fields {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }

  .user-settings-edit-profile__explanation {
    color: var(--gray);
    font-size: to-rem(12);
  }

  .user-settings-edit-profile__link-highlight {
    color: var(--faded);
  }

  .user-settings-edit-profile__link-container {
    display: flex;
    flex-direction: column;
  }

  .user-settings-edit-profile__buttons-containers {
    position: absolute;
    right: to-rem(8);
    bottom: to-rem(24);
    left: to-rem(8);
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }
}
