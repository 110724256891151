@import "../../scss/functions.scss";

.page-not-found {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .page-not-found-inner {
    display: flex;
    flex-direction: column;
    gap: 16px;
    h3 {
      font-family: "Montserrat Variable";
      font-weight: 700;
    }

    span {
      color: var(--white);
      font-family: "Montserrat Variable";
      font-weight: 400;
      font-size: 1.5rem;
    }

    .page-not-found__buttons-container {
      display: flex;

      flex-direction: column;
      gap: var(--inner-gap);
    }
  }
  // &.my-request-wrapper {
  //   margin: 0;
  // }

  // .my-request-inner {
  //   display: flex;
  //   flex-direction: column;
  //   padding: 0;
  //   gap: to-rem(24);
  //   padding-inline: to-rem(16);
  // }
}
