.user-settings-manage-account {
  &.user-settings-manage-account-wrapper {
    margin: 0;
  }

  .user-settings-manage-account-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .user-settings-configuration__options {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
