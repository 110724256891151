@import "../../scss/functions.scss";

.verify-document {
  height: 100dvh;

  &.verify-document-wrapper {
    margin: 0;
  }

  .verify-document-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .verify-document__body {
    text-align: center;
    flex-grow: 1;
    align-content: center;
  }

  .verify-document__content {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
  }

  .verify-document__icon {
    font-size: to-rem(30);
  }

  .verify-document__title {
    margin: 0;
    font-size: to-rem(14);
    text-transform: uppercase;
  }

  .verify-document__text {
    font-size: to-rem(14);
  }
}
