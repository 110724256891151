#document-placeholder {
  display: grid;
  justify-content: center;
  gap: var(--inner-gap);
  span,
  img,
  p {
    background-color: var(--dawn);
  }

  .photo-preview {
    position: relative;
    border-radius: 8px;
    width: 322px;
    height: 176px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--dawn);
    overflow: hidden;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23555555FF' stroke-width='2' stroke-dasharray='8' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e");
    cursor: pointer;

    :hover {
      cursor: pointer;
    }

    .bottom-text {
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0 0 8px 12.14px;
      color: var(--gray);
      font-size: 0.75rem;
    }

    .preview-images {
      img {
        z-index: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      button {
        position: absolute;
        top: 5px;
        right: 5px;
        border: none;
        background-color: transparent;

        img {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          background-color: rgba(0, 0, 0, 0.5);
          padding: 10px;
        }
      }
    }
  }
}
