@import "../../scss/functions.scss";

.create-request {
  display: flex;
  flex-direction: column;

  &.create-request-wrapper {
    margin: 0;
  }

  .create-request-inner {
    display: flex;
    flex-direction: column;
    padding: 0;
    flex: 1;
  }

  .create-request__main {
    display: flex;
    flex-direction: column;
    gap: to-rem(16);
    flex: 1;

    @media screen and (min-width: 768px) {
      height: initial;
      flex: 1;
    }
  }

  .create-request__split {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: to-rem(8);
  }

  #input-text-default .create-request__value {
    background-color: transparent;
  }

  .create-request__tip {
    padding: to-rem(0, 16);
    color: var(--gray-light);
    font-size: to-rem(12);
    grid-column: 1 / span 2;
  }

  .create-request__btns {
    display: flex;
    flex-direction: column;
    gap: to-rem(8);
  }
}
