@import "../../../scss/functions.scss";

.input-radio-carrousel {
  position: relative;
  width: 100%;

  .input-radio-carrousel__bar {
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 0;
    left: 0;
    height: to-rem(50);
    background-color: var(--charcoal);
    transform: translateY(-50%);
  }

  .input-radio-carrousel__list {
    position: relative;
    width: 100%;
    height: to-rem(200);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white);
    overflow: hidden;
  }

  .input-radio-carrousel__text {
    position: relative;
    z-index: 5;
    width: 100%;
    padding: to-rem(8);
    text-align: center;
    transform: scale(0.9);
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    min-height: to-rem(40);
  }

  .input-radio-carrousel__text.selected {
    font-weight: bold;
    opacity: 1;
    transform: scale(1.2);
    transition: all 0.3s ease-in-out;
  }
}
