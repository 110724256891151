#modal-initial-preferences {
  border-radius: var(--radius-mi);
  background-color: var(--dawn);
  overflow-y: scroll;
  height: auto;
  max-width: 422px;

  .modal-initial-preferences__inner {
    .inner__header-wrapper {
      .header-wrapper__title {
        text-align: center;
        font-family: "Montserrat Variable";
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        color: var(--white);
      }

      .header-wrapper__paragraph {
        text-align: center;
        font-family: "Raleway", sans-serif;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .inner__cards-gender-selection-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin: 24px 0;
      gap: 24px;

      .card-gender-selection-wrapper__card {
        flex: 1 1 50%;
        max-width: calc(50% - 12px);
        position: relative;
        height: 215px;
        border-radius: var(--radius-mi);

        &.selected-card {
          outline: 1px solid var(--pink-light);
        }

        .card__image-card {
          border-radius: var(--radius-mi);
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }

        .card__texts-wrapper {
          position: absolute;
          bottom: 16px;
          right: 50%;
          transform: translateX(50%);

          .texts-wrapper__principal-text {
            font-family: "Montserrat Variable";
            font-weight: 600;
            font-size: 16px;
            color: var(--white);
          }
        }
      }
    }

    .inner_buttons-wrapper {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }

  @media only screen and (min-width: 60rem) {
    max-width: inherit;
    .modal-initial-preferences__inner {
      .inner__cards-gender-selection-wrapper {
        .card-gender-selection-wrapper__card {
          flex: inherit;
          max-width: inherit;
          width: 175px;
        }
      }
    }
  }
}
