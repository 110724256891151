.plan__container {
  display: flex;
  flex-direction: column;
  gap: var(--inner-gap);
  max-width: 800px;
  width: 100%;

  .plan-modal-buttons-container {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;

    @media (max-width: 48rem) {
      justify-content: center;
    }

    .button-cancel {
      width: 100%;
      max-width: 126px;
    }

    .plan-modal-buttons-container-right {
      display: flex;
      gap: var(--inner-gap);
      width: 100%;
      max-width: 420px;
      @media (max-width: 48rem) {
        flex-direction: column;
        width: 100%;
      }
      .plan-modal-subscribe-button {
        background-color: var(--gold);
        color: var(--charcoal);
        &:hover {
          opacity: 0.85;
        }
      }
    }

    span {
      color: var(--white);
    }
  }

  .loading {
    margin: 0 auto;
  }

  .plan-modal__content {
    width: 100%;
    display: flex;
    gap: var(--inner-gap);

    @media (max-width: 48rem) {
      flex-direction: column;
    }

    .plan-list {
      display: flex;
      width: 100%;
      gap: 0.5rem;
    }

    .plan-features-list {
      width: 100%;
      background: var(--midnight);
      border-radius: 1rem;

      .plan-features-list-logo {
        width: 1.5rem;
        height: 1.5rem;
      }
      .plan-features-list-item {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        border-bottom: 1px solid var(--gray-dark);
        padding: 0.5rem;
        .plan-features-list-item-text {
          color: var(--gray);
          font-size: 0.75rem;
        }
        .plan-features-list-first-item-subtext {
          color: var(--gray);
          font-size: 0.75rem;
          margin: 0;
        }
        .plan-features-list-item-icon {
          font-size: 1.5rem;
        }
        &:first-child {
          display: flex;
          flex-wrap: wrap;
          align-items: start;
          gap: 0.5rem;
          .plan-features-list-first-line {
            display: flex;
            align-items: center;
            gap: 0.25rem;
            .plan-features-list-first-line-number {
              color: var(--pink-light);
              font-weight: 600;
              font-size: 1rem;
            }
            .plan-features-list-first-line-number-creator {
              color: var(--gold);
            }
            .plan-features-list-first-line-number-consumer {
              color: var(--pink-light);
            }
            .plan-features-list-first-line-text {
              color: var(--white);
              margin: 0;
              font-size: 0.875rem;
            }
            .plan-features-list-first-line-icon {
              font-size: 1rem;
            }
          }
        }
        &:last-child {
          border-bottom: 0px;
        }
      }
    }
  }
  .plan-modal__features {
    grid-column-start: 1;
    grid-column-end: 5;
  }

  @media only screen and (min-width: 48rem) {
  }
}
