@import "../../scss/functions.scss";

.page-title-container {
  position: sticky;
  z-index: 10;
  top: to-rem(0);
  display: flex;
  align-items: center;
  padding-bottom: to-rem(16);
  gap: to-rem(8);
  overflow: visible;

  &.background-transparent {
    background: transparent;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: to-rem(56);
    pointer-events: none;
    //z-index: 11;
    content: "";
    background: linear-gradient(to bottom, rgb(0 0 0) 0%, rgb(0 0 0 / 0%) 100%);
  }

  &.degrade-midnight {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      //z-index: 11;
      content: "";
      pointer-events: none;
      background: linear-gradient(
        var(--midnight) 0,
        var(--midnight) 0,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  @media screen and (min-width: 768px) {
    &::before {
      top: 16px;
    }

    &.page-title-become {
      &::before {
        background: transparent;
      }
    }
  }

  .chat-icon {
    border-radius: 100%;
    height: to-rem(40);
    cursor: pointer;

    &.new-moment-available {
      background: linear-gradient(
        150.65deg,
        var(--peach) 13.34%,
        var(--pink) 90.3%
      );
    }

    &.moment-was-seen {
      background-color: var(--charcoal);
    }

    .chat-icon-img {
      border-radius: 100%;
      width: to-rem(40);
      height: to-rem(40);
      aspect-ratio: 1 / 1;
      object-fit: cover;
    }
  }

  .text-wrapper {
    z-index: 10;
    margin-left: to-rem(8);
    width: 100%;
    background-color: transparent;
    //height: to-rem(40);

    .title {
      background-color: transparent;
      color: var(--white);
      font-family: "Montserrat Variable";
      font-weight: 500;
      font-size: to-rem(14);
      letter-spacing: var(--letter-spacing);
      text-transform: uppercase;

      &.chat-title {
        text-transform: none;
      }
    }

    .subtitle-wrapper {
      display: flex;
      align-items: center;
      gap: to-rem(4);

      .chat-icon-status {
        border-radius: 100%;
        width: to-rem(8);
        height: to-rem(8);

        &.interest-online {
          background-color: var(--green);
        }

        &.interest-offline {
          background-color: var(--gray-light);
        }
      }

      .subtitle {
        background-color: transparent;
        color: var(--gray-light);
        font-weight: 400;
        font-size: to-rem(14);
        // margin-top: to-rem(3);
      }
    }
  }

  .back-button {
    z-index: 10;
    border-radius: 50%;
    height: to-rem(48);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    min-width: to-rem(48);
    cursor: pointer;

    &.button-transparent {
      background-color: transparent;
    }

    [alt="x back icon"] {
      width: to-rem(11);
      height: to-rem(11);
      background-color: transparent;
    }
  }

  .open-extra {
    z-index: 10;
    display: flex;
    justify-content: flex-end;

    .open-extra-button {
      border: none;
      border-radius: to-rem(24);
      background-color: var(--bg-secondary);
      padding: to-rem(14, 40);
      color: var(--gray-light);
      font-family: "Montserrat Variable";
      font-weight: 600;
      font-size: to-rem(10);
      line-height: to-rem(20);
      letter-spacing: 0.05em;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
    }
  }

  .settings-button {
    z-index: 10;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    padding: 0.75rem;
    background: var(--midnight);

    .settings-button-icon {
      width: 1.5rem;
      height: 1.5rem;
      padding: 0.25rem;
    }

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  @media only screen and (min-width: 48rem) {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      //z-index: 11;
      content: "";
      pointer-events: none;
      background: linear-gradient(
        rgba(0, 0, 0, 0.86) 0,
        rgba(0, 0, 0, 0.92) 0,
        rgba(0, 0, 0, 0) 100%
      );
    }

    &.degrade-midnight {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        //z-index: 11;
        content: "";
        pointer-events: none;
        background: linear-gradient(
          var(--midnight) 0,
          var(--midnight) 0,
          rgba(0, 0, 0, 0) 100%
        );
      }
    }

    .page-title-container .back-button {
    }

    .page-title-container .open-extra {
    }
  }
}
