@import "../../scss/functions.scss";
$header-height: to-rem(72);

.create-legal-name {
  height: calc(100dvh - #{$header-height});
  // display: grid;
  // grid-template-rows: 80px 1fr 1fr;

  &.create-legal-name-wrapper {
    margin: 0;
  }

  .create-legal-name-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .create-legal-name__main {
    margin-bottom: auto;
  }

  .create-legal-name__title {
    margin: 0;
    margin-bottom: to-rem(16);
  }

  .create-legal-name__header {
    margin-bottom: to-rem(24);
  }

  .create-legal-name__icon-disclaimer {
    align-items: center;
    gap: to-rem(8);

    .create-legal-name__icon {
      margin: 0;
      width: to-rem(24);
      height: to-rem(24);
      padding: to-rem(2) to-rem(3);
    }
  }

  .create-legal-name__btn {
    margin-top: auto;
  }
}
