@import "./var.scss";

@font-face {
  font-family: "#{$roses-font-family}";
  src: url("#{$roses-font-path}/#{$roses-font-family}.eot?pkj7rc");
  src:
    url("#{$roses-font-path}/#{$roses-font-family}.eot?pkj7rc#iefix")
      format("embedded-opentype"),
    url("#{$roses-font-path}/#{$roses-font-family}.ttf?pkj7rc")
      format("truetype"),
    url("#{$roses-font-path}/#{$roses-font-family}.woff?pkj7rc") format("woff"),
    url("#{$roses-font-path}/#{$roses-font-family}.svg?pkj7rc##{$roses-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$roses-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add {
  &:before {
    content: $icon-add;
    color: #757575;
  }
}
.icon-airplane {
  &:before {
    content: $icon-airplane;
    color: #757575;
  }
}
.icon-apenotic-avatar {
  &:before {
    content: $icon-apenotic-avatar;
    color: #757575;
  }
}
.icon-apenotic {
  &:before {
    content: $icon-apenotic;
    color: #757575;
  }
}
.icon-apple {
  &:before {
    content: $icon-apple;
    color: #757575;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
    color: #757575;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
    color: #757575;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
    color: #757575;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
    color: #757575;
  }
}
.icon-auction {
  &:before {
    content: $icon-auction;
    color: #757575;
  }
}
.icon-bank {
  &:before {
    content: $icon-bank;
    color: #757575;
  }
}
.icon-birth {
  &:before {
    content: $icon-birth;
    color: #757575;
  }
}
.icon-blocked {
  &:before {
    content: $icon-blocked;
    color: #757575;
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark;
    color: #757575;
  }
}
.icon-brush {
  &:before {
    content: $icon-brush;
    color: #757575;
  }
}
.icon-bulk-images {
  &:before {
    content: $icon-bulk-images;
    color: #757575;
  }
}
.icon-burn {
  &:before {
    content: $icon-burn;
    color: #757575;
  }
}
.icon-buy {
  &:before {
    content: $icon-buy;
    color: #757575;
  }
}
.icon-calendar-add {
  &:before {
    content: $icon-calendar-add;
    color: #757575;
  }
}
.icon-camera {
  &:before {
    content: $icon-camera;
    color: #757575;
  }
}
.icon-camp {
  &:before {
    content: $icon-camp;
    color: #757575;
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel;
    color: #757575;
  }
}
.icon-Cardano {
  &:before {
    content: $icon-Cardano;
    color: #757575;
  }
}
.icon-certified-1 {
  &:before {
    content: $icon-certified-1;
    color: #999;
  }
}
.icon-certified .path1 {
  &:before {
    content: $icon-certified-path1;
    color: rgb(0, 0, 0);
  }
}
.icon-certified .path2 {
  &:before {
    content: $icon-certified-path2;
    margin-left: -1em;
    color: rgb(32, 129, 226);
  }
}
.icon-chain {
  &:before {
    content: $icon-chain;
    color: #757575;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
    color: #757575;
  }
}
.icon-chevron-bottom {
  &:before {
    content: $icon-chevron-bottom;
    color: #757575;
  }
}
.icon-chevron-double-left {
  &:before {
    content: $icon-chevron-double-left;
    color: #757575;
  }
}
.icon-chevron-double-right {
  &:before {
    content: $icon-chevron-double-right;
    color: #757575;
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left;
    color: #757575;
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right;
    color: #757575;
  }
}
.icon-chevron-top {
  &:before {
    content: $icon-chevron-top;
    color: #757575;
  }
}
.icon-circle {
  &:before {
    content: $icon-circle;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
    color: #757575;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
    color: #757575;
  }
}
.icon-cloud {
  &:before {
    content: $icon-cloud;
    color: #757575;
  }
}
.icon-code-1 {
  &:before {
    content: $icon-code-1;
    color: #757575;
  }
}
.icon-code {
  &:before {
    content: $icon-code;
    color: #757575;
  }
}
.icon-collectors {
  &:before {
    content: $icon-collectors;
    color: #757575;
  }
}
.icon-connection {
  &:before {
    content: $icon-connection;
    color: #757575;
  }
}
.icon-contract {
  &:before {
    content: $icon-contract;
    color: #757575;
  }
}
.icon-copy {
  &:before {
    content: $icon-copy;
    color: #757575;
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card;
    color: #757575;
  }
}
.icon-cube {
  &:before {
    content: $icon-cube;
    color: #757575;
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard;
    color: #757575;
  }
}
.icon-deferred {
  &:before {
    content: $icon-deferred;
    color: #757575;
  }
}
.icon-detail {
  &:before {
    content: $icon-detail;
    color: #757575;
  }
}
.icon-digitalprint {
  &:before {
    content: $icon-digitalprint;
    color: #757575;
  }
}
.icon-discord {
  &:before {
    content: $icon-discord;
    color: #757575;
  }
}
.icon-dollar {
  &:before {
    content: $icon-dollar;
    color: #757575;
  }
}
.icon-dots-h {
  &:before {
    content: $icon-dots-h;
    color: #757575;
  }
}
.icon-dots-v {
  &:before {
    content: $icon-dots-v;
    color: #757575;
  }
}
.icon-dribble {
  &:before {
    content: $icon-dribble;
    color: #757575;
  }
}
.icon-drink {
  &:before {
    content: $icon-drink;
    color: #757575;
  }
}
.icon-earth {
  &:before {
    content: $icon-earth;
    color: #757575;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
    color: #757575;
  }
}
.icon-ethereum {
  &:before {
    content: $icon-ethereum;
    color: #757575;
  }
}
.icon-ethnicity {
  &:before {
    content: $icon-ethnicity;
    color: #757575;
  }
}
.icon-events {
  &:before {
    content: $icon-events;
    color: #757575;
  }
}
.icon-eye {
  &:before {
    content: $icon-eye;
    color: #757575;
  }
}
.icon-face-id-confirmed {
  &:before {
    content: $icon-face-id-confirmed;
    color: #757575;
  }
}
.icon-face-id {
  &:before {
    content: $icon-face-id;
    color: #757575;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
    color: #757575;
  }
}
.icon-fingerprint {
  &:before {
    content: $icon-fingerprint;
    color: #757575;
  }
}
.icon-fixed {
  &:before {
    content: $icon-fixed;
    color: #999;
  }
}
.icon-flag {
  &:before {
    content: $icon-flag;
    color: #757575;
  }
}
.icon-folder {
  &:before {
    content: $icon-folder;
    color: #757575;
  }
}
.icon-food-1 {
  &:before {
    content: $icon-food-1;
    color: #757575;
  }
}
.icon-food-2 {
  &:before {
    content: $icon-food-2;
    color: #757575;
  }
}
.icon-food {
  &:before {
    content: $icon-food;
    color: #757575;
  }
}
.icon-games {
  &:before {
    content: $icon-games;
    color: #757575;
  }
}
.icon-gas {
  &:before {
    content: $icon-gas;
    color: #757575;
  }
}
.icon-gear {
  &:before {
    content: $icon-gear;
    color: #757575;
  }
}
.icon-gender {
  &:before {
    content: $icon-gender;
    color: #757575;
  }
}
.icon-gifts {
  &:before {
    content: $icon-gifts;
    color: #757575;
  }
}
.icon-globe {
  &:before {
    content: $icon-globe;
    color: #757575;
  }
}
.icon-google-play .path1 {
  &:before {
    content: $icon-google-play-path1;
    color: rgb(117, 117, 117);
  }
}
.icon-google-play .path2 {
  &:before {
    content: $icon-google-play-path2;
    margin-left: -1em;
    color: rgb(117, 117, 117);
    opacity: 0.4;
  }
}
.icon-google-play .path3 {
  &:before {
    content: $icon-google-play-path3;
    margin-left: -1em;
    color: rgb(117, 117, 117);
    opacity: 0.7;
  }
}
.icon-google-play .path4 {
  &:before {
    content: $icon-google-play-path4;
    margin-left: -1em;
    color: rgb(117, 117, 117);
    opacity: 0.5;
  }
}
.icon-Google .path1 {
  &:before {
    content: $icon-Google-path1;
    color: rgb(117, 117, 117);
    opacity: 0.7;
  }
}
.icon-Google .path2 {
  &:before {
    content: $icon-Google-path2;
    margin-left: -1em;
    color: rgb(117, 117, 117);
    opacity: 0.85;
  }
}
.icon-Google .path3 {
  &:before {
    content: $icon-Google-path3;
    margin-left: -1em;
    color: rgb(117, 117, 117);
    opacity: 0.6;
  }
}
.icon-Google .path4 {
  &:before {
    content: $icon-Google-path4;
    margin-left: -1em;
    color: rgb(117, 117, 117);
  }
}
.icon-grid {
  &:before {
    content: $icon-grid;
    color: #757575;
  }
}
.icon-gym {
  &:before {
    content: $icon-gym;
    color: #757575;
  }
}
.icon-hair-1 {
  &:before {
    content: $icon-hair-1;
    color: #757575;
  }
}
.icon-hair-2 {
  &:before {
    content: $icon-hair-2;
    color: #757575;
  }
}
.icon-hair {
  &:before {
    content: $icon-hair;
    color: #757575;
  }
}
.icon-heart-outline {
  &:before {
    content: $icon-heart-outline;
    color: #757575;
  }
}
.icon-heart-solid {
  &:before {
    content: $icon-heart-solid;
    color: #757575;
  }
}
.icon-height {
  &:before {
    content: $icon-height;
    color: #757575;
  }
}
.icon-hiking {
  &:before {
    content: $icon-hiking;
    color: #757575;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
    color: #757575;
  }
}
.icon-id {
  &:before {
    content: $icon-id;
    color: #757575;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
    color: #757575;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
    color: #757575;
  }
}
.icon-key {
  &:before {
    content: $icon-key;
    color: #757575;
  }
}
.icon-keypad {
  &:before {
    content: $icon-keypad;
    color: #757575;
  }
}
.icon-language {
  &:before {
    content: $icon-language;
    color: #757575;
  }
}
.icon-layers {
  &:before {
    content: $icon-layers;
    color: #757575;
  }
}
.icon-link {
  &:before {
    content: $icon-link;
    color: #757575;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
    color: #757575;
  }
}
.icon-loan {
  &:before {
    content: $icon-loan;
    color: #999;
  }
}
.icon-location {
  &:before {
    content: $icon-location;
    color: #757575;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
    color: #757575;
  }
}
.icon-log-in {
  &:before {
    content: $icon-log-in;
    color: #757575;
  }
}
.icon-log-out {
  &:before {
    content: $icon-log-out;
    color: #757575;
  }
}
.icon-mail {
  &:before {
    content: $icon-mail;
    color: #757575;
  }
}
.icon-makeup-1 {
  &:before {
    content: $icon-makeup-1;
    color: #757575;
  }
}
.icon-makeup-2 {
  &:before {
    content: $icon-makeup-2;
    color: #757575;
  }
}
.icon-makeup {
  &:before {
    content: $icon-makeup;
    color: #757575;
  }
}
.icon-marketplace {
  &:before {
    content: $icon-marketplace;
    color: #757575;
  }
}
.icon-match {
  &:before {
    content: $icon-match;
    color: #757575;
  }
}
.icon-measurement-1 {
  &:before {
    content: $icon-measurement-1;
    color: #757575;
  }
}
.icon-measurement-2 {
  &:before {
    content: $icon-measurement-2;
    color: #757575;
  }
}
.icon-measurement {
  &:before {
    content: $icon-measurement;
    color: #757575;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
    color: #757575;
  }
}
.icon-messages {
  &:before {
    content: $icon-messages;
    color: #757575;
  }
}
.icon-mic {
  &:before {
    content: $icon-mic;
    color: #757575;
  }
}
.icon-mint {
  &:before {
    content: $icon-mint;
    color: #757575;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
    color: #757575;
  }
}
.icon-mobile-ring {
  &:before {
    content: $icon-mobile-ring;
    color: #757575;
  }
}
.icon-mobile {
  &:before {
    content: $icon-mobile;
    color: #757575;
  }
}
.icon-money {
  &:before {
    content: $icon-money;
    color: #757575;
  }
}
.icon-moon {
  &:before {
    content: $icon-moon;
    color: #757575;
  }
}
.icon-move {
  &:before {
    content: $icon-move;
    color: #757575;
  }
}
.icon-music {
  &:before {
    content: $icon-music;
    color: #757575;
  }
}
.icon-nft-single {
  &:before {
    content: $icon-nft-single;
    color: #757575;
  }
}
.icon-nft {
  &:before {
    content: $icon-nft;
    color: #757575;
  }
}
.icon-notebook {
  &:before {
    content: $icon-notebook;
    color: #757575;
  }
}
.icon-notes {
  &:before {
    content: $icon-notes;
    color: #757575;
  }
}
.icon-notification {
  &:before {
    content: $icon-notification;
    color: #757575;
  }
}
.icon-notifiication-add {
  &:before {
    content: $icon-notifiication-add;
    color: #757575;
  }
}
.icon-ownership {
  &:before {
    content: $icon-ownership;
    color: #757575;
  }
}
.icon-passanger {
  &:before {
    content: $icon-passanger;
    color: #757575;
  }
}
.icon-passport-1 {
  &:before {
    content: $icon-passport-1;
    color: #757575;
  }
}
.icon-passport {
  &:before {
    content: $icon-passport;
    color: #757575;
  }
}
.icon-patreon {
  &:before {
    content: $icon-patreon;
    color: #757575;
  }
}
.icon-percent {
  &:before {
    content: $icon-percent;
    color: #757575;
  }
}
.icon-perfume {
  &:before {
    content: $icon-perfume;
    color: #757575;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
    color: #757575;
  }
}
.icon-photo {
  &:before {
    content: $icon-photo;
    color: #757575;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
    color: #757575;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
    color: #757575;
  }
}
.icon-private {
  &:before {
    content: $icon-private;
    color: #757575;
  }
}
.icon-public {
  &:before {
    content: $icon-public;
    color: #757575;
  }
}
.icon-puzzle {
  &:before {
    content: $icon-puzzle;
    color: #757575;
  }
}
.icon-qr-code {
  &:before {
    content: $icon-qr-code;
    color: #757575;
  }
}
.icon-qr-scanner {
  &:before {
    content: $icon-qr-scanner;
    color: #757575;
  }
}
.icon-question {
  &:before {
    content: $icon-question;
    color: #757575;
  }
}
.icon-race {
  &:before {
    content: $icon-race;
    color: #757575;
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh;
    color: #757575;
  }
}
.icon-reorder {
  &:before {
    content: $icon-reorder;
    color: #757575;
  }
}
.icon-retweet {
  &:before {
    content: $icon-retweet;
    color: #757575;
  }
}
.icon-revenue {
  &:before {
    content: $icon-revenue;
    color: #757575;
  }
}
.icon-roadmap {
  &:before {
    content: $icon-roadmap;
    color: #757575;
  }
}
.icon-robot {
  &:before {
    content: $icon-robot;
    color: #757575;
  }
}
.icon-roses-default {
  position: relative;
  display: inline-block;

  &::before {
    content: $icon-roses-default-path1;
    color: rgb(117, 117, 117);
    opacity: 0.6;
    display: inline-block;
  }

  &::after {
    content: $icon-roses-default-path2;
    color: rgb(117, 117, 117);
    margin-left: -1em;
    display: inline-block;
  }
}
.icon-roses-outline {
  &:before {
    content: $icon-roses-outline;
    color: #757575;
  }
}
.icon-roses-solid {
  &:before {
    content: $icon-roses-solid;
    color: #757575;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
    color: #757575;
  }
}
.icon-security {
  &:before {
    content: $icon-security;
    color: #757575;
  }
}
.icon-selfie {
  &:before {
    content: $icon-selfie;
    color: #757575;
  }
}
.icon-send-1 {
  &:before {
    content: $icon-send-1;
    color: #757575;
  }
}
.icon-send {
  &:before {
    content: $icon-send;
    color: #757575;
  }
}
.icon-share-1 {
  &:before {
    content: $icon-share-1;
    color: #757575;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
    color: #757575;
  }
}
.icon-smile {
  &:before {
    content: $icon-smile;
    color: #757575;
  }
}
.icon-solana {
  &:before {
    content: $icon-solana;
    color: #757575;
  }
}
.icon-sport {
  &:before {
    content: $icon-sport;
    color: #757575;
  }
}
.icon-star-outline {
  &:before {
    content: $icon-star-outline;
    color: #757575;
  }
}
.icon-star-solid {
  &:before {
    content: $icon-star-solid;
    color: #757575;
  }
}
.icon-stopwatch {
  &:before {
    content: $icon-stopwatch;
    color: #757575;
  }
}
.icon-text {
  &:before {
    content: $icon-text;
    color: #757575;
  }
}
.icon-theatre {
  &:before {
    content: $icon-theatre;
    color: #757575;
  }
}
.icon-tickets {
  &:before {
    content: $icon-tickets;
    color: #757575;
  }
}
.icon-tiktok {
  &:before {
    content: $icon-tiktok;
    color: #757575;
  }
}
.icon-tips {
  &:before {
    content: $icon-tips;
    color: #999;
  }
}
.icon-transfer {
  &:before {
    content: $icon-transfer;
    color: #757575;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
    color: #757575;
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter;
    color: #757575;
  }
}
.icon-underwear {
  &:before {
    content: $icon-underwear;
    color: #757575;
  }
}
.icon-unlock {
  &:before {
    content: $icon-unlock;
    color: #757575;
  }
}
.icon-upload-bulk {
  &:before {
    content: $icon-upload-bulk;
    color: #757575;
  }
}
.icon-upload-1 {
  &:before {
    content: $icon-upload-1;
    color: #757575;
  }
}
.icon-upload {
  &:before {
    content: $icon-upload;
    color: #757575;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
    color: #757575;
  }
}
.icon-verified-1 {
  &:before {
    content: $icon-verified-1;
    color: #757575;
  }
}
.icon-verified .path1 {
  &:before {
    content: $icon-verified-path1;
    color: rgb(0, 0, 0);
  }
}
.icon-verified .path2 {
  &:before {
    content: $icon-verified-path2;
    margin-left: -1em;
    color: rgb(32, 129, 226);
  }
}
.icon-video {
  &:before {
    content: $icon-video;
    color: #757575;
  }
}
.icon-wallet {
  &:before {
    content: $icon-wallet;
    color: #757575;
  }
}
.icon-weight-1 {
  &:before {
    content: $icon-weight-1;
    color: #757575;
  }
}
.icon-weight-2 {
  &:before {
    content: $icon-weight-2;
    color: #757575;
  }
}
.icon-weight {
  &:before {
    content: $icon-weight;
    color: #757575;
  }
}
.icon-whitelist {
  &:before {
    content: $icon-whitelist;
    color: #757575;
  }
}
.icon-wi-fi-off {
  &:before {
    content: $icon-wi-fi-off;
    color: #757575;
  }
}
.icon-wi-fi-on {
  &:before {
    content: $icon-wi-fi-on;
    color: #757575;
  }
}
.icon-zodiac {
  &:before {
    content: $icon-zodiac;
    color: #757575;
  }
}
