@import "../../scss/functions.scss";

.verify-document-message {
  display: flex;
  flex-direction: column;
  gap: to-rem(16);

  .verify-document-message__icon {
    font-size: to-rem(30);
  }

  .verify-document-message__title {
    margin: 0;
    font-size: to-rem(14);
    text-transform: uppercase;
  }

  .verify-document-message__text {
    font-size: to-rem(14);
  }
}
