#landing-page-secondary-carousel {
  position: relative;
  height: 540px;
  max-width: 1224px;
  overflow: hidden;

  .swiper {
    width: auto;
    height: 540px;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    touch-action: pan-y;
  }

  .slide-background {
    position: relative;
    border-radius: var(--radius-mi);
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:before {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: radial-gradient(
        circle,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 100%
      );
      pointer-events: none;
    }

    &::after {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: "";
      background: radial-gradient(
        circle at -15% -65%,
        rgba(231, 0, 124, 0.5) 0%,
        rgba(231, 0, 124, 0) 50%
      );
      pointer-events: none;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }

    .banner-wrapper-inner {
      position: relative;
      z-index: 2;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: left;

      .text-container {
        position: absolute;
        z-index: 3;
        bottom: 40px;
        width: 100%;
        width: calc(100% - 48px);
        max-width: 600px;
        box-sizing: border-box;

        img {
          width: 40px;
          height: 40px;
        }

        h2 {
          color: var(--white);
          font-weight: 400;
          font-size: 24px;
        }

        p {
          color: var(--ash);
          font-weight: 400;
          font-size: 1rem;
        }
      }
    }
  }

  @media (min-width: 30em) {
    .slide-background {
      .banner-wrapper-inner {
        .text-container {
        }
      }
    }
  }

  @media (min-width: 48rem) {
    width: calc(100vw - 48px);
    margin: 24px auto;

    .slide-background {
      .banner-wrapper-inner {
        .text-container {
          left: 40px;
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 80em) {
    margin-top: 0;

    .slide-background {
      .banner-wrapper-inner {
        .text-container {
          left: 40px;
          width: 100%;
        }
      }
    }
  }
}
