@import "../../scss/functions.scss";
$header-height: to-rem(72);

.user-settings-configuration {
  &::before {
    position: absolute;
    z-index: 6;
    top: to-rem(54);
    width: 100dvw;
    height: to-rem(100);
    pointer-events: none;
    content: "";
    background: linear-gradient(to bottom, var(--black) 0%, transparent 100%);
  }

  &.user-settings-configuration-wrapper {
    margin: 0;
  }

  .user-settings-configuration-inner {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 0;
    min-height: 100%;
  }

  .user-settings-configuration-list {
    // padding: to-rem(0, 16);
    flex: 1;

    .text-detail {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .user-settings-configuration-item {
    border-radius: var(--radius-mi);
    padding: to-rem(12, 0);
    gap: to-rem(8);
  }

  .user-settings-configuration-btns {
    z-index: 5;
    display: flex;
    flex-direction: column;
    background-color: var(--bg-primary);
    gap: to-rem(16);
  }

  @media only screen and (min-width: 48rem) {
    &::before {
      position: absolute;
      z-index: 6;
      top: 0;
      width: 100dvw;
      height: to-rem(100);
      pointer-events: none;
      content: "";
      background: linear-gradient(to bottom, var(--black) 0%, transparent 100%);
    }

    .user-settings-configuration-btns {
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .my-plan-modal {
    max-width: to-rem(800);
  }
}
