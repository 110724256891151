@import "../../../scss/functions.scss";

#slid-up-container {
  position: fixed;
  z-index: 10;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(-100%);
  opacity: 0;

  &.open {
    top: 80px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: end;
    background-color: #0606064d;
    transform: translateY(0);
    opacity: 1;
    overflow: hidden;
  }

  &.close {
    transform: translateY(100%);
    opacity: 0;
  }

  .slid-up-content {
    position: relative;
    border-radius: to-rem(33, 33, 0, 0);
    width: 100%;
    background-color: var(--dawn);
    padding: to-rem(8, 20, 20, 20);
    touch-action: pan-y;

    .header {
      padding-bottom: to-rem(24);

      .header__title {
        margin: 0;
        font-weight: 500;
        font-size: to-rem(16);
        text-align: center;
      }

      .header__text {
        font-size: to-rem(14);
        text-align: center;
      }
    }
  }

  .slid-up-full-screen {
    position: relative;
    border-radius: to-rem(16, 16, 0, 0);
    width: 100%;
    height: 100%;
    background-color: var(--bg-primary);
    touch-action: pan-y;

    .slid-up-header {
      border-radius: var(--radius-mi) var(--radius-mi) 0 0;
      display: flex;
      justify-content: space-between;
      background-color: var(--bg-inverse-5);
      padding: 1rem;

      .slid-up-full-header-button {
        z-index: 10;
        border-radius: 50%;
        width: 100%;
        height: to-rem(48);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--bg-inverse-10);
        max-width: to-rem(48);
        min-width: to-rem(48);
        cursor: pointer;
      }
    }

    .slid-up-full-content {
      display: flex;
      flex-direction: column;
      gap: var(--inner-gap);

      .slid-up-full-content-info-text {
        color: var(--gray);
        font-size: 0.875rem;
        text-align: center;
        max-width: to-rem(300);
        align-self: center;
      }
    }

    .header {
      padding-bottom: to-rem(24);

      h3 {
        font-weight: 500;
        font-size: to-rem(16);
        text-align: center;
      }

      p {
        font-family: "Raleway", sans-serif;
        font-size: to-rem(14);
        text-align: center;
      }
    }
  }

  .handler-bar {
    margin: 0 auto to-rem(24) auto;
    border-radius: to-rem(3);
    width: 33%;
    height: to-rem(6);
    border-top: to-rem(6) solid var(--gray-dark);
  }

  .slid-up-title {
    font-weight: 500;
    font-size: to-rem(16);
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;
  }

  @media only screen and (min-width: 48rem) {
    &.open {
      top: 0;
    }
  }
}

.overlay {
  position: fixed;
  z-index: 20;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.5);
}
