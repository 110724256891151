.user-settings-my-plans {
  overflow: auto;

  &.user-settings-my-plans-wrapper {
    margin: 0;
  }

  .user-settings-my-plans-inner {
    padding: 0;
  }
  .user-settings-my-plans-container {
  }
}
