@import "../../scss/functions.scss";

.user-settings-profile-customize {
  &.user-settings-profile-customize-wrapper {
    margin: 0;
  }

  .user-settings-profile-customize-inner {
    padding: 0;
  }

  .user-settings-edit-profile__input-container {
    display: grid;
    gap: var(--inner-gap);
  }

  .user-settings-edit-profile__main {
    display: grid;
    gap: var(--inner-gap);

    .profile-link-container {
      display: flex;
      flex-direction: column;

      .profile-link-container__p {
        display: flex;
        font-size: to-rem(12);
        gap: to-rem(4);
      }
    }

    .user-settings-edit-profile__info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: to-rem(8);
      text-align: center;
    }

    .container-profile {
      border-radius: var(--radius-mi);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: to-rem(24);
      background: var(--bg-tertiary);
      gap: to-rem(16);
      overflow-x: hidden;

      .title-customize-profile {
        margin: 0;
        color: var(--white);
        font-family: "Montserrat Variable";
        font-size: to-rem(16);
      }

      .subtitle-customize-profile {
        color: var(--gray);
        font-family: "Roboto Regular";
        font-size: to-rem(14);
      }

      .user-settings-edit-profile__colors {
        border-radius: to-rem(30);
        width: 100%;
        display: inline-flex;
        padding: to-rem(8);
        gap: to-rem(8);
        background: var(--black);
        overflow: auto;
      }
    }

    .grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: to-rem(8);
    }

    .box-info {
      border-radius: var(--radius-mi);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: var(--bg-secondary);
      padding: to-rem(16);
      border-radius: var(--radius-mi);
      gap: to-rem(8);
      cursor: pointer;

      .signal-circle {
        position: absolute;
        right: to-rem(-2);
        bottom: 0;
        border-radius: 50%;
        width: to-rem(12);
        height: to-rem(12);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: var(--dawn);
        justify-content: space-around;

        .signal {
          border-radius: 50%;
          width: to-rem(8);
          height: to-rem(8);
        }

        .signal-green {
          background-color: var(--green);
        }

        .signal-gray {
          background-color: var(--bg-quinary);
        }
      }

      .box-info--hidden {
        display: hidden;
      }

      .box-info__top {
        position: relative;
      }

      .circle-icon-box-info {
        position: relative;
        border: to-rem(1) solid var(--gray-dark);
        border-radius: to-rem(50);
        width: to-rem(40);
        height: to-rem(40);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--black);

        .span-icon {
          font-size: to-rem(24);
        }
      }

      .box-info__texts {
        text-align: center;

        .title-box-info {
          margin: 0;
          color: var(--white);
          font-family: "Roboto Bold";
          font-size: to-rem(14);
        }

        .subtitle-box-info {
          font-family: "Roboto Regular";
          font-size: to-rem(12);
          text-align: center;
        }
      }
    }
  }

  .user-settings-edit-profile__avatar {
    position: relative;
  }

  .user-settings-edit-profile__add-moments-container {
    position: absolute;
    z-index: 11;
    right: to-rem(-4);
    bottom: to-rem(-4);
    border-radius: 100%;
    display: flex;
    align-items: center;
    background-color: var(--pink-light);
    padding: to-rem(4);
    border-top: to-rem(1) solid #ffffff80;
    justify-items: center;
    cursor: pointer;
    transition: background-color 0.4s ease-out;

    &:hover {
      background-color: var(--pink-light-hover);
    }

    .icon {
      width: 100%;
      text-align: center;
    }
  }
}
