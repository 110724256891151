.create-email-verify {
  display: grid;
  gap: 24px;

  .create-email-verify__main {
    .email-text {
      color: var(--white);
      text-wrap: nowrap;
    }
  }

  .create-email-verify__skip-button {
    margin-top: auto;
  }
}
