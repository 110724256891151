#moments-circles-component {
  .moments-circles-component__wrapper {
    padding-bottom: 16px;
    position: sticky;
    z-index: 10;
    top: 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--inner-gap);
    overflow-y: hidden;

    .moments-circles-component__self-moment {
      position: relative;

      .moments-circles-component__add-moments-container {
        position: absolute;
        left: 32px;
        top: 28px;
        background-color: var(--brand-primary);
        border-top: 1px solid #ffffff80;
        border-radius: 100%;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-items: center;
        cursor: pointer;
        transition: background-color 0.4s ease-out;
        z-index: 11;

        &:hover {
          background-color: var(--pink-light-hover);
        }

        .icon {
          width: 100%;
          // height: 100%;
          text-align: center;
        }
      }
    }
  }

  @media only screen and (min-width: 48rem) {
    .moments-circles-component__wrapper {
      top: 0;
    }
  }

  @media only screen and (min-width: 64em) {
    .moments-circles-component__wrapper {
      justify-content: flex-start;
    }
  }
}
