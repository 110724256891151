@import "../../../scss/functions.scss";

.drop-down__container-button {
  position: sticky;
  bottom: 0;
  border-radius: var(--radius-mi);
  width: 100%;
  padding: to-rem(16);
  background: var(--midnight);
}

#drop-down-component {
  display: flex;
  flex-direction: column;
  align-items: center;

  &[data-active="true"] {
    .input-text-default {
      z-index: 3;
    }
  }

  .drop-down-component__input-container {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    max-height: 0;
    transition:
      max-height 0.3s ease,
      opacity 0.3s ease;

    &.drop-down-component__input-container-open {
      z-index: 11;
      width: 100%;
      max-height: to-rem(300);
      opacity: 1;
    }

    &.drop-down-component__input-container-close {
      width: 100%;
      max-height: 0;
      opacity: 0;
      pointer-events: none;
    }

    .input-container__radio-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: var(--radius-mi);
      width: 100%;
      background-color: var(--bg-secondary);
      box-shadow: 0px 8px 24px 0px #00000059;

      .radio-wrapper__content {
        max-height: to-rem(300);
        overflow-y: auto;
      }
    }

    .input-container__checkbox-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: var(--radius-mi);
      width: 100%;
      background-color: var(--bg-secondary);
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.35);

      .checkbox-wrapper__content {
        max-height: to-rem(300);
        overflow-y: auto;
      }
    }

    .input-container__list-select-wrapper {
      position: absolute;
      top: 2px;
      right: 16px;
      left: 16px;
      border-radius: var(--radius-mi);
      background-color: var(--bg-secondary);
      box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.35);

      .list-select-wrapper__content {
        max-height: to-rem(300);
        overflow-y: auto;
      }
    }
    // .input-container__radio--reverse {
    //   padding-bottom: 24px;
    // }
    // .drop-down-component__li {
    //   &:last-child {
    //     border: none;
    //   }
    //   &:hover {
    //     background-color: (var(--charcoal));
    //   }
    // }
  }
  // .drop-down-component__input-container--reverse {
  //   top: initial;
  //   bottom: calc(100% - 24px);
  //   margin-top: 0;
  //   margin-bottom: 24px;
  //   border-radius: 16px 16px 0 0;
  // }
  // .drop-down__item {
  //   label {
  //     padding: 14.21px 0;
  //   }
  // }
}
