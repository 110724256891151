@import "../../../scss/functions.scss";

.profile-slide {
  overflow-y: auto;

  #profile {
    position: relative;
    z-index: 5;
    display: grid;
    overscroll-behavior: none;
    box-sizing: border-box;

    .profile_dots {
      position: absolute;
      z-index: 8;
      top: to-rem(24);
      left: to-rem(24);
      right: to-rem(24);
      // width: 100%;
      display: flex;
      justify-content: space-between;

      .right_dots {
        display: inline-flex;
        align-items: center;
        gap: to-rem(4);

        .ia-icon {
          border-radius: to-rem(4);
          padding: to-rem(4) to-rem(8);
          color: var(--white);
          font-size: to-rem(12);
          background: var(--bg-quaternary);
          font-weight: 500;
        }
      }

      .left_dots {
        display: inline-flex;
        align-items: center;
      }
    }

    .profile-banner {
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      overflow: hidden;
      gap: 16px;
      // min-height: to-rem(620);

      .img-change-buttons {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;

        .img-change-button-right,
        .img-change-button-left {
          position: absolute;
          z-index: 8;
          bottom: 50%;
          transform: translateY(50%);
          cursor: pointer;
          width: to-rem(32);
          height: to-rem(104);
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--bg-inverse-10);
          backdrop-filter: blur(24px);
        }
        .img-change-button-right {
          right: 0;
          border-radius: 8px 0 0 8px;
        }
        .img-change-button-left {
          left: 0;
          border-radius: 0 8px 8px 8px;
        }

        .placeholder-photos {
          z-index: 10;
          width: 100%;
          height: 100%;
          background-color: var(--bg-primary);
        }

        .photo-video {
          position: absolute;
          z-index: 0;
          top: 0;
          left: 0;
          border-radius: var(--radius-mi);
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
        }
        // .img-change-button-left,
        // .img-change-button-right {
        //   position: absolute;
        //   z-index: 1;
        //   height: 100%;
        //   display: flex;
        //   align-items: center;
        //   flex: 1;
        //   cursor: pointer;

        //   .button-icon {
        //     width: to-rem(32);
        //     height: to-rem(104);
        //     background-color: var(--bg-inverse-10);
        //     backdrop-filter: blur(24px);

        //     .icon-img {
        //       width: to-rem(8);
        //     }
        //   }

        // &.img-change-button-left {
        //   justify-content: flex-start;
        //   background-color: rgba(255, 255, 255, 0);

        //   .left-br {
        //     border-radius: 0 to-rem(8) to-rem(8) 0;
        //   }
        // }

        // &.img-change-button-right {
        //   justify-content: flex-end;
        //   background-color: rgba(255, 255, 255, 0);

        //   .right-br {
        //     border-radius: to-rem(8) 0 0 to-rem(8);
        //   }
        // }
        // }

        .img-change-buttons__cover {
          width: 100%;
          height: 100%;
          height: calc(100dvh - 234px);
          .profile-banner {
            z-index: 1;
            border-radius: var(--radius-mi);
            width: 100%;
            height: 100%;
            // display: block;
            object-fit: cover;
            object-position: top;
          }
        }
      }

      .vip-tag {
        position: absolute;
        z-index: 4;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .background {
          position: absolute;
          z-index: -1;
          top: 0;
          left: 0;
          border-radius: to-rem(20) 0 0 0;
          width: 0;
          height: 0;
          //-webkit-backdrop-filter: blur(4px);
          border-top: to-rem(80) solid rgba(255, 255, 255, 0.15);
          border-right: to-rem(80) solid transparent;
        }

        p {
          position: relative;
          z-index: 1;
          margin: to-rem(16) 0 0 to-rem(13);
          color: var(--white);
          letter-spacing: 0.1em;
        }
      }

      .profile-title {
        position: absolute;
        z-index: 8;
        right: to-rem(24);
        bottom: to-rem(24);
        left: to-rem(24);
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: to-rem(16);

        .profile_dots {
          position: relative;
          top: -120px;
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          // top: calc(-20px - 5%);
          //  right: 14px;

          .icon-dot {
            z-index: 1;
            border-radius: var(--radius-mi);
            width: 10%;
            height: 100%;
            display: block;
            object-fit: cover;
          }
        }

        .profile__icon:before {
          color: var(--pink-light);
        }

        .user-info {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0px;

          .counter {
            font-family: "Roboto Regular";
            font-weight: 400;
            font-size: to-rem(14);
            color: var(--text-primary-75);
          }

          .user-info__profile-avatar {
            margin-bottom: to-rem(8);
          }

          .profile-ia-box {
            border-radius: to-rem(4);
            padding: to-rem(4) to-rem(8);
            color: var(--white);
            font-weight: 700;
            font-size: to-rem(12);
            background: var(--charcoal);
          }

          .user-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: to-rem(4);

            .profile-info {
              font-weight: 600;
              font-size: to-rem(16);
              color: var(--text-primary);
            }

            img {
              width: to-rem(16);
              height: to-rem(16);
            }
          }

          .profile-statistics {
            margin-top: to-rem(12);
            border-radius: var(--radius-mi);
            display: flex;
            flex-direction: row;
            align-items: center;
            background-color: #0000008c;
            padding: 0 to-rem(16);
            gap: to-rem(8);

            .statistics-info {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: to-rem(4);
              gap: to-rem(4);
            }
          }
        }

        .profile-banner-buttons {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: to-rem(8);
        }
      }
    }

    .profile-details-label {
      padding: var(--standard-spacing), 0;
    }

    .profile-bio {
      display: flex;
      flex-direction: column;
      gap: to-rem(24);
      width: 100%;

      .profile-bio__bio-text {
        font-weight: 400;
        font-size: to-rem(16);
        color: var(--gray-light);
        vertical-align: middle;
      }

      .social-buttons {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: to-rem(8);
      }

      .category-wrapper {
        display: flex;
        flex-wrap: wrap;
        // margin-bottom: 80px;
        gap: to-rem(8);
      }

      .essentials-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: to-rem(8);

        .essential-card {
          border-radius: var(--radius-mi);
          height: auto;
          display: flex;
          flex-direction: row;
          background-color: var(--dawn);
          padding: to-rem(16);
          gap: to-rem(8);

          .essential-wrapper {
            width: 100%;
          }

          &:nth-child(odd):last-child {
            grid-column: span 2;
          }

          .essential-info {
            margin: 0;
            // max-width: calc(100% - 5px);
            // overflow: hidden;
            // white-space: nowrap;
            // text-overflow: ellipsis;
          }
        }
      }
    }

    .photo-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: to-rem(8);

      img {
        border-radius: to-rem(8);
        width: calc(50% - 4px);
        height: auto;
        object-fit: cover;
      }
    }

    .profile-disclaimer {
      margin: to-rem(24) to-rem(60) to-rem(40) to-rem(60);
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: to-rem(16);

      .icon-test {
        width: to-rem(24);
        height: to-rem(24);
        background-color: var(--gray);
      }

      p {
        text-align: center;
      }
    }

    .media {
      position: relative;
      border-radius: var(--radius-mi);
      display: flex;
      align-items: center;
      // background-color: var(--dawn);
      overflow: hidden;
      max-width: 100%;

      .media_scroll {
        width: 100%;
        display: inline-flex;
        // gap: to-rem(8);
        overflow-y: hidden;
        overflow-x: auto;
        overscroll-behavior: none;
        scroll-snap-type: x mandatory;

        .media-photo {
          border-radius: var(--radius-mi);
          width: to-rem(120);
          display: flex;
          flex-shrink: 0;
          cursor: pointer;
          gap: to-rem(8);

          .photo {
            border-radius: var(--radius-mi);
            width: to-rem(120);
            height: to-rem(148);
            object-fit: cover;
            transition: box-shadow 0.3s ease;
          }
        }
      }
    }

    .my-goals {
      border-radius: var(--radius-mi);
      width: 100%;
      display: grid;
      align-items: center;
      background-color: var(--dawn);
      padding: to-rem(24);
      //height: 114px;
      grid-template-columns: 4fr 1fr;
      gap: to-rem(16);

      .my-goals_meta {
        display: flex;
        align-items: center;
      }

      .my-goals_logo {
        width: to-rem(25);
      }

      .col-2 {
        display: flex;
        justify-content: center;
        align-items: center;

        .button {
          border-radius: 100%;
          width: to-rem(50);
          max-width: to-rem(50);
          height: to-rem(50);
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--brand-primary);
          color: var(--white);
          font-weight: 500;
          font-size: to-rem(18);
          cursor: pointer;
          border-top: 1px solid #ffffff80;
          padding: 0;
        }
      }
      // h3 {
      //     margin: 0;
      //     font-weight: 700;
      //     font-size: 24px;
      // }
      // p {
      //     margin: 0;
      //     font-size: 12px;
      // }

      .line_progress {
        margin-top: to-rem(8);
        border-radius: to-rem(8);
        width: 100%;
        height: to-rem(12);
        background-color: var(--charcoal);

        .line_progress_complete {
          border: 1px solid var(--pink);
          border-radius: to-rem(8);
          width: 50%;
          height: to-rem(12);
          display: flex;
          justify-content: flex-end;
          align-items: center;
          background-color: #ee4e866a;
          min-width: 5%;
          transition: width 1s ease-in-out;

          .line_indicator {
            border-radius: 50%;
            width: to-rem(20);
            height: to-rem(20);
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #b30060ed;

            .line_indicator_second {
              border-radius: 50%;
              width: to-rem(12);
              height: to-rem(12);
              background-color: var(--brand-primary);
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 48rem) {
    #profile {
      .profile-banner {
        // height: calc(80dvh - 80px);
      }
    }
  }
}
