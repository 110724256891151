.user-settings-personal-safety {
  &.user-settings-personal-safety-wrapper,
  .user-settings-personal-safety-inner {
    margin: 0;
    padding: 0;
  }

  .user-settings-personal-safety__list-container {
    gap: 0;
  }
}
