@import "../../../scss/functions.scss";

.button {
  position: relative;
  border: none;
  border-radius: to-rem(24);
  width: 100%;
  height: to-rem(48);
  padding: to-rem(18) to-rem(40);
  color: var(--white);
  font-family: "Montserrat Variable";
  font-weight: 600;
  font-size: to-rem(10);
  text-transform: uppercase;
  line-height: 1.4;
  letter-spacing: to-rem(1);
  text-wrap: nowrap;
  outline: none;
  overflow: hidden;
  transition: all ease 0.3s;
  align-self: flex-end;

  p {
    margin: 0;
  }

  &[status="disabled"] {
    opacity: 0.75;
    cursor: not-allowed;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 100%;
    display: block;
    content: "";
    background: rgba(255, 0, 122, 0.1);
    opacity: 1;
    transition: all ease 0.3s;
  }

  &:not([status="disabled"]) {
    &:hover {
      cursor: pointer;
    }
  }

  &.btn-auto {
    width: auto;
  }

  &.primary {
    color: var(--white);
    border-top: to-rem(1) solid rgba(255, 255, 255, 0.5);
    background: var(--pink-light);
    // box-shadow: to-rem(0) to-rem(1) to-rem(0) to-rem(0) #ffffff73 inset;

    &:hover {
      background: var(--pink-light-hover);
    }

    &:active {
      background: var(--pink-light-active);
    }

    p {
      color: var(--white);
    }
  }

  &.secondary {
    position: relative;
    background: var(--pink-light);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    &:before {
      position: absolute;
      z-index: 1;
      border-radius: to-rem(24);
      padding: to-rem(1);
      content: "";
      inset: 0;
      background: var(--pink-light);
      -webkit-mask:
        linear-gradient(var(--bg-primary) 0 0) content-box,
        linear-gradient(var(--bg-primary) 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }

    &:hover {
      opacity: 0.85;

      &:before {
        background: var(--pink-light-hover);
      }
    }

    &:active {
      opacity: 0.65;

      &:before {
        background: var(--pink-light-active);
      }
    }
  }

  &.tertiary {
    background-color: var(--bg-inverse-10);
    backdrop-filter: blur(24px);
    color: var(--text-primary-75);

    &:hover {
      color: var(--white);
      opacity: 0.85;
    }

    &:active {
      opacity: 0.65;
    }
  }

  &.iconButton {
    background-color: var(--bg-secondary);
    padding: to-rem(12) to-rem(30);

    &:hover {
      cursor: pointer;
      opacity: 0.85;
    }

    &:active {
      opacity: 0.65;
    }

    & img {
      width: to-rem(24);
      height: to-rem(24);
      padding: to-rem(2);
    }
  }

  &.quaternary {
    position: relative;
    color: var(--gray);
    background: none;

    &:hover {
      color: var(--white);
    }

    &:after {
      display: none;
    }
  }

  &.quintenary {
    border: to-rem(1) solid #ffffff59;
    color: var(--gray);
    font-weight: 600;
    letter-spacing: 5%;
    // line-height: to-rem(24);
    cursor: pointer;
  }

  &.sexternary {
    border: none;
    background-color: var(--dawn);
    font-weight: 600;
    letter-spacing: 5%;
    cursor: pointer;
  }

  &.septenary {
    background-color: var(--charcoal);
    color: var(--white);

    &:hover {
      color: var(--white);
      opacity: 0.85;
    }

    &:active {
      opacity: 0.65;
    }
  }

  &.octonary {
    background-color: var(--gold);
    color: var(--charcoal);

    &:hover {
      opacity: 0.85;
    }

    &:active {
      opacity: 0.65;
    }
  }

  &.golden {
    background-color: var(--color-gold);
    color: var(--charcoal);
  }
}
