@import "../../scss/functions.scss";

.create-number-verify {
  display: grid;

  .create-number-verify-wrapper {
    margin: 0;
  }

  .create-number-verify-inner {
    display: grid;
    padding: 0;
  }

  .create-number-verify__phone-text {
    color: var(--white);
    text-wrap: nowrap;
  }

  .create-number-verify__resend-code {
    background-color: transparent;
    color: var(--pink-hot);
    font-size: to-rem(12);
    cursor: pointer;
  }
}
