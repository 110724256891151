#list-item-checkbox {
  position: relative;

  .list-item-checkbox__label {
    padding: 16px;
    display: grid;
    grid-template-columns: 40px 1fr auto;
    align-items: center;
    gap: 16px;

    .list-item-checkbox__icon-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--dawn);
      height: 40px;
      width: 40px;
      border-radius: 100%;
      border: 1px solid var(--gray-dark);
    }

    .list-item-checkbox__text-wrapper {
      .text-wrapper__principal {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        color: var(--white);
      }
      .text-wrapper__secondary {
        margin: 0;
        font-size: 12px;
        color: var(--gray-light);
      }
    }

    .list-item-checkbox__checkbox-wrapper {
      position: relative;
      .checkbox-wrapper__input {
      }

      .container-checkbox__checkmark {
        position: absolute;
        right: 0;
        border: 1px solid var(--gray-dark);
        border-radius: 4px;
        width: 24px;
        height: 24px;
        bottom: 0;
        transform: translateY(50%);
        background-color: var(--dawn);

        &::after {
          top: 2px;
          margin: 3px;
          border-radius: 2px;
          width: 16px;
          height: 16px;
          display: none;
          background-color: var(--brand-primary);
          content: "";
          background-image: url("../../../assets/icons/check-simple-gray.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: 10px;
        }
      }

      input[type="checkbox"] {
        height: 48px;
        display: none;
        cursor: pointer;
      }

      input:checked ~ .container-checkbox__checkmark:after {
        display: block;
      }
    }
  }
  .separator {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--charcoal);
  }
}
