@import "../../scss/functions.scss";
$header-height: to-rem(72);

.choose-document {
  height: calc(100dvh - #{$header-height});

  &.choose-document-wrapper {
    margin: 0;

    @media screen and (min-width: 768px) {
      border-radius: var(--radius-mi);
      background-color: var(--bg-primary);
      max-width: to-rem(500);
    }
  }

  .choose-document-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: to-rem(24);

    @media screen and (min-width: 768px) {
      padding: var(--standard-spacing);
    }
  }

  .choose-document__title {
    margin: 0;
    margin-bottom: to-rem(16);
    font-size: to-rem(24);
  }

  .choose-document__body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: to-rem(8);
  }

  .choose-document__btn {
    border-radius: var(--radius-mi);
    border-color: transparent;
    padding: to-rem(12);
    background: var(--midnight);
    outline: 0;
    cursor: pointer;
    transition: 0.5s all;
  }
  .choose-document__btn--active,
  .choose-document__btn:hover {
    border-color: var(--pink-light);
    background-color: #ee4e871a;
    border-style: solid;
  }

  .choose-document__icon {
    margin-bottom: to-rem(8);
    border: to-rem(1) solid var(--gray-dark);
    border-radius: 50%;
    display: inline-block;
    padding: to-rem(8);
    font-size: to-rem(24);
    background: var(--black);
  }

  .choose-document__btn-title {
    margin: 0;
    font-weight: 700;
    font-size: to-rem(14);
  }

  .choose-document__btn-text {
    font-size: to-rem(12);
  }
}
