@import "../../../scss/functions.scss";

#media-modal-content {
  position: relative;
  user-select: none;
  display: flex;
  background-color: var(--bg-primary);
  z-index: 10;
  border-radius: var(--radius-mi);

  .media-card__media-wrapper {
    border-radius: var(--radius-mi);

    .media-wrapper__media-photo {
      border-radius: var(--radius-mi);
    }
  }

  .media-card__media-wrapper {
    .media-wrapper__media-photo {
      height: 100%;
    }
  }
  .media-modal-content__inner {
    position: relative;

    .close-modal-icon {
      position: absolute;
      top: to-rem(24);
      right: to-rem(8);
      cursor: pointer;
      z-index: 11;
      padding: 16px;
      background-color: transparent;
      transition: backdrop-filter 0.3s ease-in-out;
      border-radius: 100%;
      &:hover {
        backdrop-filter: blur(5px);
      }
    }

    .media-wrapper__media-photo {
      height: 100%;
    }
  }

  .content-wrapper__progressbar-wrapper {
    position: absolute;
    z-index: 8;
    top: to-rem(24);
    right: to-rem(24);
    left: to-rem(24);
    width: calc(100% - 48px);
    display: flex;
    gap: to-rem(8);
  }

  .media-card-wrapper {
    height: 100dvh;

    .media-wrapper__media-photo {
      height: 100%;
    }
  }

  .media-creator-details {
    max-width: to-rem(460);
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media only screen and (min-width: 48rem) {
    height: calc(100dvh - 48px);

    .media-card-wrapper {
      height: 100%;
    }
  }
}
