@import "../../scss/functions.scss";

.allow-location {
  margin: 0 auto;
  max-width: to-rem(460);
  &.account-location-wrapper,
  .account-location-inner {
    margin: 0;
    padding: 0;
  }
}
