.user-settings-edit-goals {
  &.user-settings-edit-goals-wrapper {
    margin: 0;
  }

  .user-settings-edit-goals-inner {
    padding: 0;
  }
  .goals {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .goals__history {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-bottom: 1px solid var(--charcoal);

    &:last-of-type {
      border: 0;
    }
  }

  .goals__top {
    display: flex;
    justify-content: space-between;
  }

  .goals__history-title {
    font-size: 14px;

    &--active {
      color: var(--green);
    }

    &--inactive {
      color: var(--gray);
    }
  }

  .goals__bottom {
    display: flex;
    justify-content: space-between;
  }

  .goals__objective {
    font-size: 14px;
  }

  .goals__amount {
    font-size: 14px;
  }

  .goals__donation {
    font-size: 14px;
  }

  .goals--inactive {
    pointer-events: none;
    opacity: 0.25;
  }
}

.goals__modal {
  margin: 0 auto;
  border-radius: var(--radius-mi);
  width: 100%;
  background-color: var(--bg-secondary);
  max-width: 342px;

  .goals__modal-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .goals__modal-title {
    font-size: 16px;
  }

  .goals__modal-logo {
    width: 25px;
  }
}
