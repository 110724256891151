@import "../../scss/functions.scss";
$header-height: to-rem(72);

.upload-document {
  height: calc(100dvh - #{$header-height});
  display: flex;
  flex-direction: column;
  background-color: var(--bg-primary);
  // display: grid;
  // grid-template-rows: 80px auto 1fr;

  &.upload-document-wrapper {
    margin: 0;

    @media screen and (min-width: 768px) {
      border-radius: var(--radius-mi);
      background-color: var(--bg-secondary);
      max-width: to-rem(500);
    }
  }

  .upload-document-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;

    @media screen and (min-width: 768px) {
      padding: var(--standard-spacing);
    }
  }

  .upload-document__main {
    margin-bottom: auto;
    // height: calc(100dvh - 315px);
    overflow-y: auto;
  }

  .upload-document__title {
    margin: 0;
    margin-bottom: to-rem(16);
    font-size: to-rem(24);
  }

  .upload-document__header {
    margin-bottom: to-rem(24);
  }

  .upload-document__buttons-container {
    width: 100%;
    display: grid;
    align-self: flex-end;
    gap: to-rem(16);
  }
}
