#modal-pwa {
  position: fixed;
  z-index: 13;
  top: 50%;
  left: 50%;
  border: none;
  border-radius: 24px;
  width: calc(100% - 48px);
  height: auto;
  background-color: var(--bg-secondary);
  max-width: 400px;
  width: 100%;
  transform: translate(-50%, -50%);
  padding: 40px;

  .modal-content {
    display: flex;
    flex-direction: column;

    .modal-content__explanation-text {
      font-size: 14px;
    }

    .modal-content__buttons-container {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 16px;
      width: 100%;
    }
  }

  .logo-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    .logo-wrapper__logo {
      sup {
        font-size: 16px;
      }
    }

    img {
      border-radius: var(--radius-mi);
      width: 80px;
      height: 80px;
    }
  }

  .list-wrapper__wrapper {
    margin: 16px 0;
    display: flex;
    flex-direction: column;

    .list-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 16px;

      p {
        font-size: 14px;
      }

      img {
        &.save-icon {
          width: 24px;
          height: 24px;
          padding: 3px;
        }

        &.sphere-icon {
          margin-top: -12px;
        }
      }
    }

    hr {
      margin: 14px 0;
    }
  }

  #button {
    margin: 0;
  }
}
