@import "../../scss/functions.scss";

.home-page {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: 1fr;
  justify-items: center;
}

.catalog-container {
  grid-column: 1 / 2;
}

.account-login {
  display: none;

  padding: to-rem(0, 24);
  grid-column: 2 / 3;
  max-width: to-rem(460);
  border-left: 1px solid var(--gray-dark);
  opacity: 0;
  transform: translateX(100%);
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;
  pointer-events: none;
}

.account-login.show {
  display: grid;
  animation-name: slideIn;
  animation-duration: 0.3s;
  opacity: 1;
  transform: translateX(0);
  visibility: visible;
  pointer-events: auto;
}

.account-login.hide {
  display: grid;
  animation-name: slideOut;
  animation-duration: 0.3s;
  opacity: 1;
  transform: translateX(100%);
  visibility: visible;
  pointer-events: auto;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(100%);
    opacity: 1;
  }

  to {
    transform: translateX(0%);
    opacity: 0;
  }
}
