#media-card {
  position: relative;
  display: grid;
  max-width: 460px;

  .locked-info-container {
    position: absolute;
    top: 50%;
    margin: 0 auto;
    padding: 40px;
    transform: translate(0%, -50%);

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      [alt="lock icon"] {
        margin: 8px;
        width: 18px;
        height: 18px;
      }
      h3,
      span,
      p {
        margin: 0;
        color: var(--white);
      }

      h3 {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        font-family: "Roboto Regular";
        font-weight: 500;
        font-size: 24px;
        text-align: center;
        gap: 4px;

        span {
          font-family: "Montserrat Variable";
          font-weight: 500;
          font-size: 14px;
          letter-spacing: 0.05em;
          text-align: center;
        }
      }

      p {
        font-family: "Roboto Regular";
        font-weight: 400;
        font-size: 14px;
        text-align: center;
      }

      #button {
        margin: 16px auto;
        max-width: 168px;
      }
    }
  }

  .media-card__media-wrapper {
    position: relative;

    .media-wrapper__media-photo {
      border-radius: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    // &:before {
    //   position: absolute;
    //   z-index: 1;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   content: "";
    //   background: linear-gradient(
    //     to top,
    //     rgb(0 0 0 / 90%) 0%,
    //     rgb(0 0 0 / 0%) 50%,
    //     rgb(0 0 0 / 0%) 100%
    //   );
    //   pointer-events: none;
    // }

    .media-wrapper__media-video {
      height: 100%;

      .media-video__video {
        border-radius: 0 0 var(--radius-mi) var(--radius-mi);
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      [alt="play icon"] {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 30px;
        transform: translate(-50%, -50%);
        opacity: 0.2;
      }
    }
  }

  .media-details__interaction-wrapper {
    position: absolute;
    z-index: 0;
    top: 55%;
    right: 24px;

    .interaction-wrapper__likes-button-amount-view {
      display: flex;
      flex-direction: column;
      align-items: center;

      .likes-button-amount-view__button {
        border: none;
        background-color: transparent;
        cursor: pointer;

        [alt="heart icon"] {
          width: 30px;
        }
      }

      .likes-button-amount-view__amount {
        color: var(--white);
      }
    }
  }

  .media-details__creator-media-details {
    position: absolute;
    top: 42px;
    left: 24px;
    width: calc(100% - 48px);
    display: flex;
    align-items: center;
    gap: 16px;

    .creator-media-details__profile-avatar {
      border-radius: 100%;
      width: 48px;
      height: 48px;
      padding: 4px;

      .profile-avatar__image {
        border-radius: 100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .creator-media-details__names-container {
      position: relative;
      z-index: 8;
      .profile-name {
        font-family: "Montserrat Variable";
        display: flex;
        color: var(--text-primary);
        font-weight: 600;
        font-size: 18px;
        gap: 8px;
      }

      .profile-subtext {
        color: var(--text-primary-75);
        font-weight: 400;
        font-size: 14px;
      }
    }

    .post-time {
      position: absolute;
      right: 24px;
      color: var(--ash);
      font-weight: 400;
      font-size: 16px;
    }
  }

  .textarea {
    border: none;
    border-radius: var(--radius-mi);
    width: 100%;
    height: 48px;
    background-color: var(--dawn);
    padding: 16px 50px 16px 16px;
    color: var(--white);
    font-size: 14px;
    max-height: 100px;
    box-sizing: border-box;
    resize: none;
    overflow-y: auto;
    word-break: break-all;
    &.textarea-active,
    &:focus {
      padding: 16px 56px 16px 16px;
    }
  }

  .media-details__footer {
    border-radius: 0 0 var(--radius-mi) var(--radius-mi);
    width: 100%;
    display: flex;
    background: var(--black);

    .footer__interaction-elements {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0 16px 48px 16px;
      gap: 8px;
    }

    #button-icon {
      margin: 0;
    }
  }

  @media only screen and (min-width: 48rem) {
    border-radius: var(--radius-mi);
    padding: 8px;

    .media-card__media-wrapper .media-wrapper__media-video .media-video__video {
      border-radius: var(--radius-mi);
    }

    .media-card__media-wrapper {
      height: calc(100dvh - 64px);
      width: 100%;
      .media-wrapper__media-photo {
        border-radius: var(--radius-mi);
      }
    }
  }
}
