.send-audio-chat-component {
  width: 100%;
  background-color: var(--bg-primary);
  pointer-events: auto;

  .audio-recorder {
    height: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;

    .audio-recorder__btn {
      border: 0;
      background: none;
      outline: 0;

      &:hover {
        cursor: pointer;
      }
    }

    .audio-recorder__icon-mic {
      font-size: 24px;
    }

    .audio-record__time {
      color: var(--white);
      font-size: 12px;
    }

    .grid-column-1-2 {
      display: flex;
      align-items: center;
      gap: 8px;
      grid-column: 1/2;
    }

    .grid-column-3-4 {
      display: flex;
      grid-column: 3/4;
      gap: 8px;
    }
  }

  .send-audio-chat__icon-close {
    &.icon:before {
      color: var(--peach);
    }
  }

  .send-audio-chat__icon-send {
    &.icon:before {
      color: var(--white);
    }
  }

  .send-audio-chat__btn {
    width: 48px;
    height: 48px;
  }
}
