#category-default {
  position: relative;
  border-radius: var(--radius-mi);
  width: auto;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 400;

  & p {
    font-size: 12px;
    margin: 8px 12px;

    &.translatableText {
      font-size: 12px;
    }
  }
}

#category-default.active-class {
  background-color: rgb(255 255 255 / 32%);
  p {
    color: #ffffff;
  }
}

#category-default.disable-class {
  background-color: rgba(85, 85, 85, 0.25);
  p {
    color: #cccccc;
  }
}
