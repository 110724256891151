.catalog-page-wrapper {
  position: relative;
  overflow: hidden;
  padding-top: 134px;

  .catalog-page-inner {
    position: relative;

    .actions-wrapper {
      position: absolute;
      z-index: 11;
      top: -1px;
      right: 0;
      left: 0;
      width: 100%;
      overflow-x: hidden;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 1) 99.9%
      );

      .add-moments-container__moments {
        // padding-bottom: var(--standard-spacing);
      }

      .button-navigation-catalog {
        width: 100%;
        overflow-x: scroll;
      }
    }

    .catalog-page {
      display: grid;
      height: calc(100dvh - 134px);
      padding-top: 56px;
      padding-bottom: 8px;
      gap: 8px;
      overflow-y: scroll;
      grid-auto-rows: min-content;

      &.mobile-size {
        grid-template-columns: 1fr 1fr;
      }

      &.mobile-size-plus {
        grid-template-columns: 1fr 1fr;
      }

      &.tablet-size {
        grid-template-columns: 1fr 1fr 1fr;
        //   // grid-auto-rows: 400px;
      }

      &.wide-size {
        grid-template-columns: repeat(auto-fill, minmax(316px, 1fr));
        //   // grid-auto-rows: minmax(414px, auto);
      }

      &.super-wide-size {
        grid-template-columns: repeat(5, 1fr);
      }

      &.space-nav {
        padding-top: 126px;
      }
    }
  }

  @media only screen and (min-width: 48rem) {
    .catalog-page-wrapper {
      position: relative;
      overflow: hidden;
      padding-top: 114px;
    }
    padding-top: 0;
    .catalog-page-inner {
      .actions-wrapper {
      }
      .catalog-page {
        height: calc(100dvh - 80px);
        padding: 64px 0 16px 0;
        &.space-nav {
          padding-top: 126px;
        }
      }
    }
  }

  @media only screen and (min-width: 64rem) {
    padding-top: 0;
    // height: calc(100dvh - 98px);
  }
}
