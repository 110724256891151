.producer-summary {
  &::after {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    width: 100%;
    height: 168px;
    top: 0;
    z-index: 6;
    pointer-events: none;
  }

  .photo-album {
    display: grid;
    grid-template-columns: auto auto;
    gap: 16px;
    max-height: 670px;
    overflow: auto;
  }

  .reviews-wrapper {
    padding: 40px 24px;

    .header-review-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      [alt="lock icon"] {
        margin-bottom: 19px;
        width: 13px;
      }

      p {
        color: var(--white);
        font-family: "Montserrat Variable";
        font-size: 14px;
        text-align: center;

        &:last-child {
          color: var(--gray);
        }
      }
    }

    .footer-interaction-divider {
      border-bottom: 1px solid var(--charcoal);
    }

    .buttons-interaction-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;

      #button {
        padding: 12px 24px;
        font-family: "Montserrat Variable";
        font-size: 12px;
        max-width: 170px;
        min-width: 154px;
        gap: 8px;
      }
    }
  }
}
