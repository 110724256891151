#photo-gallery {
  position: relative;
  border-radius: 8px;
  display: flex;
  gap: 8px;
  justify-content: space-between;

  img {
    min-width: 120px;
    min-height: 134px;
    max-width: 10%;
    max-height: 10%;
    object-fit: cover;
    z-index: 0;
    border-radius: 8px;
  }
}
